import CommonAPIService from './CommonHandler';

export class BeltRanksAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getBeltRanks(router) {
    const url = '/api/belt_ranks';
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default BeltRanksAPIService;
