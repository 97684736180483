import CommonAPIService from './CommonHandler';

export class BoardsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getBoards(router) {
    const url = '/api/boards';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addEvent(board, router) {
    const url = '/api/boards';
    return this.CommonAPIService.postCall(url, board, router);
  }

  updateEvent(boardId, board, router) {
    const url = `/api/boards/${boardId}`;
    return this.CommonAPIService.putCall(url, board, router);
  }

  deleteEvent(boardId, router) {
    const url = `/api/boards/${boardId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default BoardsAPIService;
