import CommonAPIService from './CommonHandler';

export class TapesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the tapes
  getTapes(router) {
    const url = '/api/tapes';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getTapesByProgram(program_id, router) {
    const url = `/api/tapes/${program_id}/program`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getTapeByID(tape_id, router) {
    const url = `/api/tapes/${tape_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveTapesToPrograms(programs, router) {
    const url = '/api/tapes/program';
    return this.CommonAPIService.postCall(url, {programs: programs}, router);
  }

  addTapes(tape, router) {
    const url = '/api/tapes';
    return this.CommonAPIService.postCall(url, tape, router);
  }

  updateTapes(tape_id, parms, router) {
    const url = `/api/tapes/${tape_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  linkTapesToProgram(tape_id, program_id, router) {
    const url = `/api/tapes/${tape_id}/${program_id}/program`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteTapesToProgram(tape_id, program_id, router) {
    const url = `/api/tapes/${tape_id}/${program_id}/program`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  updateTapesToProgram(tape_ids, program_id, router) {
    const url = `/api/tapes/${tape_ids}/${program_id}/toprogram`;
    return this.CommonAPIService.putCall(url, '', router);
  }

  deleteTapes(tape_id, router) {
    const url = `/api/tapes/${tape_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  updateNicknameOnTape(tape_id, program_id, nickname, router) {
    const url = `/api/tapes/${tape_id}/${program_id}/${nickname}/nickname`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default TapesAPIService;
