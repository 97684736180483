import CommonAPIService from './CommonHandler';

export class PoomsaeAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the poomsae
  getPoomsae(router) {
    const url = '/api/poomsae';
    return this.CommonAPIService.getCall(url, '', router);
  }

  updatePoomsaeToRank(data, rank_id, router) {
    const url = `/api/poomsae/${rank_id}/torank`;
    return this.CommonAPIService.putCall(url, data, router);
  }

  getPoomsaeByRank(rank_id, router) {
    const url = '/api/poomsae/' + rank_id + '/rank';
    return this.CommonAPIService.getCall(url, '', router);
  }

  savePoomsaeToTechniques(techniques, router) {
    const url = `/api/poomsae/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getPoomsaeByID(poomsae_id, router) {
    const url = `/api/poomsae/${poomsae_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  savePoomsaeToRank(ranks, router) {
    const url = '/api/poomsae/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addPoomsae(poomsae, router) {
    const url = '/api/poomsae';
    return this.CommonAPIService.postCall(url, poomsae, router);
  }

  updatePoomsae(poomsae_id, parms, router) {
    const url = `/api/poomsae/${poomsae_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  // this is a single link call, adds the rank to poomsae to the rank
  linkPoomsaeToRank(poomsae_id, rank_id, router) {
    const url = `/api/poomsae/${poomsae_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deletePoomsaeToRank(poomsae_id, rank_id, router) {
    const url = `/api/poomsae/${poomsae_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deletePoomsae(poomsae_id, router) {
    const url = `/api/poomsae/${poomsae_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  updateTestedOnPoomsae(poomsae_id, rank_id, tested, router) {
    const url = `/api/poomsae/${poomsae_id}/${rank_id}/${tested}/tested`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default PoomsaeAPIService;
