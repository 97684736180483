<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Step Sparring</h4>
                <p class="card-category">
                  <el-button type="primary" @click="createStepSparring">Create New Step Sparring</el-button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredAllSparrings" style="width: 100%" height="50vh"  @sort-change="handleSortChange" :row-class-name="tableRowClassName">
        <el-table-column prop="name" sortable="custom" label="Name" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input v-model="scope.row.name" @change="updateStepSparring(scope.row.id)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="File" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.pdf">
              <el-button type="primary" @click="downloadPDF(scope.row.pdf, 'step_sparring', scope.row.id)" icon="">{{ scope.row.pdf }}</el-button>
              <el-button type="danger" @click="removeFile(scope.row.id)" icon="el-icon-delete"></el-button>
            </div>
            <div v-else>
              <el-button type="primary" @click="preUpload('pdf', scope.row.id)" icon="">Upload PDF</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Date Created" :min-width="150" align="center">
          <template v-slot="scope">
            {{scope.row.createdAt.toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}}
          </template>
        </el-table-column>
        <el-table-column label="Last Updated" :min-width="150" align="center">
          <template v-slot="scope">
            {{scope.row.updatedAt.toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}}
          </template>
        </el-table-column>
        <el-table-column label="Make Current" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="flex-row">
              <el-button v-if="!scope.row.current" type="primary" @click="makeCurrent(scope.row.id)" icon="">Make Current</el-button>
              <span v-else>Current</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template v-slot="scope">
            <div class="flex-row">
              <el-button type="danger" @click="deleteStepSparring(scope.row.id)" icon="el-icon-delete"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Upload PDF"
      :visible.sync="modals.pdf">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <input type="file" ref="pdfInput" @change="uploadPDF"/>
            <el-button type="primary" @click="uploadFile" icon="">Upload</el-button>
            <p v-if="message">{{ message }}</p>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('pdf')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Select, Input, Button, Checkbox, Table, TableColumn } from 'element-ui';
import StepSparringAPIService from "../../../../servicehandlers/StepSparringAPIService";
import FileAPIService from "../../../../servicehandlers/FileHandler";

const stepSparringAPIService = new StepSparringAPIService()
const fileAPI = new FileAPIService();

export default {
  name: "StepSparring",
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      spinning: false,
      modals: {
        error: false,
        success: false,
        pdf: false,
        dis: false,
      },
      error_txt: '',
      success_txt: '',
      allSparrings: [],
      filteredAllSparrings: [],
      currentStepSparring: null,
      search: '',
      selectedSparring: null,
      message: '',
      pdfFile: null,
    }
  },
  mounted() {
    this.getStepSparrings()
  },
  methods: {
    getStepSparrings(){
      this.spinning = true
      return stepSparringAPIService.getStepSparrings(this.$router)
        .then((response) => {
          this.allSparrings = []
          this.filteredAllSparrings = []
          this.allSparrings = response
          let temp = []
          for (let step of this.allSparrings){
            step.createdAt = new Date(step.createdAt)
            step.updatedAt = new Date(step.updatedAt)
            if (step.current){
              this.filteredAllSparrings.push(step)
            } else {
              temp.push(step)
            }
          }
          this.filteredAllSparrings = this.filteredAllSparrings.concat(temp)
          this.spinning = false
        })
        .catch((e) => {
          this.error_txt = 'Error getting Step Sparrings: ' + e
          this.openModal('error')
          this.spinning = false
        })
    },
    createStepSparring(){
      let temp = {
        name: '',
        description: '',
        current: true,
      }
      for (let step of this.allSparrings){
        if (step.current){
          step.current = false
          this.updateStepSparring(step.id)
        }
      }
      this.spinning = true
      return stepSparringAPIService.addStepSparring(temp, this.$router)
        .then(() => {
          this.success_txt = "Successfully added Step Sparring"
          this.openModal('success')
          this.spinning = false
          this.getStepSparrings()
        })
        .catch((e) => {
          this.error_txt = "Error while adding Step Sparring: " + e
          this.openModal('error')
          this.spinning = false
        })
    },
    updateStepSparring(stepID){
      this.spinning = true
      let toBeUpdated = null
      for (let step of this.allSparrings){
        if (step.id === stepID){
          toBeUpdated = step
          break
        }
      }
      return stepSparringAPIService.updateStepSparring(stepID, toBeUpdated, this.$router)
        .then(() => {
          //this.success_txt = 'Successfully updated Step Sparring'
          //this.openModal('success')
          this.spinning = false
          if (this.modals.dis){
            this.modals.dis = false
          }
          this.getStepSparrings()
        })
        .catch((e) => {
          this.error_txt = "Error while updating Step Sparring: " + e
          this.openModal('error')
          this.spinning = false
        })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredOrders.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredOrders.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterSparrings(){
      try {
        this.filteredAllSparrings = this.allSparrings.filter(e => {return (!e.current)}).filter(
          (data) =>
            !this.search ||
            (data.name.toLowerCase() + data.name.toLowerCase()).includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
    makeCurrent(id){
      for (let step of this.allSparrings){
        if (step.id === id){
          step.current = true
          this.updateStepSparring(id)
        } else if (step.current){
          step.current = false
          this.updateStepSparring(step.id)
        }
      }
      this.getStepSparrings()
      this.closeModal('past')
    },
    deleteStepSparring(id){
      this.spinning = true
      return stepSparringAPIService.deleteStepSparring(id, this.$router)
        .then(() => {
          this.success_txt = 'Successfully Deleted Step Sparring'
          this.openModal('success')
          this.spinning = false
          this.getStepSparrings()
        })
        .catch((e) => {
          this.error_txt = 'Error while deleting Step Sparring: ' + e
          this.openModal('error')
          this.spinning = false
        })
    },
    displayDescription(step){
      this.selectedSparring = step
      this.openModal('dis')
    },
    preUpload(name, id) {
      this.selectedSparring = id;
      this.openModal(name);
    },
    uploadPDF(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const fileType = /pdf/;
        if (!fileType.test(files[0].type)) {
          this.message = "Please select a valid PDF file.";
          return;
        }
        this.pdfFile = files[0];
        this.message = '';
      }
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.pdfInput.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.pdfInput.files) {
        formData.append('pdfInput', fle);
        formData.append('type', 'step_sparring');
        formData.append('id', this.selectedSparring);
      }
      return fileAPI.uploadFile(formData, this.$router)
        .then((response) => {
          this.success_txt = 'PDF upload success';
          this.spinning = false;
          this.pdfFile = null;
          this.closeModal('pdf');
          this.openModal('success');
          this.getStepSparrings();
        })
        .catch((error) => {
          this.error_txt = 'File upload error: ' + error;
          this.openModal('error');
          this.spinning = false;
        });
    },
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.filename;
          this.spinning = false;
          link.click();
        })
        .catch((error) => {
          this.error_txt = 'Step Sparring PDF download failed! ' + error;
          this.openModal('error');
          this.spinning = false;
        })
    },
    removeFile(id) {
      const parms = {
        pdf: null,
      };
      return stepSparringAPIService.updateStepSparring(id, parms, this.$router)
        .then(() => {
          this.getStepSparrings();
        })
        .catch((error) => {
          this.error_txt = 'PDF clearing failure: ' + error;
          this.openModal('error');
          this.spinning = false;
        });
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'current-row';
      }
      return '';
    }
  }
}
</script>

<style scoped>
.customHeader{
  position: sticky;
  top: 10px;
  background-color: #f0f0f0;
  z-index: 100;
  padding: 1em;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  align-content: center;
}
.customFooter{
  background-color: #f0f0f0;
  border-radius: 6px;
  font-size: 16px;
  padding: 15px;
}
.customContainer{
  padding: 1em;
}
.customCard {
  padding: .5em;
  border-radius: 6px;
}
.customCard:hover{
  background-color: #f0f0f0;
}
.moveUp{
  animation: moveUp .5s ease;
}
.moveDown{
  animation: moveDown .5s ease;
}

el-input,el-select{
  width: 100%;
}

@keyframes moveUp {
  0%{
    transform: translate(0, 65px);
  }
  100%{
    transform: translate(0);
  }
}

@keyframes moveDown {
  0%{
    transform: translate(0, -65px);
  }
  100%{
    transform: translate(0);
  }
}

.current-row {
  background: black;
}
</style>
