<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row align-items-center">
        <div class="col-sm-4">
          <label>Please Select a Rank:</label>
          <el-cascader filterable v-model="selected_rank" placeholder="Select a Rank" style="width: 100%" @change="rankSelected" :options="cascaderPrograms"></el-cascader>
        </div>
        <div class="col-sm-4 text-center">
          <el-button type="primary" @click="reorderRanks" icon="">{{ rank_button_text}}</el-button>
        </div>
        <div class="col-sm-4">
          <el-button type="primary" class="float-right" @click="generatePDF" icon="">Generate criteria PDF page</el-button>
        </div>
      </div>
      <div v-if="show_rank_reorder">
        <div class="row position-sticky sticky-top">
          <div class="col-sm-3"></div>
          <div class="col-sm-2">
            Move rank to after selection
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row align-items-center hoverHighlight" v-for="rank in ranks" :id="rank.id" v-bind:key="rank.id">
              <div class="col-sm-2">
                <div class="row">
                  <div class="col-sm-5 align-items-center">
                    {{ rank.order }}
                  </div>
                  <div class="col-sm-3">
                    <el-button type="text" icon="el-icon-arrow-up" @click="moveRankUp(rank, rank.id)" size="large"/>
                  </div>
                  <div class="col-sm-3">
                    <el-button type="text" icon="el-icon-arrow-down" @click="moveRankDown(rank, rank.id)" size="large"/>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">{{ rank.name}}</div>
              <div class="col-sm-4">
                <el-select filterable v-model="rankBefore" placeholder="" style="width: 100%"
                           @change="moveRankAfter(rank)">
                  <el-option v-for="item in ranks"
                             :key="item.id" :value="item.id" :label="item.name">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <el-tabs type="border-card" v-if="selected_rank" @tab-click="handleTabClick" v-model="selected_tab">
        <el-tab-pane v-for="category of categories" v-bind:key="category.id" :label="displayName(category)">
          <div v-if="category.id === 'breaking_techniques'">
            <div class="row">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-8">
                    <el-select filterable v-model="selectedOutline" placeholder="Select One" style="width: 100%">
                      <el-option v-for="item in defaultOutlines"
                                 :key="item.id" :value="item.id" :label="item.description">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="col-sm-4">
                    <el-button type="primary" @click="selectDropdownItem('outline')">Add Outline</el-button>
                  </div>
                </div>
              </div>
            </div>
            <el-table :data="assignedOutline" style="width: 100%" height="55vh">
              <el-table-column label="Description" :min-width="150" align="center">
                <template v-slot="scope">
                  {{scope.row.description}}
                </template>
              </el-table-column>
              <el-table-column label="Edit" align="center">
                <template v-slot="scope">
                  <outline :outline_id="scope.row.id"></outline>
                </template>
              </el-table-column>
              <el-table-column label="Assign" align="center">
                <template v-slot="scope">
                  <el-button type="primary" @click="assignOutline(scope.row.id)" icon="">Assign to Users</el-button>
                </template>
              </el-table-column>
              <el-table-column label=" " align="center">
                <template>
                  <el-button type="danger" @click="removeOutline" icon="el-icon-delete"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else-if="category.id === 'programs'"  class="text-center">
            <h3>{{programName(selected_rank[0])}}</h3>
          </div>
          <div v-else>
            <div class="row" v-if="category.id !== 'programs' && category.id !== 'documentation'">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-8">
                    <el-select filterable v-model="selected_dropdown_items" placeholder="Select Any" style="width: 100%"
                               @change="checkSelectAll(category.dropdown_list)" multiple collapse-tags>
                      <el-option-group>
                        <el-option value="All">All</el-option>
                      </el-option-group>
                      <el-option-group>
                        <el-option v-for="item in category.dropdown_list"
                                   :key="item.id" :value="item" :label="item.name">
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </div>
                  <div class="col-sm-4">
                    <el-button type="primary" @click="selectDropdownItem(category)">Add Items</el-button>
                  </div>
                </div>
              </div>
              <div class="col-sm-6" v-if="category.id === 'techniques'">
                <div class="row">
                  <div class="col-sm-8">
                    <h3>Step Sparring: {{stepSparring.name}}</h3>
                  </div>
                  <div class="col-sm-4 align-content-center">
                    <el-button v-if="stepSparring.pdf" type="primary" @click="downloadPDF(stepSparring.pdf, 'step_sparring', stepSparring.id)" icon="">{{ stepSparring.pdf }}</el-button>
                    <b v-else>No File</b>
                  </div>
                </div>
              </div>
            </div>
            <el-table stripe :data="category.items" style="width: 100%" height="55vh">
              <el-table-column label="Order" :min-width="150" align="center" v-if="category.id !== 'programs'">
                <template v-slot="{row}">
                  <div class="row">
                    <div class="col-sm-4">
                      {{ row.order }}
                    </div>
                    <div class="col-sm-4">
                      <el-button type="text" icon="el-icon-arrow-up" @click="moveItemUp(category, row)" />
                    </div>
                    <div class="col-sm-4">
                      <el-button type="text" icon="el-icon-arrow-down" @click="moveItemDown(category, row)" />
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Name" :min-width="150" align="center">
                <template v-slot="{row}">
                  {{ row.name }}
                </template>
              </el-table-column>
              <el-table-column label="Poomsae Tech" :min-width="150" align="center" v-if="category.id === 'techniques'">
                <template v-slot="{ row }">
                  <el-checkbox v-model="row.poomsae_technique" size="large" border @change="updatePoomsaeFlag(category, row.id, row.poomsae_technique)">Poomsae</el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="Tested" :min-width="150" align="center" v-if="category.id !== 'programs' && category.id !== 'documentation'">
                <template v-slot="{ row }">
                  <el-checkbox v-model="row.tested" size="large" border @change="updateTestedFlag(category, row.id, row.tested)">Tested</el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label=" " align="center" v-if="category.id !== 'programs' && category.id !== 'documentation'">
                <template v-slot="{row}">
                  <el-button type="danger" @click="removeItem(category, row)" icon="el-icon-delete"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Table, TableColumn, Dialog, Button, Input, Select, Option, OptionGroup, Checkbox, Cascader, Tabs, TabPane} from 'element-ui';
import moment from 'moment';
import jsPDF from 'jspdf';
import RanksAPIService from "src/servicehandlers/RanksAPIService";
import BreakingTechniquesAPIService from "src/servicehandlers/BreakingTechniquesAPIService";
import PoomsaeAPIService from "src/servicehandlers/PoomsaeAPIService";
import KicksAPIService from "src/servicehandlers/KicksAPIService";
import SelfDefenseTechniquesAPIService from "src/servicehandlers/SelfDefenseTechniquesAPIService";
import KickCombosAPIService from "src/servicehandlers/KickCombosAPIService";
import TechniquesAPIService from "src/servicehandlers/TechniquesAPIService";
import TerminologyAPIService from "src/servicehandlers/TerminologyAPIService";
import DocumentationAPIService from "src/servicehandlers/DocumentationAPIService";
import ProgramsAPIService from "src/servicehandlers/ProgramsAPIService";
import MotionsAPIService from "src/servicehandlers/MotionsAPIService";
import TapesAPIService from "../../../../servicehandlers/TapesAPIService";
import OutlinesAPIService from "../../../../servicehandlers/OutlinesAPIService";
import StepSparringAPIService from "../../../../servicehandlers/StepSparringAPIService";
import FileAPIService from "../../../../servicehandlers/FileHandler";

const ranksAPIService = new RanksAPIService();
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
const poomsaeAPIService = new PoomsaeAPIService();
const kicksAPIService = new KicksAPIService();
const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService();
const kickCombosAPIService = new KickCombosAPIService();
const techniqueAPIService = new TechniquesAPIService();
const terminologyAPIService = new TerminologyAPIService();
const documentationAPIService = new DocumentationAPIService();
const programsAPIService = new ProgramsAPIService();
const motionsAPIService = new MotionsAPIService();
const tapesAPIService = new TapesAPIService();
const outlinesAPIService = new OutlinesAPIService();
const stepSparringAPIService = new StepSparringAPIService();
const fileAPI = new FileAPIService();
import Techniques from "./Techniques.vue";

import Outline from "src/pages/Dashboard/Pages/Outline/Outline";

export default {
  components: {
    //Techniques,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Checkbox.name]: Checkbox,
    [Cascader.name]: Cascader,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    Outline
  },
  name: "Assignments",
  data() {
    return {
      modals: {
        error: false,
        success: false,
      },
      spinning: false,
      ranks: [],
      selected_rank: null,
      show_rank_reorder: false,
      rank_button_text: 'Reorder Ranks',
      selectedItems: {
        'breaking_techniques': null,
        'poomsae': null,
        'kicks': null,
        'self_defense': null,
        'kick_combos': null,
        'techniques': null,
        'terminology': null,
        'documentation': null,
        'programs': null,
      },
      selected_dropdown_items: [],
      selected_all_items: false,
      error_txt: null,
      success_txt: null,
      categories: [
        {
          id: 'techniques',
          name: 'Green Tape',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        },
        {
          id: 'poomsae',
          name: 'Black Tape',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        },
        {
          id: 'kicks',
          name: 'Blue Tape',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        },
        {
          id: 'self_defense',
          name: 'Brown Tape',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        },
        {
          id: 'kick_combos',
          name: 'Red Tape',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        },
        {
          id: 'breaking_techniques',
          name: 'Breaking Techniques',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        },
        {
          id: 'terminology',
          name: 'Yellow Tape(ADD PDF FOR MEDIA)',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        },
        {
          id: 'documentation',
          name: 'White (ADD PDF FOR MEDIA)',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        },
        {
          id: 'programs',
          name: 'Program',
          items: [""],
          dropdown_list: [""],
          visible: false,
          nickname: ''
        }
      ],
      cascaderPrograms: [],
      rankBefore: null,
      defaultOutlines: [],
      selectedOutline: null,
      assignedOutline: [],
      stepSparring: null,
      selected_tab: null,
    }
  },
  mounted() {
    this.getRanks();
    this.getStepSparring()
    if (Object.keys(this.$store.getters.rankAssignmentsData).length > 0){
      let getData = this.$store.getters.rankAssignmentsData
      this.selected_rank = getData.selected_rank
      this.rankSelected()
      this.selected_tab = getData.selected_tab
    }

  },
  methods: {
    async updateTestedFlag(category, item_id, tested_flag) {
      try {
        if (category.id === 'breaking_techniques') {
          await breakingTechniquesAPIService.updateTestedOnBreakingTechique(item_id, this.selected_rank[1], tested_flag, this.$router);
        } else if (category.id === 'poomsae') {
          await poomsaeAPIService.updateTestedOnPoomsae(item_id, this.selected_rank[1], tested_flag, this.$router);
        } else if (category.id === 'kicks') {
          await kicksAPIService.updateTestedOnKick(item_id, this.selected_rank[1], tested_flag, this.$router);
        } else if (category.id === 'self_defense') {
          await selfDefenseTechniquesAPIService.updateTestedOnSelfDefenseTechnique(item_id, this.selected_rank[1], tested_flag, this.$router);
        } else if (category.id === 'kick_combos') {
          await kickCombosAPIService.updateTestedOnKickCombo(item_id, this.selected_rank[1], tested_flag, this.$router);
        } else if (category.id === 'techniques') {
          await techniqueAPIService.updateTestedOnTechnique(item_id, this.selected_rank[1], tested_flag, this.$router);
        } else if (category.id === 'terminology') {
          await terminologyAPIService.updateTestedOnTerminology(item_id, this.selected_rank[1], tested_flag, this.$router);
        } else if (category.id === 'documentation') {
          await documentationAPIService.updateTestedOnDocumentation(item_id, this.selected_rank[0], tested_flag, this.$router);
        } else if (category.id === 'programs') {
          await programsAPIService.updateProgramsToRank(item_id, this.selected_rank[1], tested_flag, this.$router);
        }
      } catch (e) {
        this.error_txt = 'Error updating order: ' + e;
        this.openModal('error');
      }
    },
    async updatePoomsaeFlag(category, item_id, poomsae_flag) {
      try {
        await techniqueAPIService.updatePoomsaeOnTechnique(item_id, this.selected_rank[1], poomsae_flag, this.$router);
      } catch (e) {
        this.error_txt = 'Error updating order: ' + e;
        this.openModal('error');
      }
    },
    getItemById(array, id) {
      return array.find(item => item.id === id);
    },
    closeModal(name) {
      this.modals[name] = false
    },
    async generatePDF() {
      this.spinning = true;
      // get kicks and motions for the kick combos
      let all_motions = null;
      try {
        all_motions = await motionsAPIService.getMotion(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting motions: ' + e;
        this.openModal('error');
        return;
      }
      let all_kicks = null;
      try {
        all_kicks = await kicksAPIService.getKick(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting kicks: ' + e;
        this.openModal('error');
        return;
      }

      const date = moment().format('MM-DD-YYYY');
      // Get the width of the page
      const doc = new jsPDF({
        orientation: "landscape",
        format: 'letter',
      });
      doc.setFontSize('12');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      // do this in two page segments
      let x = 10;
      let y = 5;
      doc.text(date, x, y);
      y += 7;
      doc.setFontSize('12');
      doc.text('Beyond Sports Taekwondo Curriculum', x, y);
      y += 7;
      for (const rank of this.ranks) {
        doc.setFontSize('12');
        doc.text(rank.name, x, y);
        doc.setFontSize('8');
        y += 5;

        doc.setFontSize('12');
        doc.text('Kicking', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, halfPage, y);
        y += 5;

        // now loop through all the kicks by each rank, getting from the DB, one at a time
        let kicks = null;
        try {
          kicks = await kicksAPIService.getKickByRank(rank.id, this.$router)
        } catch (e) {
          this.error_txt = 'Error getting kicks by ranks: ' + e;
          this.openModal('error');
          return;
        }

        // POPULATE KICKS
        for (const item of kicks) {
          let rank_kicks = [];
          if (item.kicks) {
            rank_kicks = item.kicks;
          }
          for (const kick of rank_kicks) {
            if (kick.description) {
              doc.text(kick.description, x, y);
              y += 5;
            }
          }
        }
        y += 3;

        // KICK COMBOS
        doc.setFontSize('12');
        doc.text('Kicking Combos', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, halfPage, y);
        y += 5;
        let kick_combos = null;
        try {
          kick_combos = await kickCombosAPIService.getKickCombosByRank(rank.id, this.$router)
        } catch (e) {
          this.error_txt = 'Error getting kick combos: ' + e;
          this.openModal('error');
          return;
        }

        for (const item of kick_combos) {
          let rank_kick_combos = [];
          if (item.kick_combos) {
            rank_kick_combos = item.kick_combos;
          }
          for (const kick_combo of rank_kick_combos) {
            let fulltext = '';
            doc.text(kick_combo.description, x, y);
            y += 5;

            doc.text(fulltext, x, y);
            y += 5;
          }

          y += 3;

          doc.setFontSize('12');
          doc.text('Poomsae', x, y);
          doc.setFontSize('8');
          y += 3;
          doc.line(x, y, halfPage, y);
          y += 5;

          let poomsae_by_rank = null;
          try {
            poomsae_by_rank = await poomsaeAPIService.getPoomsaeByRank(rank.id, this.$router);
            for (const pr of poomsae_by_rank) {
              const poomsaes = pr.poomsaes;
              for (let i = 0; i < poomsaes.length; i++) {
                const poomsae = poomsaes[i];
                if (i === 0) {
                  doc.text('Current: ' + poomsae.description, x, y);
                  y += 5;
                } else {
                  doc.text('Previous: ' + poomsae.description, x, y);
                  y += 5;
                }
              }
            }
          } catch (e) {
            this.error_txt = 'Unresolved data error getting poomsae to ranks: ';
            this.openModal('error');
          }
          y += 3;

          doc.setFontSize('12');
          doc.text('Techniques', x, y);
          doc.setFontSize('8');
          y += 3;
          doc.line(x, y, halfPage, y);
          y += 5;
          let techniques_by_rank = null;
          try {
            techniques_by_rank = await techniqueAPIService.getTechniqueByRank(rank.id, this.$router);
            for (const tech of techniques_by_rank) {
              const techniques = tech.techniques;
              for (const technique of techniques) {
                doc.text(technique.description, x, y);
                y += 5;
              }
            }
          } catch (e) {
            this.error_txt = 'Unresolved data error getting techniques to ranks: ';
            this.openModal('error');
          }
          y += 3;

          doc.setFontSize('12');
          doc.text('Self Defense', x, y);
          doc.setFontSize('8');
          y += 3;
          doc.line(x, y, halfPage, y);
          y += 5;
          let self_defense_by_rank = null;
          try {
            self_defense_by_rank = await selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(rank.id, this.$router);
            for (const tech of self_defense_by_rank) {
              const techniques = tech.self_defense_techniques;
              for (const technique of techniques) {
                doc.text(technique.description, x, y);
                y += 5;
              }
            }
          } catch (e) {
            this.error_txt = 'Unresolved data error getting self defense techniques to ranks: ';
            this.openModal('error');
          }
          y += 3;

          doc.setFontSize('12');
          doc.text('Breaking', x, y);
          doc.setFontSize('8');
          y += 3;
          doc.line(x, y, halfPage, y);
          y += 5;
          let breaking_by_rank = null;
          try {
            breaking_by_rank = await breakingTechniquesAPIService.getBreakingTechniquesByRankID(rank.id, this.$router);
            for (const tech of breaking_by_rank) {
              const techniques = tech.breaking_techniques;
              for (const technique of techniques) {
                doc.text(technique.description, x, y);
                y += 5;
              }
            }
          } catch (e) {
            this.error_txt = 'Unresolved data error getting self techniques to ranks: ';
            this.openModal('error');
          }

          doc.addPage();
          x = 10;
          y = 10;
        }
      }

      this.spinning = false;
      doc.save("curriculum.pdf");
    },
    updateRank(rank){
      this.spinning = true
      return ranksAPIService.updateRank(rank.id, rank, this.$router)
        .then((response) => {
          this.spinning = false
        })
        .catch((error) => {
          this.spinning = false
          this.error_txt = 'Error updating ranks: ' + error
          this.openModal('error')
        })
    },
    buildProgramRankList(){
      return programsAPIService.getPrograms(this.$router)
        .then((response) => {
          for (let program of response){
            let temp = {
              value: program.id,
              label: program.name,
              children: [],
            }
            for (let rank of this.ranks){
              if (program.id === rank.program_id){
                let temp2 = {
                  value: rank.id,
                  label: rank.name,
                }
                temp.children.push(JSON.parse(JSON.stringify(temp2)))
              }
            }
            this.cascaderPrograms.push(JSON.parse(JSON.stringify(temp)))
          }
        })
    },
    getPrograms() {
      return programsAPIService.getPrograms(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'programs') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return programsAPIService.getProgramsByRank(this.selected_rank[1], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'programs') {
            category.items = [];
            for (const item of response) {
              const db_items = item.programs;
              for (const iteritem of db_items) {
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                category.items.push(iteritem);
              }
            }
            break;
          }
        }
        return tapesAPIService.getTapesByProgram(this.selected_rank[0], this.$router)
          .then((response) => {
            for (let item of response[0].tapes){
              for (let category of this.categories){
                if (category.name.split(' ')[0] === item.name){
                  category.nickname = item.tapes_to_programs.nickname
                  break
                }
              }
            }
          })
          .catch((e) => {
            this.error_txt = 'Error getting Tapes: ' + e
            this.openModal('error')
          })
      })
      .catch((error) => {
        this.error_txt = 'Error getting programs: ' + error;
        this.openModal('error');
      });
    },
    getTechniques() {
      return techniqueAPIService.getTechnique(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'techniques') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return techniqueAPIService.getTechniqueByRank(this.selected_rank[1], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'techniques') {
            category.items = [];
            for (const item of response) {
              for (const tech of category.dropdown_list){
                if (tech.id === item.technique_id){
                  item.name = tech.name
                  item.id = tech.id
                  category.items.push(item);
                }
              }
            }
            this.updateItemOrder(category, false)
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting techniques: ' + error;
        this.openModal('error');
      });
    },
    getDocumentation() {
      return documentationAPIService.getDocumentation(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'documentation') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return documentationAPIService.getDocumentationByProgram(this.selected_rank[0], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'documentation') {
            category.items = [];
            for (const item of response) {
              for (const documentation of category.dropdown_list){
                if (documentation.id === item.documentation_id){
                  item.name = documentation.name
                  item.id = documentation.id
                  category.items.push(item);
                }
              }
            }
            this.updateItemOrder(category, false)
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting documentation: ' + error;
        this.openModal('error');
      });
    },
    getKickCombos() {
      return kickCombosAPIService.getKickCombos(this.$router)
      .then((response) => {
        response.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });
        for (const category of this.categories) {
          if (category.id === 'kick_combos') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return kickCombosAPIService.getKickCombosByRank(this.selected_rank[1], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'kick_combos') {
            category.items = [];
            for (let item of response){
              for (const kick_combo of category.dropdown_list){
                if (kick_combo.id === item.kick_combo_id){
                  item.name = kick_combo.name
                  item.id = kick_combo.id
                  category.items.push(item);
                }
              }
            }
            this.updateItemOrder(category, false)
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Kick Combos: ' + error;
        this.openModal('error');
      });
    },
    getKicks() {
      return kicksAPIService.getKick(this.$router)
      .then((kicks_resp) => {

        kicks_resp.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'kicks') {
            category.dropdown_list = [];
            for (const kick of kicks_resp) {
              kick.value = kick.id;
              kick.text = kick.description;
              category.dropdown_list.push(kick);
            }
            break;
          }
        }
        return kicksAPIService.getKickByRank(this.selected_rank[1], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'kicks') {
            category.items = [];
            for (const item of response) {
              for (const kick of category.dropdown_list){
                if (kick.id === item.kick_id){
                  item.name = kick.name
                  item.id = kick.id
                  category.items.push(item);
                }
              }
            }
            this.updateItemOrder(category, false)
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting kicks: ' + error;
        this.openModal('error');
      });
    },
    getPoomsae() {
      return poomsaeAPIService.getPoomsae(this.$router)
      .then((poomsae_resp) => {

        poomsae_resp.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'poomsae') {
            category.dropdown_list = [];
            for (const poomsae of poomsae_resp) {
              poomsae.value = poomsae.id;
              poomsae.text = poomsae.description;
              poomsae.name = poomsae.description;
              category.dropdown_list.push(poomsae);
            }
            break;
          }
        }
        return poomsaeAPIService.getPoomsaeByRank(this.selected_rank[1], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'poomsae') {
            category.items = [];
            for (const item of response) {
              for (const poomsae of category.dropdown_list){
                if (poomsae.id === item.poomsae_id){
                  item.name = poomsae.name
                  item.id = poomsae.id
                  category.items.push(item);
                }
              }
            }
            this.updateItemOrder(category, false)
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting poomsae: ' + error;
        this.openModal('error');
      });
    },
    getRanks() {
      return ranksAPIService.getRanks(this.$router)
      .then((response) => {
        this.ranks = response;
        for (const rank of this.ranks) {
          rank.text = rank.name;
          rank.value = rank.id;
          this.last_order = rank.order;
        }
        this.buildProgramRankList()
      })
      .catch((error) => {
        this.error_txt = 'Error getting Ranks: ' + error;
        this.openModal('error');
      });
    },
    getSelfDefense() {
      return selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
      .then((response) => {

        response.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'self_defense') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(this.selected_rank[1], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'self_defense') {
            category.items = [];
            for (const item of response) {
              for (const self_def of category.dropdown_list){
                if (self_def.id === item.self_defense_techniques_id){
                  item.name = self_def.name
                  item.id = self_def.id
                  category.items.push(item);
                }
              }
            }
            this.updateItemOrder(category, false)
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Self Defense Items: ' + error;
        this.openModal('error');
      });
    },
    getBreakingTechniques() {
      return breakingTechniquesAPIService.getBreakingTechniques(this.$router)
      .then((technique_resp) => {
        for (const category of this.categories) {
          if (category.id === 'breaking_techniques') {
            category.dropdown_list = [];

            // Sort techniques by order
            technique_resp.sort((a, b) => {
              if (a.order && b.order) {
                return a.order - b.order;
              }
              return 0;
            });

            for (const technique of technique_resp) {
              technique.value = technique.id;
              technique.text = technique.name;
              category.dropdown_list.push(technique);
            }
            break;
          }
        }
        return breakingTechniquesAPIService.getBreakingTechniquesByRankID(this.selected_rank[1], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'breaking_techniques') {
            category.items = [];
            for (const technique_to_rank of response) {
              const techniques = technique_to_rank.breaking_techniques;
              for (const technique of techniques) {
                technique.tested = false;
                if (technique.breaking_techniques_to_ranks) {
                  technique.tested = technique.breaking_techniques_to_ranks.tested;
                }
                technique.value = technique.id;
                technique.text = technique.name;
                category.items.push(technique);
              }
            }
            this.updateItemOrder(category, false)
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting breaking techniques: ' + error;
        this.openModal('error');
      });
    },
    getTerminology() {
      return terminologyAPIService.getTerminology(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'terminology') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return terminologyAPIService.getTerminologyByRank(this.selected_rank[1], this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'terminology') {
            category.items = [];
            for (let item of response){
              for (const terminology of category.dropdown_list){
                if (terminology.id === item.terminology_id){
                  item.name = terminology.name
                  item.id = terminology.id
                  category.items.push(item);
                }
              }
            }
            this.updateItemOrder(category, false)
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Terminology: ' + error;
        this.openModal('error');
      });
    },
    getOutlines(){
      return outlinesAPIService.getOutlines(this.$router)
        .then((response) => {
          this.defaultOutlines = []
          this.assignedOutline = []
          for (let outline of response){
            if (outline.is_default){
              this.defaultOutlines.push(outline)
            }
          }
          for (let outline of this.defaultOutlines){
            if (outline.rank_id === this.selected_rank[1]){
              this.assignedOutline.push(outline)
            }
          }
        })
        .catch((e) => {
          this.error_txt = "Error getting outlines: " + e
          this.openModal('error')
        })
    },
    getStepSparring(){
      return stepSparringAPIService.getCurrentStepSparrings(this.$router)
        .then((response) => {
          this.stepSparring = response
        })
    },
    updateOutline(outline){
      let temp = {
        rank_id: outline.rank_id
      }
      return outlinesAPIService.updateOutline(outline.id, temp, this.$router)
        .then(() => {
          this.success_txt = 'Successfully updated outline'
          this.openModal('success')
          this.getOutlines()
        })
        .catch((e) => {
          this.error_txt = 'Error updating Outline: ' + e
          this.openModal('error')
        })
    },
    async removeOutline(){
      this.assignedOutline[0].rank_id = null
      await this.updateOutline(this.assignedOutline[0])
    },
    async onEnd(category) {
      const allData = [];
      for (let i = 0; i < category.items.length; i += 1) {
        const item = category.items[i];
        let temp = {
          id: item.id,
          tested: item.tested,
        }
        if (category.id === 'techniques'){
          temp.poomsae_technique = item.poomsae_technique
        }
        allData.push(temp);
      }

      try {
        if (category.id === 'breaking_techniques') {
          await breakingTechniquesAPIService.updateBreakingTechniqueToRank(allData, this.selected_rank[1], this.$router);
        } else if (category.id === 'poomsae') {
          await poomsaeAPIService.updatePoomsaeToRank(allData, this.selected_rank[1], this.$router);
        } else if (category.id === 'kicks') {
          await kicksAPIService.updateKicksToRank(allData, this.selected_rank[1], this.$router);
        } else if (category.id === 'self_defense') {
          await selfDefenseTechniquesAPIService.updateSelfDefenseToRank(allData, this.selected_rank[1], this.$router);
        } else if (category.id === 'kick_combos') {
          await kickCombosAPIService.updateKickCombosToRank(allData, this.selected_rank[1], this.$router);
        } else if (category.id === 'techniques') {
          await techniqueAPIService.updateTechniqueToRank(allData, this.selected_rank[1], this.$router);
        } else if (category.id === 'terminology') {
          await terminologyAPIService.updateTerminologiesToRank(allData, this.selected_rank[1], this.$router);
        } else if (category.id === 'documentation') {
          await documentationAPIService.updateDocumentationToProgram(allData, this.selected_rank[0], this.$router);
        } else if (category.id === 'programs') {
          await programsAPIService.updateProgramsToRank(allData, this.selected_rank[1], this.$router);
        }
      } catch (e) {
        this.error_txt = 'Error updating order: ' + e;
        this.openModal('error');
      }
    },
    async onRankEnd(rank) {
      const allIds = [];
      for (let i = 0; i < this.ranks.length; i += 1) {
        const item = this.ranks[i];
        try {
          const parms = {
            order: i + 1,
          };
          await ranksAPIService.updateRank(item.id, parms, this.$router);
        } catch (e) {
          this.error_txt = 'Error updating rank order: ' + e;
          this.openModal('error');
        }
        allIds.push(item.id);
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    rankSelected() {
      this.getBreakingTechniques();
      this.getPoomsae();
      this.getKicks();
      this.getSelfDefense();
      this.getKickCombos();
      this.getTechniques();
      this.getTerminology();
      this.getDocumentation();
      this.getPrograms();
      this.getOutlines()
      this.$nextTick(() => {
        this.displayDescription();
      });
      let temp = {
        selected_rank: this.selected_rank,
        selected_tab: this.selected_tab,
      }
      this.$store.dispatch('ADD_RANK_ASSIGNMENTS_DATA', temp)
    },
    async removeItem(category, item) {
      const index = category.items.indexOf(item);
      if (index > -1) {
        category.items.splice(index, 1);

        // now unlink the item
        try {
          if (category.id === 'breaking_techniques') {
            const unlinked = breakingTechniquesAPIService.deleteBreakingTechniqueToRank(item.id, this.selected_rank[1], this.$router);
          } else if (category.id === 'poomsae') {
            const unlinked = poomsaeAPIService.deletePoomsaeToRank(item.id, this.selected_rank[1], this.$router);
          } else if (category.id === 'kicks') {
            const unlinked = kicksAPIService.deleteKickToRank(item.id, this.selected_rank[1], this.$router);
          } else if (category.id === 'self_defense') {
            const unlinked = selfDefenseTechniquesAPIService.deleteSelfDefenseTechniquesToRank(item.id, this.selected_rank[1], this.$router);
          } else if (category.id === 'kick_combos') {
            const unlinked = kickCombosAPIService.deleteKickCombosToRank(item.id, this.selected_rank[1], this.$router);
          } else if (category.id === 'breaking') {
            const unlinked = techniqueAPIService.deleteTechniqueToRank(item.id, this.selected_rank[1], this.$router);
          } else if (category.id === 'terminology') {
            const unlinked = terminologyAPIService.deleteTerminologyToRank(item.id, this.selected_rank[1], this.$router);
          } else if (category.id === 'documentation') {
            const unlinked = documentationAPIService.deleteDocumentationToProgram(item.id, this.selected_rank[0], this.$router);
          } else if (category.id === 'programs') {
            const unlinked = programsAPIService.deleteProgramtoRank(item.id, this.selected_rank[1], this.$router);
          } else if (category.id === 'techniques') {
            const unlinked = techniqueAPIService.deleteTechniqueToRank(item.id, this.selected_rank[1], this.$router);
          }
        } catch (e) {
          this.error_txt = 'Error unlinking item: ' + e;
          this.openModal('error');
        }
      }
    },
    reorderRanks: function () {
      if (!this.show_rank_reorder) {
        this.rank_button_text = 'Done Ordering Ranks';
        this.show_rank_reorder = true;
      } else {
        this.rank_button_text = 'Reorder Ranks';
        this.show_rank_reorder = false;
      }
    },
    async selectDropdownItem(category) {
      if (category === 'outline'){
        if (this.assignedOutline.length > 0){
          await this.removeOutline()
        }
        let outline
        for (let item of this.defaultOutlines){
          if (item.id === this.selectedOutline){
            outline = item
          }
        }
        outline.rank_id = this.selected_rank[1]
        this.updateOutline(outline)
      } else{
        for (let item of this.selected_dropdown_items){
          if (item !== 'All'){
            const category_id = category.id;
            const selected_id = item.id;

            // append the dropped down item to the category
            let found = false;
            for (const item of category.items) {
              if (item.id === selected_id) {
                found = true;
                break;
              }
            }
            if (!found) {
              category.items.push(item);
              try {
                // now link it up to it as well
                if (category_id === 'breaking_techniques') {
                  const link = await breakingTechniquesAPIService.linkBreakingTechniqueToRank(selected_id, this.selected_rank[1], this.$router);
                  this.getBreakingTechniques()
                } else if (category_id === 'poomsae') {
                  const link = await poomsaeAPIService.linkPoomsaeToRank(selected_id, this.selected_rank[1], this.$router);
                  this.getPoomsae()
                } else if (category_id === 'kicks') {
                  const link = await kicksAPIService.linkKickToRank(selected_id, this.selected_rank[1], this.$router);
                  this.getKicks()
                } else if (category_id === 'self_defense') {
                  const link = await selfDefenseTechniquesAPIService.linkSelfDefenseTechniquesToRank(selected_id, this.selected_rank[1], this.$router);
                  this.getSelfDefense()
                } else if (category_id === 'kick_combos') {
                  const link = await kickCombosAPIService.linkKickComboToRank(selected_id, this.selected_rank[1], this.$router);
                  this.getKickCombos()
                } else if (category_id === 'breaking') {
                  const link = await techniqueAPIService.linkTechniqueToRank(selected_id, this.selected_rank[1],  this.$router);
                  this.getTechniques()
                } else if (category_id === 'terminology') {
                  const link = await terminologyAPIService.linkTerminologyToRank(selected_id, this.selected_rank[1], this.$router);
                  this.getTerminology()
                } else if (category_id === 'documentation') {
                  const link = await documentationAPIService.linkDocumentationToProgram(selected_id, this.selected_rank[0], this.$router);
                  this.getDocumentation()
                } else if (category_id === 'programs') {
                  const link = await programsAPIService.linkProgramToRank(selected_id, this.selected_rank[1], this.$router);
                  this.getPrograms()
                } else if (category_id === 'techniques') {
                  const link = await techniqueAPIService.linkTechniqueToRank(selected_id, this.selected_rank[1], this.$router);
                  this.getTechniques()
                }
              } catch (e) {
                this.error_txt = 'Error linking technique: ' + e;
                this.openModal('error');
              }
            }
          }
        }
        this.selected_dropdown_items = []
        this.selected_all_items = false
      }
    },
    moveRankUp(item, id){
      const index = this.ranks.indexOf(item);
      if (index > 0){
        let prevID = this.ranks[index-1].id
        document.getElementById(id).classList.add("moveUp")
        document.getElementById(prevID).classList.add("moveDown")
        let frontSide = this.ranks.slice(0, index-1)
        let backSide = this.ranks.slice(index+1)
        frontSide.push(this.ranks[index])
        backSide.unshift(this.ranks[index-1])
        this.ranks = frontSide.concat(backSide)
        this.updateRankOrder()
        setTimeout(function (){
          document.getElementById((id)).classList.remove("moveUp")
          document.getElementById(prevID).classList.remove("moveDown")
        }, 1000)
      }
    },
    moveRankDown(item, id){
      const index = this.ranks.indexOf(item);
      if (index < this.ranks.length-1){
        let nextID = this.ranks[index+1].id
        document.getElementById((id)).classList.add("moveDown")
        document.getElementById(nextID).classList.add("moveUp")
        let frontSide = this.ranks.slice(0, index)
        let backSide = this.ranks.slice(index+2)
        frontSide.push(this.ranks[index+1])
        frontSide.push(this.ranks[index])
        this.ranks = frontSide.concat(backSide)
        this.updateRankOrder()
        setTimeout(function (){
          document.getElementById((id)).classList.remove("moveDown")
          document.getElementById(nextID).classList.remove("moveUp")
        }, 1000)
      }
    },
    updateRankOrder(){
      for (let i = 0; i < this.ranks.length; i++){
        this.ranks[i].order = i+1
        this.updateRank(this.ranks[i])
      }
    },
    moveItemUp(category, item){
      const index = category.items.indexOf(item);
      if (index > 0){
        let frontSide = category.items.slice(0, index-1)
        let backSide = category.items.slice(index+1)
        frontSide.push(category.items[index])
        backSide.unshift(category.items[index-1])
        category.items = frontSide.concat(backSide)
        this.updateItemOrder(category)
      }
    },
    moveItemDown(category, item){
      const index = category.items.indexOf(item);
      if (index < category.items.length-1){
        let frontSide = category.items.slice(0, index)
        let backSide = category.items.slice(index+2)
        frontSide.push(category.items[index+1])
        frontSide.push(category.items[index])
        category.items = frontSide.concat(backSide)
        this.updateItemOrder(category)
      }
    },
    updateItemOrder(category, from = true){
      for (let i = 0; i < category.items.length; i++){
        category.items[i].order = i+1
      }

      if (from) this.onEnd(category)
    },
    moveRankAfter(rank){
      let moveIndex = this.ranks.indexOf(rank)
      let newIndex
      for (let rank of this.ranks){
        if (rank.id === this.rankBefore){
          newIndex = this.ranks.indexOf(rank)
          break
        }
      }
      if (moveIndex > -1 && newIndex > -1){
        this.ranks.splice(moveIndex, 1)
        this.ranks.splice(newIndex, 0, rank)
        this.rankBefore = null

      }
    },
    displayName(category){
      if (category.nickname){
        return category.nickname
      }
      return category.name
    },
    checkSelectAll(items){
      if (this.selected_all_items){
        let found = false
        for (let item of this.selected_dropdown_items){
          if (item === 'All') {
            found = true
            break
          }
        }
        if (!found){
          this.selected_dropdown_items = []
          this.selected_all_items = false
        }
      } else {
        if (this.selected_dropdown_items[this.selected_dropdown_items.length - 1] === 'All'){
          this.selected_all_items = true
          for (let item of items){
            if (!this.selected_dropdown_items.includes(item)){
              this.selected_dropdown_items.push(item)
            }
          }
        }
      }
    },
    displayDescription(){
      let div = document.getElementById('description')
      if (div){
        let splitDescription = this.stepSparring.description.split('\n')
        let formatedString =''
        for (let section of splitDescription){
          formatedString += section + '<br>'
        }
        div.innerHTML = formatedString
      }
      return ''
    },
    assignOutline(id){
      return outlinesAPIService.assignOutline(id, this.$router)
        .then(() => {
          this.success_txt = 'Successfully assigned Outline to users'
          this.openModal('success')
        })
        .catch(() => {
          this.error_txt = 'Error while assigning outline to users'
          this.openModal('error')
        })
    },
    programName(id){
      for (let program of this.categories[this.categories.length - 1].dropdown_list){
        if (program.id === id){
          return program.name
        }
      }
    },
    handleTabClick(tab) {
      this.selected_tab = tab.index
      let temp = {
        selected_rank: this.selected_rank,
        selected_tab: this.selected_tab,
      }
      this.$store.dispatch('ADD_RANK_ASSIGNMENTS_DATA', temp)
    },
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.filename;
          this.spinning = false;
          link.click();
        })
        .catch((error) => {
          this.error_txt = 'Step Sparring PDF download failed! ' + error;
          this.openModal('error');
          this.spinning = false;
        })
    },
  },

};
</script>

<style scoped>
el-input,el-select{
  width: 100%;
}
.sticky-top{
  padding: 10px;
  background-color: white;
}
.hoverHighlight{
  padding: 5px;
  width: 50%;
}
.hoverHighlight:hover{
  background-color: #e5e5e5;
}
</style>
