<template>
  <div v-if="currentUser">
    <card>
      <div class="row">
        <div class="col-sm-10">
          <h3><span class="font-weight-bold">About Me: </span><el-button type="primary" class="float-right" @click="editUser" icon="el-icon-edit"></el-button></h3>
          <div class="row">
            <div class="col-sm-6">
              <h5><span class="font-weight-bold">Full Name: </span>{{currentUser.first_name}} {{currentUser.last_name}}</h5>
              <h5><span class="font-weight-bold">Age: </span>{{currentUser.age}}</h5>
              <h5 v-if="userRank"><span class="font-weight-bold">Rank: </span>{{userRank.name}}</h5>
            </div>
            <div class="col-sm-6">
              <h5><span class="font-weight-bold">Weight: </span>{{currentUser.weight}}</h5>
              <h5><span class="font-weight-bold">Weight Class: </span>Class {{userClass}}</h5>
              <h5 v-if="averageBoardSize"><span class="font-weight-bold">Average Board Size: </span>{{averageBoardSize.name}}</h5>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <img :src="currentUser.img_url" alt="Profile Picture">
        </div>
      </div>
    </card>
    <card>
      <div class="row" v-if="checkIfJudge()">
        <div class="col-sm-4 text-center">
          <h4 class="font-weight-bold">My TKD Curriculum</h4>
          <master-print-dialog :type="'curriculum'" :given-data="forPrint"></master-print-dialog>
          <master-print-dialog :type="'test-sheet'" :print-name="currentUser.first_name + ' ' + currentUser.last_name" :given-data="forPrint"></master-print-dialog>
          <el-button type="primary" @click="goToCertificates" class="margin">My TKD Certificates</el-button>
        </div>
        <div class="col-sm-4 text-center">
          <h4 class="font-weight-bold">My Breaking</h4>
          <el-button type="primary" @click="goToEvents" class="margin">My Events</el-button>
          <br>
          <el-button type="primary" @click="goToOutlines" class="margin">My Breaking Outlines</el-button>
          <br>
          <el-button type="primary" @click="goToScores" class="margin">My Presentation Scores</el-button>
        </div>
        <div class="col-sm-4 text-center">
          <h4 class="font-weight-bold">Judging</h4>
          <el-button type="primary" @click="goToJudging" class="margin">Judge an Outline</el-button>
          <br>
          <el-button type="primary" @click="goToJudgingEvent" class="margin">Judge for an Event</el-button>
          <br>
          <el-button type="primary" @click="goToJudgingSheet" class="margin">Blank Judging Sheet PDF</el-button>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-sm-6 text-center">
          <h4 class="font-weight-bold">My TKD Curriculum</h4>
          <master-print-dialog :type="'curriculum'" :given-data="forPrint"></master-print-dialog>
          <master-print-dialog :type="'test-sheet'" :print-name="currentUser.first_name + ' ' + currentUser.last_name" :given-data="forPrint"></master-print-dialog>
          <el-button type="primary" @click="goToCertificates" class="margin">My TKD Certificates</el-button>
        </div>
        <div class="col-sm-6 text-center">
          <h4 class="font-weight-bold">My Breaking</h4>
          <el-button type="primary" @click="goToEvents" class="margin">My Events</el-button>
          <br>
          <el-button type="primary" @click="goToOutlines" class="margin">My Breaking Outlines</el-button>
          <br>
          <el-button type="primary" @click="goToScores" class="margin">My Presentation Scores</el-button>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Edit"
      :visible.sync="modals.edit">
      <div class="text-center" v-if="tempUser">
        <div class="row">
          <div class="col-sm-3">
            <label>First Name:</label>
            <br>
            <el-input v-model="tempUser.first_name"></el-input>
          </div>
          <div class="col-sm-3">
            <label>Last Name:</label>
            <br>
            <el-input v-model="tempUser.last_name"></el-input>
          </div>
          <div class="col-sm-3">
            <label>Age:</label>
            <br>
            <el-input-number v-model="tempUser.age"></el-input-number>
          </div>
          <div class="col-sm-3">
            <label>Weight:</label>
            <br>
            <el-input-number v-model="tempUser.weight"></el-input-number>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="saveUser" icon="">Save</el-button>
          <el-button type="primary" @click="closeModal('edit')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import OutlinesAPIService from 'src/servicehandlers/OutlinesAPIService';
import UserAPIService from "../../../servicehandlers/UsersAPIService";
import RanksAPIService from "../../../servicehandlers/RanksAPIService";
import BoardsAPIService from "../../../servicehandlers/BoardsAPIService";
import { Table, TableColumn, Dialog, Button, Input, InputNumber } from 'element-ui';
import MasterPrintDialog from "../Pages/MasterPrintDialog.vue";

const outlineAPIService = new OutlinesAPIService();
const usersAPIService = new UserAPIService();
const ranksAPIService = new RanksAPIService();
const boardAPIService = new BoardsAPIService()

export default {
  components: {
    MasterPrintDialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
  },
  data() {
    return {
      currentUser: null,
      scopes: [],
      ranks: [],
      userRank: null,
      forPrint: [
        {
        id: 1,
        user: null,
        rank: null,
        nextRank: null,
        }
      ],
      modals: {
        error: false,
        success: false,
        edit: false,
      },
      tempUser: null,
      error_txt: '',
      success_txt: '',
      userClass: null,
      averageBoardSize: null,
    }
  },
  mounted() {
    this.getCurrentUser()
  },
  methods: {
    getCurrentUser(){
      let userID = this.$store.getters.userId
      return usersAPIService.getUserById(userID, this.$router)
        .then((response) => {
          this.currentUser = response
          if (!this.currentUser.img_url){
            this.currentUser.img_url = '/static/img/faces/face-0.jpg'
          }
          this.scopes = this.$store.getters.scopes
          this.getRanks()
          this.getBoards()
        })
        .catch((e) => {
          this.error_txt = 'Error getting current user: ' + e
          this.openModal('error')
        })
    },
    getRanks(){
      return ranksAPIService.getRanks(this.$router)
        .then((response) => {
          this.ranks = response
          let index
          for (let rank of this.ranks){
            if (rank.id === this.currentUser.rank_id){
              this.userRank = rank
              index = this.ranks.indexOf(rank)
              break
            }
          }
          this.forPrint[0].rank = this.userRank
          this.forPrint[0].user = this.currentUser
          if (this.ranks[index + 1]){
            this.forPrint[0].nextRank = this.ranks[index + 1]
          }
        })
        .catch((e) => {
          this.error_txt = 'Error getting ranks: ' + e
          this.openModal('error')
        })
    },
    getBoards(){
      return boardAPIService.getBoards(this.$router)
      .then((response) => {
        this.userClass = 6;
        if (!this.currentUser.weight) {
          this.currentUser.weight = 99999;
        }
        if (this.currentUser.weight >= 35 && this.currentUser.weight <= 49.9) {
          this.userClass = 1;
        } else if (this.currentUser.weight >= 50 && this.currentUser.weight <= 70.5) {
          this.userClass = 2;
        } else if (this.currentUser.weight >= 70.6 && this.currentUser.weight <= 96.6) {
          this.userClass = 3;
        } else if (this.currentUser.weight >= 96.7 && this.currentUser.weight <= 127.5) {
          this.userClass = 4;
        } else if (this.currentUser.weight >= 127.6 && this.currentUser.weight <= 164.9) {
          this.userClass = 5;
        } else if (this.currentUser.weight >= 165) {
          this.userClass = 6;
        } else {
          this.userClass = 6;
        }
        this.averageBoardSize = response[this.userClass - 1]
      })
    },
    editUser(){
      this.tempUser = JSON.parse(JSON.stringify(this.currentUser))
      this.openModal('edit')
    },
    saveUser(){
      return usersAPIService.updateUser(this.currentUser.id, this.tempUser, this.$router)
        .then(() => {
          this.success_txt = 'Successfully Saved User'
          this.openModal('success')
          this.closeModal('edit')
          this.getCurrentUser()
        })
        .catch((e) => {
          this.error_txt = 'Error while updating user: ' + e
          this.openModal('error')
        })
    },
    goToCertificates(){
      /*this.$router.push({
        name: 'rank-checklist'
      });*/
    },
    goToEvents(){
      this.$router.push({
        name: 'Events'
      });
    },
    goToOutlines(){
      this.$router.push({
        name: 'My Outlines'
      });
    },
    goToScores(){
      this.$router.push({
        name: 'Judge View Page'
      });
    },
    goToJudging(){
      this.$router.push({
        name: 'Judging Page'
      });
    },
    goToJudgingEvent(){
      /*this.$router.push({
        name: 'Judging Page'
      });*/
    },
    goToJudgingSheet(){
      /*this.$router.push({
        name: 'Judging Page'
      });*/
    },
    checkIfJudge(){
      for (let scope of this.scopes){
        if (scope.name === 'judge-page'){
          return true
        }
      }
      return false
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
      if (name === 'view') this.kicksToView = null
    },
  }
}
</script>
<style scoped>
.margin{
  margin: 6px;
}
</style>
