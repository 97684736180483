<template>
  <auth-layout pageClass="register-page">
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <div class="row d-flex justify-content-center">
      <div class="col-md-5 ml-auto">
        <fade-render-transition :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-circle-09"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>BST Registration Page</h4>
              <p>Register for a free account so you can create and do your own outlines</p>
            </div>
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="200" :duration="200">
          <div class="media">
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="400" :duration="200">
          <div class="media">
            <div class="media-left">
            </div>
            <div class="media-body">
              <h4></h4>
              <p></p>
            </div>
          </div>
        </fade-render-transition>
      </div>
      <div class="col-md-4 mr-auto">
        <form method="#" action="#">
          <card class="card-plain">
            <div class="row">
              <div class="col-md-12">
                <fg-input placeholder="Username" v-model="username"></fg-input>
                <fg-input placeholder="Email" type="email" v-model="email"></fg-input>
                <fg-input placeholder="First Name" v-model="first"></fg-input>
                <fg-input placeholder="Last Name" v-model="last"></fg-input>
                <fg-input placeholder="Studio Name" v-model="studio_name"></fg-input>
                <fg-input placeholder="Master Instructors Name" v-model="master_instructor_name"></fg-input>
                <fg-input placeholder="Phone with area code" v-model="phone_number"></fg-input>
                <fg-input placeholder="Weight" type="number" min="0" v-model="weight"></fg-input>
                <label style="color: white">Rank</label>
                <model-select class="select-primary" :options="ranks"
                              v-model="rank_id"
                              placeholder="select rank">
                </model-select>
                <label style="color: white">Belt Rank</label>
                <model-select class="select-primary" :options="belt_ranks"
                              v-model="belt_rank_id"
                              placeholder="select belt rank">
                </model-select>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-md-6">
                <fg-input placeholder="Password" type="password" v-model="password1"></fg-input>
              </div>
              <div class="col-md-6">
                <fg-input placeholder="Password confirmation" type="password"
                          v-model="password2"></fg-input>
              </div>
            </div>
            <div class="text-center">
              <button type="button" class="btn btn-fill btn-default btn-neutral btn-wd"
                      @click="registerUser">Create Account
              </button>
            </div>
          </card>
        </form>
      </div>
    </div>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ response_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-ok" @click="closeModal('success')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </auth-layout>
</template>
<script>
import { ModelSelect } from 'vue-search-select';
import { FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import { Dialog } from 'element-ui';
import UserAPIService from "../../../servicehandlers/UsersAPIService";
import RanksAPIService from "../../../servicehandlers/RanksAPIService";
import BeltRanksAPIService from "../../../servicehandlers/BeltRanksAPIService";
import {phone} from 'phone';

const usersAPIService = new UserAPIService();
const ranksAPIService = new RanksAPIService();
const beltRanksAPIService = new BeltRanksAPIService();

export default {
  data() {
    return {
      response_txt: null,
      error_txt: null,
      spinning: false,
      first: null,
      last: null,
      username: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      weight: null,
      phone_number: null,
      email: null,
      phone: null,
      password: null,
      password1: null,
      password2: null,
      studio_name: null,
      master_instructor_name: null,
      rank_id: null,
      belt_rank_id: null,
      ranks: [],
      belt_ranks: [],
      modals: {
        error: false,
        success: false,
      },
    }
  },
  components: {
    FadeRenderTransition,
    AuthLayout,
    [Dialog.name]: Dialog,
    ModelSelect
  },
  mounted() {
    this.getRanks();
    this.getBeltRanks();
  },
  methods: {
    getBeltRanks() {
      return beltRanksAPIService.getBeltRanks(this.$router)
      .then((resp) => {
        this.belt_ranks = resp;
        for (const rank of this.belt_ranks) {
          rank.text = rank.name;
          rank.value = rank.id;
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting ranks: ' + error.message;
        this.openModal('error');
      });

    },
    getRanks() {
      return ranksAPIService.getRanks(this.$router)
      .then((resp) => {
        this.ranks = resp;
        for (const rank of this.ranks) {
          rank.text = rank.name;
          rank.value = rank.id;
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting ranks: ' + error.message;
        this.openModal('error');
      });
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    beforeDestroy() {
      this.closeMenu();
    },
    registerUser() {
      this.phone = phone(this.phone_number, {country: 'USA'});
      if (this.password1 !== this.password2) {
        this.error_txt = 'Passwords do not match';
        this.openModal('error');
        this.password1 = null;
        this.password2 = null;
        return;
      }
      if (!this.phone.isValid) {
        this.error_txt = 'Phone number must be valid and include an area code';
        this.openModal('error');
        return;
      }
      if (this.password1.length < 8) {
        this.error_txt = 'Passwords must be a minimum of 8 characters';
        this.openModal('error');
        return;
      }
      if (!this.username) {
        this.error_txt = 'No username was set';
        this.openModal('error');
        return;
      }
      if (!this.email) {
        this.error_txt = 'No email was set';
        this.openModal('error');
        return;
      }
      if (!this.first) {
        this.error_txt = 'No first name was set';
        this.openModal('error');
        return;
      }
      if (!this.last) {
        this.error_txt = 'No last name was set';
        this.openModal('error');
        return;
      }
      if (!this.belt_rank_id) {
        this.error_txt = 'No belt rank was set';
        this.openModal('error');
        return;
      }
      if (!this.weight) {
        this.error_txt = 'No weight was set';
        this.openModal('error');
        return;
      }
      if (!this.rank_id) {
        this.error_txt = 'No rank was selected';
        this.openModal('error');
        return;
      }
      if (parseInt(this.weight) <= 0) {
        this.error_txt = 'Weight is invalid';
        this.openModal('error');
        return;
      }
      this.spinning = true;
      // first check if the username is taken or not
      return usersAPIService.checkUsername(this.username)
      .then((response) => {
        if (parseInt(response) === 1) {
          // username already exists
          this.error_txt = 'User already taken';
          this.openModal('error');
          this.spinning = false;
        } else {
          // go to the next step
          this.password = this.password1;
          const user = {
            username: this.username,
            email: this.email,
            first_name: this.first,
            last_name: this.last,
            studio_name: this.studio_name,
            weight: this.weight,
            phone: this.phone.phoneNumber,
            belt_rank_id: this.belt_rank_id,
            password: this.password,
            master_instructor_name: this.master_instructor_name,
            enabled: true,
            rank_id: this.rank_id,
            validated: 0,
          };
          return usersAPIService.createUser(user, this.$router)
          .then(() => {
            this.response_txt = 'User created successfully, check your email to validate and login';
            this.openModal('success');
            this.spinning = false;
            this.username = null;
            this.email = null;
            this.first = null;
            this.last = null;
            this.studio_name = null;
            this.weight = null;
            this.phone = null;
            this.belt_rank_id = null;
            this.password1 = null;
            this.password2 = null;
            this.rank_id = null;
            this.master_instructor_name = null;
            this.studio_name = null;
          })
          .catch((error) => {
            this.spinning = false;
            this.error_txt = 'An error occurred while registering user: ' + error.exception.errors[0];
            this.openModal('error');
          });
        }
      })
    },
  }
}
</script>
<style>
</style>
