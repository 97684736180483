<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4">
          <button class="btn btn-primary" @click="openModal('create')">Add New Motions</button>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12 text-center">
          <span style="font-size: 2em; font-weight: bold">Create or Edit Motions</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-1">
          <span style="font-weight: bold">Order</span>
        </div>
        <div class="col-sm-2">
          <span style="font-weight: bold">Name</span>
        </div>
        <div class="col-sm-5">
          <span style="font-weight: bold">Info</span>
        </div>
        <div class="col-sm-2">
          <span style="font-weight: bold">PDF</span>
        </div>
        <div class="col-sm-1">
          <span style="font-weight: bold">Media</span>
        </div>
        <div class="col-sm-1">
          <span style="font-weight: bold">Actions</span>
        </div>
      </div>
      <draggable group="top_group" class="dragArea list-group" v-model="motions" @end="onEnd">
        <div v-for="motion of motions" v-bind:key="motion.id" class="row">
          <div class="col-sm-1">
            {{ motion.order }}
          </div>
          <div class="col-sm-2">
            <label>
              Name:
              <fg-input placeholder="Name" v-model="motion.name"></fg-input>
            </label><br/>
          </div>
          <div class="col-sm-5">
            <label>
              Description:
              <fg-input placeholder="Description" v-model="motion.description"></fg-input>
            </label>
            <label>
              Notes:
              <fg-input placeholder="Notes" v-model="motion.notes"></fg-input>
            </label>
          </div>
          <div class="col-sm-2">
            <div v-if="motion.pdf">
              <button class="btn-primary" @click="downloadPDF(motion.pdf, 'motions', motion.id)">
                {{ motion.pdf }}
              </button>&nbsp;
              <button class="btn btn-danger" @click="removeFile(motion.id)">-</button>
            </div>
            <div v-else>
              <button class="btn btn-primary" @click="preUpload('pdf', motion.id)">Upload PDF</button>
            </div>
          </div>
          <div class="col-sm-1">
            <fg-input placeholder="Media URL" v-model="motion.media"></fg-input>
          </div>
          <div class="col-sm-1">
            <button class="btn btn-primary" @click="updateMotion(motion.id)">Save</button>
            <button class="btn btn-danger" @click="deleteMotion(motion.id)">-</button>
          </div>
        </div>
      </draggable>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-primary" @click="closeModal('success')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Upload PDF"
      :visible.sync="modals.pdf">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <input type="file" ref="pdfInput" @change="uploadPDF"/>
            <button @click="uploadFile">Upload</button>
            <p v-if="message">{{ message }}</p>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('pdf')">Cancel</button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.create">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-4">
            <label>
              Name:
              <fg-input placeholder="Name" v-model="name"></fg-input>
            </label><br/>
          </div>
          <div class="col-sm-4">
            <label>
              Description:
              <fg-input placeholder="Description" v-model="description"></fg-input>
            </label>
          </div>
          <div class="col-sm-4">
            <label>
              Notes:
              <fg-input placeholder="Notes" v-model="notes"></fg-input>
            </label>
          </div>
          <div class="col-sm-4">
            <fg-input placeholder="Media URL" v-model="media"></fg-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-primary" @click="createMotion('create')">OK</button>&nbsp;
<button class="btn btn-danger" @click="closeModal('create')">Cancel</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { Dialog } from 'element-ui';
import MotionsAPIService from "src/servicehandlers/MotionsAPIService";
import { FileAPIService } from 'src/servicehandlers/FileHandler';

const motionsAPIService = new MotionsAPIService();
const fileAPI = new FileAPIService();

export default {
  name: "Motions",
  components: {
    [Dialog.name]: Dialog,
    draggable
  },
  data() {
    return {
      spinning: false,
      korean_name: null,
      abbreviation: null,
      notes: null,
      breaking: false,
      tested: false,
      selected_motion_id: null,
      pdfFile: null,
      message: '',
      filename: null,
      name: null,
      description: null,
      pdf: null,
      media: null,
      last_order: null,
      motioning_foot_position_id: null,
      motion_family_id: null,
      motions: [],
      motion_families: [],
      motion_positions: [],
      error_txt: null,
      success_txt: null,
      modals: {
        error: false,
        success: false,
        create: false,
        pdf: false,
      },
    }
  },
  mounted() {
    this.getMotions();
  },
  methods: {
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.filename;
        this.spinning = false;
        link.click();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Motions PDF download failed! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    preUpload(name, id) {
      this.selected_motion_id = id;
      this.openModal(name);
    },
    removeFile(id) {
      const parms = {
        pdf: null,
      };
      return motionsAPIService.updateMotion(id, parms, this.$router)
      .then(() => {
        this.getMotions();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'PDF clearing failure: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.pdfInput.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.pdfInput.files) {
        formData.append('pdfInput', fle);
        formData.append('type', 'motions');
        formData.append('id', this.selected_motion_id);
      }
      return fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        const self = this;
        this.success_txt = 'PDF upload success';
        this.spinning = false;
        this.pdfFile = null;
        this.closeModal('pdf');
        this.openModal('success');
        this.getMotions();
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadPDF(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const fileType = /pdf/;
        if (!fileType.test(files[0].type)) {
          this.message = "Please select a valid PDF file.";
          return;
        }
        this.pdfFile = files[0];
        this.message = '';
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.motions.length; i += 1) {
        const id = this.motions[i].id;
        this.motions[i].order = i + 1;
        promises.push(motionsAPIService.updateMotion(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        this.getMotions();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updateMotion(id) {
      for (const motion of this.motions) {
        if (id === motion.id) {
          const parms = {
            name: motion.name,
            description: motion.description,
            media: motion.media,
            pdf: motion.pdf,
            notes: motion.notes,
          };
          return motionsAPIService.updateMotion(id, parms, this.$router)
          .then(() => {
            const self = this;
            this.success_txt = 'Motion update successful';
            this.openModal('success');
            setTimeout(function () {
              self.modals['success'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          })
          .catch((error) => {
            const self = this;
            this.error_txt = 'Motion update failure: ' + error;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          });
        }
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    deleteMotion(id) {
      return motionsAPIService.deleteMotion(id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Delete Motion successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getMotions();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error deleting Motions: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getMotions() {
      return motionsAPIService.getMotion(this.$router)
      .then((response) => {
        for (const motion of response) {
          this.last_order = motion.order;
        }
        this.motions = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Motions: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    createMotion() {
      this.closeModal('create');
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      const motion = {
        order,
        name: this.name,
        description: this.description,
        media: this.media,
        notes: this.notes,
      };
      return motionsAPIService.addMotion(motion, this.$router)
      .then((response) => {
        this.motions.push(response);
        this.name = null;
        this.description = null;
        this.media = null;
        const self = this;
        this.success_txt = 'Add Motions successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error Creating Motions: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    }
  }
}
</script>

<style scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
