<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-sm-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div slot="header">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12">
              <h4 class="card-title">Events</h4>
              <p class="card-category">
                List of All Your Events
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row align-items-center">
            <div class="col-sm-8">
              <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterEvents"></el-input>
            </div>
            <div class="col-sm-4" style="padding-left: 5px;">
              <el-button type="primary" @click="filterEvents" icon="">Search</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-table stripe :data="filteredEvents" style="width: 100%" height="65vh" @sort-change="handleSortChange">
      <el-table-column prop="name" sortable="custom" label="Name" :min-width="200" align="center">
        <template v-slot="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column label="Description" :min-width="200" align="center">
        <template v-slot="scope">
          {{scope.row.description}}
        </template>
      </el-table-column>
      <el-table-column label="Outline" align="center">
        <template v-slot="scope">
          <div v-if="scope.row.order_number && scope.row.order_number.outline">
            <outline :outline_id="scope.row.order_number.outline.id"></outline>
          </div>
          <div v-else class="col-sm-3">No Outline Registered</div>
        </template>
      </el-table-column>
      <el-table-column label="Order Number" :min-width="150" align="center">
        <template v-slot="scope">
          {{ scope.row.order_number.code }}
        </template>
      </el-table-column>
      <el-table-column prop="start_date" sortable="custom" label="Start Date" :min-width="100" align="center">
        <template v-slot="scope">
          {{scope.row.start_date}}
        </template>
      </el-table-column>
      <el-table-column prop="end_date" sortable="custom" label="End Date" :min-width="100" align="center">
        <template v-slot="scope">
          {{scope.row.end_date}}
        </template>
      </el-table-column>
    </el-table>
  </card>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import Outline from "../Outline/Outline";
import moment from 'moment';
import EventsAPIService from "../../../../servicehandlers/EventsAPIService";
const eventsAPIService = new EventsAPIService();
import { Dialog, Table, TableColumn, Button } from 'element-ui';
export default {
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    Outline,
  },
  name: "UserEvents",
  data() {
    return {
      response_txt: null,
      error_txt: null,
      spinning: false,
      userEvents: [],
      filteredEvents: [],
      modals: {
        error: false,
        success: false,
      },
      search: '',
    }
  },
  mounted() {
    this.getUserEvents();
  },
  methods: {
    getUserEvents() {
      const userId = this.$store.getters.userId;
      return eventsAPIService.getEventsForUser(userId, this.$router)
      .then((response) => {
        this.userEvents = response;
        for (const event of this.userEvents) {
          if (event.start_date) {
            event.start_date = moment(event.start_date).format('MM/DD/YYYY hh:mm');
          }
          if (event.end_date) {
            event.end_date = moment(event.end_date).format('MM/DD/YYYY hh:mm');
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting user events! ' + error;
        this.openModal('error');
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredEvents.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredEvents.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterEvents(){
      try {
        this.filteredEvents = this.userEvents.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
  }
}
</script>

<style scoped>

</style>
