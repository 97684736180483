import CommonAPIService from './CommonHandler';

export class KicksAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the kicks
  getKick(router) {
    const url = '/api/kicks';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getKickByRank(rank_id, router) {
    const url = '/api/kicks/' + rank_id + '/rank';
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveKickToTechniques(techniques, router) {
    const url = `/api/kicks/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getKickByID(kick_id, router) {
    const url = `/api/kicks/${kick_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveKickToRank(ranks, router) {
    const url = '/api/kicks/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addKick(kicks, router) {
    const url = '/api/kicks';
    return this.CommonAPIService.postCall(url, kicks, router);
  }

  updateKick(kick_id, parms, router) {
    const url = `/api/kicks/${kick_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  updateKicksToRank(data, rank_id, router) {
    const url = `/api/kicks/${rank_id}/torank`;
    return this.CommonAPIService.putCall(url, data, router);
  }
  linkKickToRank(kick_id, rank_id, router) {
    const url = `/api/kicks/${kick_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteKickToRank(kick_id, rank_id, router) {
    const url = `/api/kicks/${kick_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deleteKick(kick_id, router) {
    const url = `/api/kicks/${kick_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  getKickFamilies(router) {
    const url = `/api/kicks/kickfamilies`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getKickFootPositions(router) {
    const url = `/api/kicks/kickfootpositions`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  createOrUpdate(kick, router) {
    const url = `/api/kicks/createorupdate`;
    return this.CommonAPIService.postCall(url, kick, router);
  }
  updateTestedOnKick(kick_id, rank_id, tested, router) {
    const url = `/api/kicks/${kick_id}/${rank_id}/${tested}/tested`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default KicksAPIService;
