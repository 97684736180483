<template>
  <auth-layout pageClass="login-page">
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <div class="row d-flex justify-content-center">
      <div class="col-md-8">
        <div class="header-text">
          <h2 style="color: white;">BST Validation page</h2>
          <hr>
        </div>
      </div>
      <div class="col-md-5 ml-auto">
        <fade-render-transition :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-circle-09"></i>
              </div>
            </div>
            <div class="media-body">
              <p><span style="color: white" v-html="validation_text"></span></p>
            </div>
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="200" :duration="200">
          <div class="media">
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="400" :duration="200">
          <div class="media">
            <div class="media-left">
            </div>
            <div class="media-body">
              <h4></h4>
              <p></p>
            </div>
          </div>
        </fade-render-transition>
      </div>
      <div class="col-md-4 mr-auto">
      </div>
    </div>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ response_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-ok" @click="closeModal('success')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </auth-layout>
</template>
<script>
import { FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import { Dialog } from 'element-ui';
import UserAPIService from "../../../servicehandlers/UsersAPIService";

const usersAPIService = new UserAPIService();

export default {
  data() {
    return {
      response_txt: null,
      validation_text: null,
      error_txt: null,
      spinning: false,
      modals: {
        error: false,
        success: false,
      },
    }
  },
  components: {
    FadeRenderTransition,
    AuthLayout,
    [Dialog.name]: Dialog
  },
  mounted() {
    this.validateUser();
  },
  methods: {
    validateUser() {
      if (!this.$route.query.key) {
        this.error_txt = 'Invalid Key';
        this.openModal('error');
        return;
      }
      if (this.$route.query && this.$route.query.key && this.$route.query.key.length <= 5) {
        this.error_txt = 'Invalid Key';
        this.openModal('error');
        return;
      }
      return usersAPIService.validateUser(this.$route.query.key, this.$router)
      .then((validation_string) => {
        this.validation_text = validation_string;
      })
      .catch((error) => {
        this.error_txt = 'An unexpected error has occurred ' + error.message;
        this.openModal('error');
      });
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    beforeDestroy() {
      this.closeMenu();
    },
  }
}
</script>
<style>
</style>
