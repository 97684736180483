<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div slot="header">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12">
              <h4 class="card-title">Authgroups Table</h4>
              <p class="card-category">
                Create or Edit Authgroups
              </p>
              <p class="card-category">
                <el-button type="primary" @click="openModal('new')" icon="">Create New Auth Group</el-button>
                <el-button type="primary" @click="openModal('users')" icon="">Add User to Authgroups</el-button>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row align-items-center">
            <div class="col-sm-8">
              <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterAuthgroups"></el-input>
            </div>
            <div class="col-sm-4" style="padding-left: 5px;">
              <el-button type="primary" @click="filterAuthgroups" icon="">Search</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-table stripe :data="filteredAuthgroups" style="width: 100%" height="65vh" @sort-change="handleSortChange">
      <el-table-column prop="id" sortable="custom" label="ID" :min-width="100" align="center">
        <template v-slot="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column prop="name" sortable="custom" label="Name" :min-width="200" align="center">
        <template v-slot="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template v-slot="scope">
          <el-button type="danger" @click="preDeleteAuthgroup(scope.row.id, scope.row.name)" icon="el-icon-delete"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </card>
  <el-dialog
    center
    title="Confirm Deletion of Auth Group"
    :visible.sync="modals.basic">
    <div class="text-center">
      <span>Are you sure you want to delete the auth group: {{ delete_authgroup_name }}?</span>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="row">
        <div class="col-sm-6">
          <el-button type="success" @click="deleteAuthGroup" icon="">Confirm</el-button>
        </div>
        <div class="col-sm-6">
          <el-button type="danger" @click="closeModal('basic')" icon="">Cancel</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
  center
  title="Create New Auth Group"
  :visible.sync="modals.new">
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <label>Authgroup Name</label>
        <el-input placeholder="Authgroup Name" v-model="authgroup_name"></el-input>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="row">
        <div class="col-sm-4">
          <el-button type="success" @click="createAuthGroup" icon="">Save</el-button>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <el-button type="danger" @click="closeModal('new')" icon="">Cancel</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Add User to Authgroups"
    :visible.sync="modals.users">
    <div class="row">
      <div class="col-sm-6 text-center">
        <label>Select a User:</label>
        <br>
        <el-select filterable v-model="selected_user_id"
                   placeholder="User" @input="selectUser" style="width: 75%">
          <el-option v-for="user in users"
                     :key="user.id" :value="user.id" :label="returnUserFullName(user)">
          </el-option>
        </el-select>
      </div>
      <div class="col-sm-6 text-center">
        <label>Select the Group(s) to add to the user</label>
        <br>
        <el-select filterable multiple v-model="selected_auth_group_ids"
                   placeholder="Select Authgroup(s)" @input="onSelect" style="width: 75%">
          <el-option v-for="group in authGroups"
                     :key="group.id" :value="group.id" :label="group.name">
          </el-option>
        </el-select>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="row">
        <div class="col-sm-4">
          <el-button type="success" @click="save" icon="">Save</el-button>
        </div>
        <div class="col-sm-4">
          <el-button type="primary" @click="clear" icon="">Clear</el-button>
        </div>
        <div class="col-sm-4">
          <el-button type="danger" @click="closeModal('users')" icon="">Cancel</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Dialog, Option, Select, Button, Table, TableColumn } from 'element-ui';
import { UserAPIService } from 'src/servicehandlers/UsersAPIService';
import { AuthGroupAPIService } from 'src/servicehandlers/AuthGroupAPIService';
const usersAPIService = new UserAPIService();
const authGroupAPIService = new AuthGroupAPIService();

export default {
  name: "UserAuthgroupsAdmin",
  components: {
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      selected_authgroups: [],
      stringItem: '',
      modals: {
        basic: false,
        error: false,
        success: false,
        new: false,
        users: false,
      },
      users: [],
      authgroup_name: null,
      authGroups: [],
      filteredAuthgroups: [],
      delete_authgroup_id: null,
      delete_authgroup_name: null,
      lastSelectItem: null,
      selected_auth_group_ids: [],
      selected_user_id: null,
      selected_authgroup_id: null,
      results: false,
      result_txt: '',
      success_txt: '',
      error_txt: null,
      spinning: false,
      search: '',
    };
  },
  created() {
    this.getAllAuthGroups();
    this.getAllUsers();
  },
  methods: {
    preDeleteAuthgroup(group_id, group_name) {
      this.delete_authgroup_name = group_name;
      this.delete_authgroup_id = group_id;
      this.openModal('basic');
    },
    deleteAuthGroup() {
      this.closeModal('basic');
      this.spinning = true;
      if (!this.delete_authgroup_id) {
        this.error_txt = 'No Authgroup was selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }

      return authGroupAPIService.deleteAuthGroup(this.$router, this.delete_authgroup_id)
      .then(() => {
        this.getAllAuthGroups();
        this.success_txt = 'Successfully deleted authgroup!';
        this.openModal('success');
        this.selected_authgroup_id = null;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Authgroup deletion failed: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    createAuthGroup() {
      this.spinning = true;
      if (!this.authgroup_name) {
        this.error_txt = 'No auth group name input!';
        this.openModal('error');
        this.spinning = false;
        return;
      }

      const parms = {
        name: this.authgroup_name
      };
      return authGroupAPIService.addAuthGroup(this.$router, parms)
      .then((authgroup) => {
        this.getAllAuthGroups();
        this.success_txt = 'Successfully created group: ' + authgroup.name;
        this.closeModal('new')
        this.openModal('success');
        this.authgroup_name = null;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error creating auth group: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    save() {
      this.spinning = true;
      // save the IDS to the database for the user
      if (!this.selected_user_id) {
        this.error_txt = 'No user selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }
      if (this.selected_auth_group_ids.length <= 0) {
        this.error_txt = 'No auth groups selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }
      return authGroupAPIService.addUserToAuthGroups(this.selected_user_id, this.selected_auth_group_ids, this.$router)
      .then(() => {
        this.success_txt = 'Successfully updated groups for user';
        this.openModal('success');
        this.closeModal('users')
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error saving users to groups: ' + error;
        this.openModal('error');
      });
    },
    clear() {
      this.selected_user_id = null;
      this.selected_auth_group_ids = [];
    },
    onSelect (items, lastSelectItem) {
      this.selected_auth_group_ids = items
      this.lastSelectItem = lastSelectItem
    },
    getAllUsers() {
      this.spinning = true;
      return usersAPIService.getUserList(this.$router)
      .then((allUsers) => {
        for (const user of allUsers) {
          user.text = user.first_name + ' ' + user.last_name;
          user.value = user.id;
        }
        this.users = allUsers;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = error;
        this.openModal('error');
      });
    },
    selectUser() {
      // get the full user by ID with scopes, etc, from the DB
      this.spinning = true;
      return usersAPIService.getUserById(this.selected_user_id, this.$router)
      .then((selectedUser) => {
        this.spinning = false;
        const authgroups = selectedUser.auth_groups;
        this.selected_auth_group_ids = []
        for (const group of authgroups) {
          group.text = group.name;
          group.value = group.id;
          this.selected_auth_group_ids.push(group.id)
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting user from DB: ' + error;
        this.openModal('error');
      });
    },
    getAllAuthGroups() {
      this.spinning = true;
      return authGroupAPIService.getAuthGroups(this.$router)
      .then((agps) => {
        this.authGroups = agps;
        for (const group of this.authGroups) {
          group.text = group.name;
          group.value = group.id;
        }
        this.filteredAuthgroups = this.authGroups
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = error;
        this.spinning = false;
        this.openModal('error');
      });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    returnUserFullName(user){
      return user.first_name + ' ' + user.last_name
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredAuthgroups.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredAuthgroups.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterAuthgroups(){
      this.filteredAuthgroups = this.authGroups.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
  }
}
</script>

<style scoped>
el-input,el-select{
  width: 100%;
}
</style>
