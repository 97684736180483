import CommonAPIService from './CommonHandler';

export class BreakingTechniqueToOutlineAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  update(technique_to_outline_id, technique_to_outline, router) {
    const url = `/api/breaking_technique_to_outlines/${technique_to_outline_id}`;
    return this.CommonAPIService.putCall(url, technique_to_outline, router);
  }
}

export default BreakingTechniqueToOutlineAPIService;
