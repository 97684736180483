import CommonAPIService from './CommonHandler';

export class TestingTypesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getTestingTypes(router) {
    const url = '/api/testing_types';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getTestingType(testing_type_id, router) {
    const url = `/api/testing_types/${testing_type_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addTestingType(testing_type, router) {
    const url = '/api/testing_types';
    return this.CommonAPIService.postCall(url, testing_type, router);
  }

  updateTestingType(testing_typeId, testing_type, router) {
    const url = `/api/testing_types/${testing_typeId}`;
    return this.CommonAPIService.putCall(url, testing_type, router);
  }

  deleteTestingType(testing_typeId, router) {
    const url = `/api/testing_types/${testing_typeId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  getTestingTypesByIDWithOutlines(testing_typeId, router) {
    const url = `/api/testing_types/${testing_typeId}/testing_typeoutline`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getTestingTypesForUser(user_id, router) {
    const url = `/api/testing_types/${user_id}/user`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default TestingTypesAPIService;
