<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div slot="header">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12">
              <h4 class="card-title">Scopes Table</h4>
              <p class="card-category">
                Create or Edit Scopes
              </p>
              <p class="card-category">
                <el-button type="primary" @click="openModal('new')" icon="">Create New Scope</el-button>
                <el-button type="primary" @click="openModal('groups')" icon="">Add Authgroups to Scope</el-button>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row align-items-center">
            <div class="col-sm-8">
              <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterScopes"></el-input>
            </div>
            <div class="col-sm-4" style="padding-left: 5px;">
              <el-button type="primary" @click="filterScopes" icon="">Search</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-table stripe :data="filteredScopes" style="width: 100%" height="65vh" @sort-change="handleSortChange">
      <el-table-column prop="name" sortable="custom" label="Name" :min-width="100" align="center">
        <template v-slot="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="Description" :min-width="200" align="center">
        <template v-slot="scope">
          {{ scope.row.description }}
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template v-slot="scope">
          <el-button type="danger" @click="confirmDelete(scope.row.id, scope.row.name)" icon="el-icon-delete"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </card>
  <el-dialog
    center
    title="Confirm Deletion of Scope"
    :visible.sync="modals.basic">
    <span>Are you sure you want to delete the scope: {{ delete_scope_name }}?</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="closeModal('basic')" icon="">Cancel</el-button>
      <el-button type="success" @click="deleteScope()" icon="">Confirm</el-button>
    </span>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Create New Scope"
    :visible.sync="modals.new">
    <div class="row">
      <div class="col-sm-6">
        <label>Scope Name:</label>
        <el-input placeholder="Scope Name" v-model="scope_name"></el-input>
      </div>
      <div class="col-sm-6">
        <label>Scope Description:</label>
        <el-input placeholder="Scope Description" v-model="scope_description"></el-input>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="row">
        <div class="col-sm-4">
          <el-button type="success" @click="addScope" icon="">Save</el-button>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <el-button type="danger" @click="closeModal('new')" icon="">Cancel</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Add Authgroups to Scope"
    :visible.sync="modals.groups">
    <div class="row">
      <div class="col-sm-6 text-center">
        <label>Select a Authgroup:</label>
        <br>
        <el-select filterable v-model="selected_authgroup_id"
                   placeholder="Auth Group" @input="selectAuthgroup" style="width: 75%">
          <el-option v-for="group in authGroups"
                     :key="group.id" :value="group.id" :label="group.name">
          </el-option>
        </el-select>
      </div>
      <div class="col-sm-6 text-center">
        <label>Select the Scope(s) to add to the Authgroup</label>
        <br>
        <el-select filterable multiple v-model="selected_scope_ids"
                   placeholder="Select Scope(s)" @input="onSelect" style="width: 75%">
          <el-option v-for="scope in scopes"
                     :key="scope.id" :value="scope.id" :label="scope.text">
          </el-option>
        </el-select>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="row">
        <div class="col-sm-4">
          <el-button type="success" @click="saveScopes" icon="">Save</el-button>
        </div>
        <div class="col-sm-4">
          <el-button type="primary" @click="clear" icon="">Clear</el-button>
        </div>
        <div class="col-sm-4">
          <el-button type="danger" @click="closeModal('groups')" icon="">Cancel</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Dialog, Option, Select, Button, Input, Table, TableColumn } from 'element-ui';
import { ScopesAPIService } from 'src/servicehandlers/ScopesAPIService';
import { AuthGroupAPIService } from 'src/servicehandlers/AuthGroupAPIService';
const scopesAPIService = new ScopesAPIService();
const authGroupAPIService = new AuthGroupAPIService();

export default {
  components: {
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Input.name]: Input,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  name: "AuthGroupsScopesAdmin",
  data() {
    return {
      spinning: false,
      scope_name: null,
      authGroups: [],
      scope_description: null,
      delete_scope_id: null,
      delete_scope_name: null,
      selected_authgroup_id: null,
      last_selected_item: null,
      selected_scope_ids: [],
      modals: {
        error: false,
        success: false,
        basic: false,
        new: false,
        groups: false,
      },
      scopes: [],
      filteredScopes: [],
      success_txt: '',
      error_txt: null,
      search: '',
    };
  },
  created() {
    this.getAllScopes();
    this.getAllAuthgroups();
  },
  methods: {
    saveScopes() {
      this.spinning = true;
      if (!this.selected_authgroup_id) {
        this.spinning = false;
        this.error_txt = 'No authgroup was selected!';
        this.openModal('error');
        return;
      }
      if (this.selected_scope_ids.length <= 0) {
        this.spinning = false;
        this.error_txt = 'No scopes were selected!';
        this.openModal('error');
        return;
      }

      return scopesAPIService.addScopesToAuthgroup(this.selected_authgroup_id, this.selected_scope_ids, this.$router)
      .then(() => {
        this.clear();
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully added scopes to authgroup!';
        this.openModal('success');
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'An error occurred while adding scopes to groups: ' + error;
        this.openModal('error');
      });
    },
    clear() {
      this.selected_authgroup_id = null;
      this.selected_scope_ids = [];
    },
    selectAuthgroup() {
      // get the full user by ID with scopes, etc, from the DB
      this.spinning = true;
      return authGroupAPIService.getAuthGroupsById(this.$router, this.selected_authgroup_id)
      .then((selectedAuthgroup) => {
        this.spinning = false;
        const allScopes = selectedAuthgroup.scopes;
        this.selected_scope_ids = []
        for (const scope of allScopes) {
          scope.text = scope.name;
          scope.value = scope.id;
          this.selected_scope_ids.push(scope.id)
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting scopes for auth group: ' + error;
        this.openModal('error');
      });
    },
    getAllAuthgroups() {
      this.spinning = true;
      return authGroupAPIService.getAuthGroups(this.$router)
      .then((agps) => {
        this.authGroups = agps;
        for (const group of this.authGroups) {
          group.text = group.name;
          group.value = group.id;
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = error;
        this.spinning = false;
        this.openModal('error');
      });
    },
    onSelect (items, lastSelectItem) {
      this.selected_scope_ids = items
      this.lastSelectItem = lastSelectItem
    },
    confirmDelete(scope_id, scope_name,) {
      this.delete_scope_id = scope_id;
      this.delete_scope_name = scope_name;
      this.openModal('basic');
    },
    deleteScope() {
      this.closeModal('basic');
      this.spinning = true;
      if (!this.delete_scope_id) {
        this.spinning = false;
        this.error_txt = 'No scope was selected';
        this.openModal('error');
        return;
      }

      return scopesAPIService.deleteScope(this.delete_scope_id)
      .then(() => {
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully deleted scope!';
        this.openModal('success');
        this.delete_scope_id = null;
        this.delete_scope_name = null;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Scope deletion failed: ' + error;
        this.openModal('error');
      });
    },
    addScope() {
      this.spinning = true;
      if (!this.scope_name) {
        this.spinning = false;
        this.error_txt = 'No scope name set';
        this.openModal('error');
        return;
      }
      if (!this.scope_description) {
        this.spinning = false;
        this.error_txt = 'No scope description set';
        this.openModal('error');
        return;
      }

      const scopeData = {
        name: this.scope_name,
        description: this.scope_description,
      };

      return scopesAPIService.createScope(scopeData, this.$router)
      .then(() => {
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully created scope!';
        this.closeModal('new')
        this.openModal('success');
        this.scope_name = null;
        this.scope_description = null;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Scope creation failed: ' + error;
        this.openModal('error');
      });
    },
    getAllScopes() {
      this.spinning = true;
      return scopesAPIService.getScopes(this.$router)
      .then((response) => {
        this.spinning = false;
        this.scopes = response;
        for (const scope of this.scopes) {
          scope.text = scope.name;
          scope.value = scope.id;
        }
        this.filteredScopes = this.scopes
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting scopes!: ' + error;
        this.openModal('error');
      });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredScopes.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredScopes.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterScopes(){
      this.filteredScopes = this.scopes.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
  }
}
</script>

<style scoped>

</style>
