import CommonAPIService from './CommonHandler';

export class PenaltiesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getPenalties(router) {
    const url = '/api/penalties';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addEvent(penalty, router) {
    const url = '/api/penalties';
    return this.CommonAPIService.postCall(url, penalty, router);
  }

  updateEvent(penaltyId, penalty, router) {
    const url = `/api/penalties/${penaltyId}`;
    return this.CommonAPIService.putCall(url, penalty, router);
  }

  deleteEvent(penaltyId, router) {
    const url = `/api/penalties/${penaltyId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default PenaltiesAPIService;
