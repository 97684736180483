<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">Breaking Techniques Table</h4>
        <p class="card-category">Displays full list of the breaking techniques in the system</p>
        <p class="card-category">
          <el-button type="primary" @click="openModal('create')">Add New Technique</el-button>
        </p>
      </div>
      <el-table stripe :data="techniques" height="65vh" style="width: 100%">
        <el-table-column :min-width="225" sortable prop="name" label="Name">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" placeholder="Name" @change="updateTechnique(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column :min-width="200" sortable prop="description" label="Description">
          <template slot-scope="scope">
            <el-input v-model="scope.row.description" placeholder="Description" @change="updateTechnique(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column sortable :min-width="100" label="''">
          <template slot="header">
            <div>Tech<br>Type</div>
          </template>
          <template slot-scope="scope">
            <el-select @input="toggleSelected" v-model="scope.row.breaking_technique_type_id" placeholder="Select Technique" @change="updateTechnique(scope.row)">
              <el-option
                v-for="technique_type in technique_types"
                :key="technique_type.id"
                :label="technique_type.name"
                :value="technique_type.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="''" class="text-center form-control">
          <template slot="header">
            <div>Power<br>Break</div>
          </template>
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="Click here to set or unset as a power kick">
              <el-checkbox name="power_break" v-model="scope.row.is_power_break" @change="updateTechnique(scope.row)"></el-checkbox>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="''">
          <template slot="header">
            <div>Spin<br>/<br>Jump</div>
          </template>
          <template slot-scope="scope">
          <el-tooltip effect="dark" content="click here to set or unset it as spinning">
            <el-checkbox name="is_spinning" v-model="scope.row.is_spinning" @change="updateTechnique(scope.row)">S</el-checkbox>
          </el-tooltip>
          <br>
          <el-tooltip effect="dark" content="Click here to set or unset it as a jumping kick">
            <el-checkbox name="power_break" v-model="scope.row.is_jump" @change="updateTechnique(scope.row)">J</el-checkbox>
          </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Technique/Kick">
          <template slot="header">
            <div>Tech<br>/<br>Kick</div>
          </template>
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="click here to set or unset it as a kick">
              <el-checkbox name="power_break" v-model="scope.row.is_kick" @change="updateTechnique(scope.row)">K</el-checkbox>
            </el-tooltip>
            <br>
            <el-tooltip effect="dark" content="Click here to set or unset it as a technique">
              <el-checkbox name="power_break" v-model="scope.row.is_technique" @change="updateTechnique(scope.row)">T</el-checkbox>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column sortable label="A-" prop="a_minus">
          <template slot-scope="scope">
            <el-input type="number" min="0" placeholder="A Minus" v-model="scope.row.a_minus" @change="updateTechnique(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column sortable label="''" prop="base_point_value">
          <template slot="header">
            <div>Base<br>Point<br>Value</div>
          </template>
          <template slot-scope="scope">
            <el-input type="number" min="0" placeholder="Base Point Value" v-model="scope.row.base_point_value" @change="updateTechnique(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column sortable label="''" prop="board_multiplier">
          <template slot="header">
            <div>Board<br>Multiplier</div>
          </template>
          <template slot-scope="scope">
            <el-input type="number" min="0" placeholder="Board Multiplier" v-model="scope.row.board_multiplier" @change="updateTechnique(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column :min-width="100" sortable searchable label="Directions">
          <template slot-scope="scope">
            <el-button type="primary" @click="addDirections(scope.row)">Dir</el-button>
          </template>
        </el-table-column>
        <el-table-column sortable label="Notes" prop="notes">
          <template slot-scope="scope">
            <el-button type="primary" @click="addEditNotes(scope.row)" v-if="scope.row.notes && scope.row.notes.length > 0">Edit</el-button>
            <el-button v-else type="primary" @click="addEditNotes(scope.row)">Add</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <el-button type="danger" @click="deleteTechnique(scope.row.value)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>

    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Directions"
      :visible.sync="modals.directions">
      <div class="text-center">
        <el-select multiple v-model="breaking_technique_directions" placeholder="Select Directions for the technique">
          <el-option
            v-for="direction in directions"
            :key="direction.id"
            :label="direction.name"
            :value="direction.id">
          </el-option>
        </el-select>
        <br>
        <span slot="footer" class="dialog-footer">
<el-button type="danger" @click="closeModal('directions')">Cancel</el-button>&nbsp;
<el-button type="primary" @click="saveDirections">Save</el-button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Notes"
      :visible.sync="modals.notes">
      <div class="text-center">
        <span>Notes</span>
        <el-input type="text" placeholder="Notes" v-model="selectedRow.notes"></el-input>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('notes')">Cancel</el-button>&nbsp;
          <el-button type="primary" @click="saveNotes">Save</el-button>&nbsp;
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Create Technique"
      :visible.sync="modals.create">
      <div class="row align-items-center">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-6">
              <label>Name:</label>
              <el-input v-model="new_technique.name" placeholder="Name"></el-input>
            </div>
            <div class="col-sm-6">
              <label>Description:</label>
              <el-input v-model="new_technique.description"
                        placeholder="Description"></el-input>
            </div>
            <div class="col-sm-6">
              <label>Technique Type:</label>
              <el-select v-model="new_technique.breaking_technique_type_id" placeholder="Technique Type" style="width: 100%" @input="toggleSelected">
                <el-option
                  v-for="type in technique_types"
                  :key="type.id"
                  :label="type.name"
                  :value="type.id">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
              <label>Notes:</label>
              <el-input v-model="new_technique.notes" placeholder="Notes"></el-input>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-4">
              <label>A Minus:</label>
              <el-input type="number" min=0 v-model="new_technique.a_minus"
                        placeholder="A Minus"></el-input>
            </div>
            <div class="col-sm-4">
              <label>Base Point Value:</label>
              <el-input type="number" min=0 v-model="new_technique.base_point_value"
                        placeholder="Base Point Value"></el-input>
            </div>
            <div class="col-sm-4">
              <label>Board Multiplier:</label>
              <el-input type="number" min=0 v-model="new_technique.board_multiplier"
                        placeholder="Board Multiplier"></el-input>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row text-center">
            <div class="col-sm-4">
              <br>
              <el-checkbox v-model="new_technique.is_power_break" size="large" border>Is Power Break</el-checkbox>
            </div>
            <div class="col-sm-4">
              <br>
              <el-checkbox v-model="new_technique.is_kick" size="large" border>Is Kick</el-checkbox>
            </div>
            <div class="col-sm-4">
              <br>
              <el-checkbox v-model="new_technique.is_technique" size="large" border>Is Technique</el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" @click="saveTechnique('create')" icon="">Save</el-button>
        <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Tooltip, Checkbox, Button, Select, Option, Table, TableColumn, Dialog } from 'element-ui';
import BreakingTechniquesAPIService from "src/servicehandlers/BreakingTechniquesAPIService";
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
import TechniquesAPIService from "src/servicehandlers/TechniquesAPIService";
const techniquesAPIService = new TechniquesAPIService();
import KicksAPIService from "src/servicehandlers/KicksAPIService";
const kicksAPIService = new KicksAPIService();
import DirectionsAPIService from "src/servicehandlers/DirectionsAPIService";
const directionsAPIService = new DirectionsAPIService();

export default {
  name: "BreakingTechniqueAdmin",
  components: {
    [Tooltip.name]: Tooltip,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      selectedRow: {
        notes: '',
      },
      spinning: false,
      techniques: [],
      currentRowSelected: null,
      breaking_technique_directions: [],
      technique_types: [],
      directions: [{id: 1, name: 'forward', value: 1},{id: 2, name: 'down', value: 2},{id: 8, name: 'N/A', value: 8}],
      new_technique: {},
      selected_technique: {},
      is_technique_selected: false,
      selected_technique_id: null,
      error_txt: null,
      success_txt: null,
      modals: {
        error: false,
        success: false,
        person: false,
        create: false,
        notes: false,
        directions: false,
      },
    }
  },
  mounted() {
    this.getTechniques();
    this.getDirections();
  },
  methods: {
    getDirections() {
      return directionsAPIService.getAllDirections(this.$router)
      .then((response) => {
        this.directions = response;
      })
      .catch((error) => {
        this.error_txt = 'Error getting directions: ' + error;
        this.openModal('error');
      });
    },
    toggleSelected() {
      this.is_technique_selected = true;
      for (const technique of this.techniques) {
        if (this.selected_technique_id && this.selected_technique_id === technique.id) {
          this.selected_technique = technique;
          return;
        }
      }
    },
    addEditNotes(row) {
      this.selectedRow = row;
      this.openModal('notes');
    },
    getTechniques() {
      return breakingTechniquesAPIService.getBreakingTechniques(this.$router)
      .then((response) => {
        this.techniques = response;
        for (const technique of this.techniques) {
          technique.text = technique.name;
          technique.value = technique.id;
        }
        return breakingTechniquesAPIService.getBreakingTechniqueTypes(this.$router)
      })
      .then((response2) => {
        for (const type of response2) {
          type.value = type.id;
          type.text = type.name;
        }
        this.technique_types = response2;
      })
      .catch((error) => {
        this.error_txt = 'Error getting techniques: ' + error;
        this.openModal('error');
      });
    },
    async saveTechnique(name) {
      // not a breaking technique, insert into the technique table
      this.new_technique.single = true;
      this.new_technique.is_breaking = true;
      let new_breaking_technique = null;
      try {
        new_breaking_technique = await breakingTechniquesAPIService.addBreakingTechnique(this.new_technique, this.$router)
      } catch (e) {
        this.error_txt = 'Saving breaking techniques failed: ' + e;
        this.openModal('error');
        return;
      }
      if (!new_breaking_technique.id) {
        this.error_txt = 'Saving breaking techniques failed: ';
        this.openModal('error');
        return;
      }

      if (new_breaking_technique.is_kick) {
        let newKick = null;
        try {
          const updated_kick = {
            name: new_breaking_technique.name,
            is_breaking: true,
            is_spinning: new_breaking_technique.is_spinning,
            description: new_breaking_technique.description,
            korean_name: new_breaking_technique.korean_name,
            abbreviation: new_breaking_technique.abbreviation,
            a_minus: new_breaking_technique.a_minus,
            breaking_technique_type_id: new_breaking_technique.breaking_technique_type_id,
            base_point_value: new_breaking_technique.base_point_value,
            board_multiplier: new_breaking_technique.board_multiplier,
            sync_to_breaking_techniques: new_breaking_technique.id,
          };
          newKick = await kicksAPIService.addKick(updated_kick, this.$router);
          if (newKick.id) {
            // update the breaking to technique table
            const parms = {
              sync_to_kicks: newKick.id
            };
            const updated_breaking_technique = await breakingTechniquesAPIService.updateBreakingTechnique(new_breaking_technique.id, parms, this.$router);
          }
        } catch(e) {
          this.error_txt = 'Saving kick failed: ';
          this.openModal('error');
          return;
        }
      }
      if (new_breaking_technique.is_technique) {
        let newTechnique = null;
        try {
          const updated_technique = {
            name: new_breaking_technique.name,
            is_breaking: true,
            is_spinning: new_breaking_technique.is_spinning,
            description: new_breaking_technique.description,
            korean_name: new_breaking_technique.korean_name,
            abbreviation: new_breaking_technique.abbreviation,
            a_minus: new_breaking_technique.a_minus,
            breaking_technique_type_id: new_breaking_technique.breaking_technique_type_id,
            base_point_value: new_breaking_technique.base_point_value,
            board_multiplier: new_breaking_technique.board_multiplier,
            sync_to_breaking_techniques: new_breaking_technique.id,
          };
          newTechnique = await techniquesAPIService.addTechnique(updated_technique, this.$router);
          if (newTechnique.id) {
            // update the breaking to technique table
            const parms = {
              sync_to_techniques: newTechnique.id
            };
            const updated_breaking_technique = await breakingTechniquesAPIService.updateBreakingTechnique(new_breaking_technique.id, parms, this.$router);
          }
        } catch(e) {
          this.error_txt = 'Saving techniques failed: ';
          this.openModal('error');
          return;
        }
      }

      this.techniques = [];
      this.getTechniques();
      this.closeModal(name);
      this.new_technique = {};
      this.selected_technique = {};
      const self = this;
      this.success_txt = 'Technique Saved successfully';
      this.openModal('success');
      setTimeout(function () {
        self.modals['success'] = false; // Use your variable name
      }, 1500); // Hide after 5 secs
    },
    async updateTechnique(technique) {
      technique.id = technique.value;
      // goal sync it up with its "sync partner" if needed
      return breakingTechniquesAPIService.updateBreakingTechnique(technique.value, technique, this.$router)
      .then(async (response) => {
        // scenario 1, if the kick_id is set, we definitely do an update, if not we create one
        // after creating, we update our kick
        if (technique.is_technique) {
          const updated_technique = {
            name: technique.name,
            notes: technique.notes,
            is_breaking: true,
            is_spinning: technique.is_spinning,
            description: technique.description,
            korean_name: technique.korean_name,
            abbreviation: technique.abbreviation,
            breaking_technique_type_id: technique.breaking_technique_type_id,
            a_minus: technique.a_minus,
            selected_technique_id: technique.selected_technique_id,
            base_point_value: technique.base_point_value,
            board_multiplier: technique.board_multiplier,
          };
          if (technique.sync_to_techniques) {
            // update the techniques table
            let synced_technique = await techniquesAPIService.updateTechnique(technique.sync_to_techniques, updated_technique, this.$router);
          } else if (!technique.sync_to_techniques) {
            updated_technique.sync_to_breaking_techniques = technique.id;
            let synced_technique = await techniquesAPIService.addTechnique(updated_technique, this.$router);
            const parms = {
              sync_to_techniques: synced_technique.id
            };
            let updated_breaking = await breakingTechniquesAPIService.updateBreakingTechnique(technique.value, parms, this.$router);
          }
        }
        if (technique.is_kick) {
          // update the techniques table
          const updated_kick = {
            name: technique.name,
            is_breaking: true,
            notes: technique.notes,
            is_spinning: technique.is_spinning,
            description: technique.description,
            korean_name: technique.korean_name,
            abbreviation: technique.abbreviation,
            a_minus: technique.a_minus,
            breaking_technique_type_id: technique.breaking_technique_type_id,
            base_point_value: technique.base_point_value,
            board_multiplier: technique.board_multiplier,
          };
          if (technique.sync_to_kicks) {
            // update the techniques table
            let synced_kick = await kicksAPIService.updateKick(technique.sync_to_kicks, updated_kick, this.$router);
          } else if (!technique.sync_to_kicks) {
            updated_kick.sync_to_breaking_techniques = technique.id;
            let synced_kick = await kicksAPIService.addKick(updated_kick, this.$router);
            const parms = {
              sync_to_kicks: synced_kick.id
            };
            let updated_breaking = await breakingTechniquesAPIService.updateBreakingTechnique(technique.value, parms, this.$router);
          }
        }
        const self = this;
        this.success_txt = 'Technique update successful';
        this.getTechniques();
        /*this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs*/
      })
      .catch((error) => {
        this.error_txt = 'Error updating Technique: ' + error;
        this.openModal('error');
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    saveNotes() {
      this.closeModal('notes');
    },
    async addDirections(row) {
      this.breaking_technique_directions = [];
      this.currentRowSelected = row.id;
      try {
        const allDirections = await breakingTechniquesAPIService.getBreakingTechniqueWithDirections(this.currentRowSelected, this.$router);
        const alldirs = [];
        if (allDirections && allDirections.directions) {
          for (const dir of allDirections.directions) {
            this.breaking_technique_directions.push(dir.id);
          }
        }
        this.openModal('directions');
      } catch(e) {
        this.error_txt = 'Error getting directions for breaking technique: ' + e;
        this.openModal('error');
      }
    },
    saveDirections() {
      this.closeModal('directions');
      return breakingTechniquesAPIService.addDirectionsToBreakingTechnique(this.currentRowSelected, this.breaking_technique_directions, this.$router)
      .then((response) => {
        const self = this;
        this.success_txt = 'Add Breaking technique Directions successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error adding directions to technique: ' + error;
        this.openModal('error');
      })
    },
    deleteTechnique(id) {
      return breakingTechniquesAPIService.deleteBreakingTechnique(id, this.$router)
      .then(() => {
        this.techniques = [];
        this.selected_technique = {};
        this.new_technique = {};
        this.getTechniques();
        const self = this;
        this.success_txt = 'Delete Technique successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error deleting technique: ' + error;
        this.openModal('error');
      });
    },
  }
}
</script>

<style scoped>
</style>
