<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-md-6">
          <label>Select an outline to judge:</label>
          <el-select filterable v-model="outlineSelected" placeholder="Outline To Judge" @input="getSingleOutline">
            <el-option v-for="outline in outlines"
                       :key="outline.id" :value="outline.id" :label="outline.text">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-6">
          <button class="btn btn-primary" style="float: right" @click="clearOutline">Remove Judges</button>
        </div>
      </div>
    </card>
    <card v-if="outlineSelected">
      <div class="row">
        <div class="col-md-12 text-center">
          <label style="font-weight: bold; font-size: 2em;">Select the judges for {{outlineName}}</label>
        </div>
      </div>
      <div class="row">
        <div class="custom-col-1">
          <label>Judge 1</label>
          <br>
          <el-select filterable v-model="j1_selected"
                     placeholder="J1 Judge Selection" @input="setSelectedJudge(1)">
            <el-option v-for="user in users"
                       :key="user.id" :value="user.id" :label="returnUserFullName(user)">
            </el-option>
          </el-select>
        </div>
        <div class="custom-col-1">
          <label>Judge 2</label>
          <br>
          <el-select filterable v-model="j2_selected"
                     placeholder="J2 Judge Selection" @input="setSelectedJudge(2)">
            <el-option v-for="user in users"
                       :key="user.id" :value="user.id" :label="returnUserFullName(user)">
            </el-option>
          </el-select>
        </div>
        <div class="custom-col-1">
          <label>Judge 3</label>
          <br>
          <el-select filterable v-model="j3_selected"
                     placeholder="J3 Judge Selection" @input="setSelectedJudge(3)">
            <el-option v-for="user in users"
                       :key="user.id" :value="user.id" :label="returnUserFullName(user)">
            </el-option>
          </el-select>
        </div>
        <div class="custom-col-1">
          <label>Judge 4</label>
          <br>
          <el-select filterable v-model="j4_selected"
                     placeholder="J4 Judge Selection" @input="setSelectedJudge(4)">
            <el-option v-for="user in users"
                       :key="user.id" :value="user.id" :label="returnUserFullName(user)">
            </el-option>
          </el-select>
        </div>
        <div class="custom-col-1">
          <label>Judge 5</label>
          <br>
          <el-select filterable v-model="j5_selected"
                     placeholder="J5 Judge Selection" @input="setSelectedJudge(5)">
            <el-option v-for="user in users"
                       :key="user.id" :value="user.id" :label="returnUserFullName(user)">
            </el-option>
          </el-select>
        </div>
      </div>
    </card>
    <card v-if="response">
      <div class="row">
        <div class="col-md-12 text-center">
          {{ response_txt }}
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Option, Select } from 'element-ui';
import { ModelSelect } from 'vue-search-select';
import UsersAPIService from "../../../../servicehandlers/UsersAPIService";
import OutlinesAPIService from "../../../../servicehandlers/OutlinesAPIService";
import JudgeadminAPIService from "../../../../servicehandlers/JudgeAdminAPIService";

const userAPIService = new UsersAPIService();
const outlinesAPIService = new OutlinesAPIService();
const judgeadminAPIService = new JudgeadminAPIService();

export default {
  name: "JudgeAdmin",
  components: {
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      users: [],
      response: false,
      response_txt: '',
      outlines: [],
      outline: null,
      outlineSelected: null,
      outlineName: null,
      j1_selected: null,
      j2_selected: null,
      j3_selected: null,
      j4_selected: null,
      j5_selected: null,
      error_txt: null,
      spinning: true,
      modals: {
        error: false,
      },
    }
  },
  mounted() {
    this.getCurrentOutline();
    this.loadUsers();
    this.loadOutlines();
  },
  methods: {
    getCurrentOutline() {
      return judgeadminAPIService.getCurrentOutline(this.$router)
      .then((response) => {
        this.outlineSelected = response.current_outline;
        this.getSingleOutline();
      })
      .catch((error) => {
        this.error_txt = 'Error getting outline selected: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    clearOutline() {
      this.spinning = true;
      const parms = {
        j1_user: null,
        j2_user: null,
        j3_user: null,
        j4_user: null,
        j5_user: null,
      };
      outlinesAPIService.updateSingleOutline(this.outlineSelected, parms, this.$router)
      .then((response) => {
        this.response_txt = 'SUCCESS: Done updating outline';
        return outlinesAPIService.getOutline(this.outlineSelected, this.$router);
      })
      .then((outline) => {
        this.spinning = false;
        this.response = true;
        this.outline = outline;
        this.j1_selected = outline.j1_user;
        this.j2_selected = outline.j2_user;
        this.j3_selected = outline.j3_user;
        this.j4_selected = outline.j4_user;
        this.j5_selected = outline.j5_user;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error clearing the outline: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getSingleOutline() {
      this.j1_selected = false;
      this.j2_selected = false;
      this.j3_selected = false;
      this.j4_selected = false;
      this.j5_selected = false;
      return outlinesAPIService.getOutline(this.outlineSelected, this.$router)
      .then((outline) => {
        this.outline = outline;
        this.j1_selected = outline.j1_user;
        this.j2_selected = outline.j2_user;
        this.j3_selected = outline.j3_user;
        this.j4_selected = outline.j4_user;
        this.j5_selected = outline.j5_user;
        this.outlineName = outline.description
        return judgeadminAPIService.updateJudgeAdmin(1, { current_outline: this.outlineSelected }, this.$router);
      })
      .then(() => {
        // do nothing, we're done updating and setting
      })
      .catch((error) => {
        this.error_txt = 'Error pulling single outline: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    setSelectedJudge(num) {
      this.spinning = true;
      if (num === 1) {
        return outlinesAPIService.updateSingleOutline(this.outlineSelected, { j1_user: this.j1_selected }, this.$router)
        .then(() => {
          const parms = {
            current_outline: this.outlineSelected,
          };
          return userAPIService.updateUser(this.j1_selected, parms, this.$router);
        })
        .then(() => {
          this.spinning = false;
        })
        .catch((error) => {
          this.error_txt = 'Error updating judges: ' + error;
          this.openModal('error');
          this.spinning = false;
        });
      }
      if (num === 2) {
        return outlinesAPIService.updateSingleOutline(this.outlineSelected, { j2_user: this.j2_selected }, this.$router)
        .then(() => {
          const parms = {
            current_outline: this.outlineSelected,
          };
          return userAPIService.updateUser(this.j2_selected, parms, this.$router);
        })
        .then(() => {
          this.spinning = false;
        })
        .catch((error) => {
          this.error_txt = 'Error updating judges: ' + error;
          this.openModal('error');
          this.spinning = false;
        });
      }
      if (num === 3) {
        return outlinesAPIService.updateSingleOutline(this.outlineSelected, { j3_user: this.j3_selected }, this.$router)
        .then(() => {
          const parms = {
            current_outline: this.outlineSelected,
          };
          return userAPIService.updateUser(this.j3_selected, parms, this.$router);
        })
        .then(() => {
          this.spinning = false;
        })
        .catch((error) => {
          this.error_txt = 'Error updating judges: ' + error;
          this.openModal('error');
          this.spinning = false;
        });
      }
      if (num === 4) {
        return outlinesAPIService.updateSingleOutline(this.outlineSelected, { j4_user: this.j4_selected }, this.$router)
        .then(() => {
          const parms = {
            current_outline: this.outlineSelected,
          };
          return userAPIService.updateUser(this.j4_selected, parms, this.$router);
        })
        .then(() => {
          this.spinning = false;
        })
        .catch((error) => {
          this.error_txt = 'Error updating judges: ' + error;
          this.openModal('error');
          this.spinning = false;
        });
      }
      if (num === 5) {
        return outlinesAPIService.updateSingleOutline(this.outlineSelected, { j5_user: this.j5_selected }, this.$router)
        .then(() => {
          const parms = {
            current_outline: this.outlineSelected,
          };
          return userAPIService.updateUser(this.j5_selected, parms, this.$router);
        })
        .then(() => {
          this.spinning = false;
        })
        .catch((error) => {
          this.error_txt = 'Error updating judges: ' + error;
          this.openModal('error');
          this.spinning = false;
        });
      }
    },
    loadOutlines() {
      this.spinning = true;
      outlinesAPIService.getOutlines(this.$router)
      .then((allOutlines) => {
        for (const outline of allOutlines) {
          outline.value = outline.id;
          outline.text = outline.description;
        }
        this.outlines = allOutlines;
        this.spinning = false;
      })
      .catch(() => {
        this.error_txt = 'Error loading outlines';
        this.openModal('error');
        this.spinning = false;
      });
    },
    loadUsers() {
      this.spinning = true;
      userAPIService.getUserList(this.$router)
      .then((allUsers) => {
        for (const user of allUsers) {
          user.value = user.id;
          user.text = user.first_name + ' ' + user.last_name;
        }
        this.users = allUsers;
        this.spinning = false;
      })
      .catch(() => {
        this.error_txt = 'Error loading active users';
        this.openModal('error');
        this.spinning = false;
      });
    },
    returnUserFullName(user){
      return user.first_name + ' ' + user.last_name
    }
  },
}
</script>

<style scoped>
.custom-col-1{
  -ms-flex:0 0 20%;
  flex:0 0 20%;
  max-width: 20%;
  align-self: center;
}
</style>
