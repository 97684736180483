<template>
  <nav class="navbar navbar-expand-lg ">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"></i>
        </button>
      </div>
      <a class="navbar-brand">{{this.$route.name}}</a>
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-expanded="false"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <el-button v-if="this.$store.getters.inProxy" type="primary" @click="leaveProxy" icon="">Leave Proxy</el-button>
        <ul class="navbar-nav">
          <drop-down position="right">
            <i slot="title" class="nc-icon nc-bullet-list-67"></i>
            <a @click="resetPassword" class="dropdown-item">
              Profile
            </a>
            <a @click="logout" class="dropdown-item text-danger">
              <i class="nc-icon nc-button-power"></i> Log out
            </a>
          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { Button } from 'element-ui';
import CommonAPIService from "../../../servicehandlers/CommonHandler";

const commonAPISerivce = new CommonAPIService()
  export default {
    components: {
      [Button.name]: Button,
    },
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    data () {
      return {
        activeNotifications: false
      }
    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      },
      logout() {
        localStorage.removeItem('jwtToken');
        this.$store.dispatch('LOGOUT');
        this.$router.push({
          name: 'Login'
        });
      },
      leaveProxy(){
        let loggedIn = this.$store.getters.loggedIn;
        let userID = this.$store.getters.userId
        let errors = []
        return commonAPISerivce.proxylogin(userID,this.$store.getters.proxyUserId, true, this.$router, this.$store, errors)
          .then((response) => {
            if (response && response.success) {
              loggedIn = this.$store.getters.loggedIn;
              if (loggedIn) {
                this.goToUsers()
              }
            } else {
            }
          })
          .catch((error) => {
          });
      },
      goToHome() {
        // this.rightsArray = this.loginCheckMixin(['page-claim', 'page-firm']);
        // let homePage=this.$store.state.userPreferences.home === 'firms' ? 'FirmsSearch' : 'ClaimsSearch';
        // let homePage=this.$store.state.userPreferences.home === 'claims' ? 'ClaimsSearch' : 'FirmsSearch';
        // homePage is preferred page if user has rights to see, else other search
        // based on all having at least one of these two page rights
        // if (!this.rightsArray.includes('page-firm')) homePage = 'ClaimsSearch';
        // if (!this.rightsArray.includes('page-claim')) homePage = 'FirmsSearch';
        this.$router.push({
          name: 'main'
        });
        location.reload()
      },
      goToUsers() {
        this.$router.push({
          path: '/adminmain/users'
        });
        location.reload()
      },
      resetPassword(){
        this.$router.push({
          name: 'Reset Password'
        });
      },
    }
  }

</script>
<style>

</style>
