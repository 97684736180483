<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-2">
          <span style="font-weight: bold;">{{ outlineColumns[0] }}</span>
        </div>
        <div class="col-md-4">
          <span style="font-weight: bold;">{{ outlineColumns[1] }}</span>
        </div>
        <div class="col-md-2">
          <span style="font-weight: bold;">{{ outlineColumns[2] }}</span>
        </div>
        <div class="col-md-2">
          <span style="font-weight: bold;">{{ outlineColumns[3] }}</span>
        </div>
        <div class="col-md-2">
          <span style="font-weight: bold;">{{ outlineColumns[4] }}</span>
        </div>
      </div>
      <div class="row" v-for="outlineItem in outlines" v-bind:key="outlineItem.id">
        <div class="col-md-2">
          <fg-input type="number" :min="1"
                    @change="reorderMainOutline(outlineItem.id, outlineItem.order)"
                    v-model="outlineItem.order"></fg-input>
        </div>
        <div class="col-md-4">
          <fg-input type="text"
                    @change="updateOutlineDescription(outlineItem.id, outlineItem.description)"
                    v-model="outlineItem.description"></fg-input>
        </div>
        <div class="col-sm-2">
          <button v-if="outlineItem.locked" class="btn btn-primary"
                  @click="registerOutline(outlineItem.id, outlineItem.description)" disabled>
            Register
          </button>
          <button v-else class="btn btn-primary"
                  @click="registerOutline(outlineItem.id, outlineItem.description)">Register
          </button>
        </div>
        <div class="col-md-2">
          <button class="btn btn-primary" @click="openOutline(outlineItem.id)">View/Edit</button>
        </div>
        <div class="col-md-2">
          <el-dialog
            center
            title="Confirm Delete?"
            :visible.sync="modals.delete">
            <div class="text-center">
              <span>Are you sure you wish to delete the outline: {{ deleteOutlineName }}?</span>
              <br>
              <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('delete')">Cancel</button>&nbsp;
<button class="btn btn-success"
        @click="deleteOutlineDo">Confirm</button>
</span>
            </div>
          </el-dialog>
          <button class="btn btn-primary" @click="copyOutline(outlineItem.id)">Copy</button>
          <button class="btn btn-danger"
                  @click="deleteOutline(outlineItem.id, outlineItem.description)">-
          </button>
        </div>
      </div>
      <div class="stats" slot="footer">
        <button @click="createNewOutline('outline')"
                class="btn btn-primary">Create New Outline
        </button>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
// import OutlineNew from "./OutlineNew";
import OutlinesAPIService from "src/servicehandlers/OutlinesAPIService";

const outlineAPIService = new OutlinesAPIService();

export default {
  components: {
    [Dialog.name]: Dialog,
    // OutlineNew,
  },
  name: "UserOutlineManagement",
  data() {
    return {
      outlineColumns: ['Order', 'Description', 'Event', 'Edit', 'Action'],
      outlines: [],
      modals: {
        okmodal: false,
        error: false,
      },
    }
  },
  mounted() {
    this.getOutlines();
  },
  methods: {
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    openOutline(outline_id) {
      localStorage['params'] = JSON.stringify({
         outline_id: outline_id,
      });
      this.$router.push({
        name: 'User Outline'
      });
    },
    getOutlines() {
      this.spinning = true;
      const userId = this.$store.getters.userId;
      return outlineAPIService.getOutlinesSimple(userId, this.$router)
      .then((resp) => {
        this.spinning = false;
        this.outlines = resp;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting outlines for the user ' + error;
        this.openModal('error');
      });
    },
  },
}
</script>

<style scoped>

</style>
