<template>
  <div>
    {{ outline }}<br>
    User Class: {{ userClass }}<br>
    Weight: {{ outline.user.weight }}<br>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import OutlinesAPIService from "src/servicehandlers/OutlinesAPIService";
import { Dialog, } from 'element-ui';
import { ModelSelect } from 'vue-search-select';

const outlineAPIService = new OutlinesAPIService();

export default {
  components: {
    [Dialog.name]: Dialog,
    // ModelSelect,
  },
  name: "OutlineNew",
  data() {
    return {
      userClass: null,
      outline: {},
      error_txt: null,
      outline_id: null,
      modals: {
        success: false,
        error: false,
      },
    }
  },
  mounted() {
    this.getCurrentOutline(); // get the current user outline
  },
  methods: {
    getCurrentOutline() {
      try {
        const params = JSON.parse(localStorage['params'])
        this.outline_id = params.outline_id;

        // get the single outline
        return outlineAPIService.getOutlineByID(this.outline_id, this.$route)
        .then((resp) => {
          this.outline = resp;
          this.setUserClass(); // set the weight class
        })
        .catch((error) => {
          this.error_txt = 'Error getting outline: ' + error;
          this.openModal('error');
        })
      } catch (error) {
        this.error_txt = 'Error getting outline: ' + error;
        this.openModal('error');
      }
    },
    setUserClass() {
      if (!this.outline.weight) {
        this.outline.weight = this.outline.user.weight;
      }
      if (this.outline.weight >= 35 && this.outline.weight <= 49.9) {
        this.userClass = 1;
      } else if (this.outline.weight >= 50 && this.outline.weight <= 70.5) {
        this.userClass = 2;
      } else if (this.outline.weight >= 70.6 && this.outline.weight <= 96.6) {
        this.userClass = 3;
      } else if (this.outline.weight >= 96.7 && this.outline.weight <= 127.5) {
        this.userClass = 4;
      } else if (this.outline.weight >= 127.6 && this.outline.weight <= 164.9) {
        this.userClass = 5;
      } else if (this.outline.weight >= 165) {
        this.userClass = 6;
      } else {
        this.userClass = 6;
      }
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
  }
}
</script>

<style scoped>

</style>
