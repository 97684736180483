<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">{{ event.name }}</h4>
                <p class="card-category">
                  Manage {{ event.description }} event
                </p>
                <div class="card-category">
                  <el-button type="primary" @click="addPerson" icon="">Add Person</el-button>
                  <el-button v-if="event.event_type_id === 2 || event.event_type_id === 3" type="primary" @click="openModal('groups')" icon="">Manage Groups</el-button>
                  <el-button type="primary" @click="openModal('print')" icon="">Print</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterOrders"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterOrders" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table v-if="event.event_type_id === 2 || event.event_type_id === 3" stripe :data="filteredOrders" style="width: 100%" height="65vh" @sort-change="handleSortChange">
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Tester" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input v-model="scope.row.user.first_name" style="width: 100%" @change="saveUser(scope.row)" size="small"></el-input>
            <el-input v-model="scope.row.user.last_name" style="width: 100%" @change="saveUser(scope.row)" size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Program" :min-width="150" align="center">
          <template v-slot="scope">
            {{scope.row.program.name}}
          </template>
        </el-table-column>
        <el-table-column label="Current Rank" :min-width="150" align="center">
          <template v-slot="scope">
            {{scope.row.rank.name}}
          </template>
        </el-table-column>
        <el-table-column label="Next Rank" :min-width="300" align="center">
          <template v-slot="scope">
            <el-button v-if="scope.row.nextRank" type="primary" @click="promoteUser(scope.row.user.id, scope.row.nextRank.id)" icon="">Promote to {{scope.row.nextRank.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Weight" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input-number v-model="scope.row.user.weight"  @change="saveUser(scope.row)" size="small"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="Meets Criteria" :min-width="120" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.outline && scope.row.outline.meets_requirements"
                 class="met text-center p-2"><span>Yes</span>
            </div>
            <div v-else class="unmet text-center p-2"><span>No</span></div>
          </template>
        </el-table-column>
        <el-table-column label="Outline" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.outline && scope.row.outline.description">{{
                scope.row.user.first_name + ' ' + scope.row.user.last_name + ' - ' + event.name
              }}
              <outline v-if="scope.row.outline && scope.row.outline.id" :outline_id="scope.row.outline.id" @refresh="getAllData"></outline>
            </div>
            <div v-else>
              <el-button type="primary" @click="preRegister(scope.row.user.id, scope.row.id)" icon="">Register</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Assigned Test" :min-width="150" align="center">
          <template v-slot="scope">
            <el-select filterable v-model="scope.row.testing_type_id" placeholder="Assigned Test" style="width: 100%"
                       @change="updateDBOrder(scope.row)">
              <el-option v-for="item in testing_types"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column :min-width="200" align="center">
          <template v-slot="scope">
            <el-button v-if="!scope.row.outline || !scope.row.outline.locked" type="warning" @click="lockOutline(scope.row.outline.id)" icon="el-icon-lock"></el-button>
            <el-button v-else type="warning" @click="unlockOutline(scope.row.outline.id)" icon="el-icon-key"></el-button>
            <el-button type="danger" @click="deleteCode(scope.row.id, scope.row.user.first_name + ' ' + scope.row.user.last_name, scope.row.code)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else stripe :data="filteredOrders" style="width: 100%" height="60vh" @sort-change="handleSortChange">
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Competitor" :min-width="150" align="center">
          <template v-slot="scope">
            {{ scope.row.user.first_name + ' ' + scope.row.user.last_name }}
          </template>
        </el-table-column>
        <el-table-column label="Number" :min-width="100" align="center">
          <template v-slot="scope">
            {{ scope.row.code }}
          </template>
        </el-table-column>
        <el-table-column label="Registered" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.outline">Registered</div>
            <div v-else>Not Registered</div>
          </template>
        </el-table-column>
        <el-table-column label="Meets Criteria" :min-width="120" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.outline && scope.row.outline.meets_requirements"
                 class="met text-center p-2"><span>Yes</span>
            </div>
            <div v-else class="unmet text-center p-2"><span>No</span></div>
          </template>
        </el-table-column>
        <el-table-column label="Outline" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.outline && scope.row.outline.description">{{
                scope.row.user.first_name + ' ' + scope.row.user.last_name + ' - ' + event.name
              }}
              <outline v-if="scope.row.outline && scope.row.outline.id" :outline_id="scope.row.outline.id" @refresh="getAllData"></outline>
            </div>
            <div v-else>
              <el-button type="primary" @click="preRegister(scope.row.user.id, scope.row.id)" icon="">Register</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column :min-width="200" align="center">
          <template v-slot="scope">
            <el-button v-if="!scope.row.outline || !scope.row.outline.locked" type="warning" @click="lockOutline(scope.row.outline.id)" icon="el-icon-lock"></el-button>
            <el-button v-else type="warning" @click="unlockOutline(scope.row.outline.id)" icon="el-icon-key"></el-button>
            <el-button type="danger" @click="deleteCode(scope.row.id, scope.row.user.first_name + ' ' + scope.row.user.last_name, scope.row.code)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
     center
     :title="'Add Person to Event:' + event.description"
     :visible.sync="modals.person">
     <div class="text-center">
       <div class="row">
         <div class="col-sm-4 font-weight-bold">User</div>
         <div class="col-sm-4 font-weight-bold">Code</div>
         <div class="col-sm-4 font-weight-bold">Outline (optional)</div>
       </div>
       <div class="row">
         <div class="col-sm-4 font-weight-bold">
           <el-select filterable v-model="selected_user" placeholder="Select User" style="width: 100%"
                      @input="getOutlinesForUser">
             <el-option v-for="item in usersNotIn()"
                        :key="item.value" :value="item.value" :label="item.text">
             </el-option>
           </el-select>
         </div>
         <div class="col-sm-4 font-weight-bold"><fg-input v-model="code" placeholder="Enter the code"></fg-input></div>
         <div class="col-sm-4 font-weight-bold">
           <el-select filterable v-model="selected_user_outline" placeholder="Select Outline (optional)" style="width: 100%">
             <el-option v-for="item in possible_user_outlines"
                        :key="item.id" :value="item.id" :label="item.description">
             </el-option>
           </el-select>
         </div>
       </div>
       <span slot="footer" class="dialog-footer">
         <el-button type="success" @click="createOrder('person')" icon="">OK</el-button>
         <el-button type="danger" @click="closeModal('person')" icon="">Cancel</el-button>
       </span>
     </div>
   </el-dialog>
    <el-dialog
     center
     title="Error"
     :visible.sync="modals.error">
     <div class="text-center">
       <span>Error: {{ error_txt }}</span>
       <br>
       <span slot="footer" class="dialog-footer">
         <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
       </span>
     </div>
   </el-dialog>
    <el-dialog
     center
     title="Success"
     :visible.sync="modals.success">
     <div class="text-center">
       <span>Success: {{ success_txt }}</span>
       <br>
       <span slot="footer" class="dialog-footer">
         <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
       </span>
     </div>
   </el-dialog>
    <el-dialog
     center
     title="Success"
     :visible.sync="modals.register">
     <div class="text-center">
       <el-select filterable v-model="selected_user_outline" placeholder="Select Outline" style="width: 100%">
         <el-option v-for="item in possible_user_outlines"
                    :key="item.id" :value="item.id" :label="item.description">
         </el-option>
       </el-select>
       <br>
       <span slot="footer" class="dialog-footer">
         <el-button type="success" @click="savePerson()" icon="">OK</el-button>
         <el-button type="danger" @click="closeModal('register')" icon="">Cancel</el-button>
       </span>
     </div>
   </el-dialog>
    <el-dialog
     center
     title="Edit Testing Types"
     :visible.sync="modals.types">
     <div class="text-center">
       <el-button type="primary" @click="addTestingType" icon="">Add Type</el-button>
       <el-table stripe :data="testing_types" style="width: 100%">
         <el-table-column label="Name" :min-width="150" align="center">
           <template v-slot="scope">
             <el-input v-model="scope.row.name" @change="updateTestingType(scope.row)"></el-input>
           </template>
         </el-table-column>
       </el-table>
       <br>
       <span slot="footer" class="dialog-footer">
         <el-button type="danger" @click="closeModal('types')" icon="">Close</el-button>
       </span>
     </div>
   </el-dialog>
    <el-dialog
       center
       width="90%"
       title="Groups Management"
       :visible.sync="modals.groups">
       <div class="text-center">
         <el-tabs type="border-card" @tab-click="handleTabClick">
           <el-tab-pane v-for="type of testing_types" v-bind:key="type.id" :label="type.name">
             <div class="row" style="width: 100%">
               <div class="col-20" v-for="table of groupsTables" v-bind:key="table.id">
                 <el-table stripe :data="table.data" style="width: 100%">
                   <el-table-column :label="table.name" :min-width="150" align="center">
                     <template v-slot="scope">
                       <div class="row">
                         <div class="col-sm-2">
                           <el-button icon="el-icon-arrow-left" size="small" @click="moveLeft(scope.row, table.id)" />
                         </div>
                         <div class="col-sm-2">
                           <el-button icon="el-icon-arrow-right" size="small" @click="moveRight(scope.row, table.id)" />
                         </div>
                         <div class="col-sm-8 align-content-center">
                           <b>{{scope.row.name}}</b>
                           <br>
                           {{scope.row.rank}}
                         </div>
                       </div>
                     </template>
                   </el-table-column>
                 </el-table>
               </div>
             </div>
           </el-tab-pane>
         </el-tabs>
         <br>
         <span slot="footer" class="dialog-footer">
           <el-button type="primary" @click="openModal('types')" icon="">Edit Testing Types</el-button>
           <el-button type="success" @click="saveGroups" icon="">Save</el-button>
           <el-button type="danger" @click="closeModal('groups')" icon="">Close</el-button>
         </span>
       </div>
     </el-dialog>
    <el-dialog
       center
       title="Print"
       :visible.sync="modals.print"
       :modalAppendToBody="false">
       <div class="text-center">
         <div class="row" v-if="event.event_type_id === 2">
           <div class="col-sm-6 text-center flex-column">
             <h5 style="margin-top: 0">Event Documents</h5>
             <master-print-dialog :type="'event-test-sheets'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'event-roll-sheet'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'event-call-sheet'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'event-curriculum'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
           </div>
           <div class="col-sm-6 text-center flex-column">
             <h5 style="margin-top: 0">Event Certificates</h5>
             <master-print-dialog :type="'foundation-certs'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'color-belt-certs'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'prelim-certs'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
           </div>
         </div>
         <div class="row" v-if="event.event_type_id === 3">
           <div class="col-sm-6 text-center flex-column">
             <h5 style="margin-top: 0">Event Documents</h5>
             <master-print-dialog :type="'bbt-test-sheets'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'bbt-roll-sheet'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'bbt-call-sheet'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'bbt-curriculum'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
           </div>
           <div class="col-sm-6 text-center flex-column">
             <h5 style="margin-top: 0">Event Certificates</h5>
             <master-print-dialog :type="'black-belt-certs'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
           </div>
         </div>
         <br>
         <div class="row">
           <div class="col-sm-6">
             <master-print-dialog :type="'event-scores'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
           </div>
           <div class="col-sm-6">
             <master-print-dialog :type="'event-outlines'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
             <master-print-dialog :type="'event-board-count'" :given-data="order_numbers" :print-name="event.name"></master-print-dialog>
           </div>
         </div>
         <br>
         <span slot="footer" class="dialog-footer">
           <el-button type="danger" @click="closeModal('print')" icon="">Cancel</el-button>
         </span>
       </div>
     </el-dialog>
   </div>
 </template>
 <script>
 import Swal from 'sweetalert2';
 import { Dialog, Select, Option, Input, Button, Checkbox, Table, TableColumn, InputNumber, DatePicker, Tabs, TabPane } from 'element-ui';
 import EventsAPIService from "../../../../servicehandlers/EventsAPIService";
 import OutlineAPIService from "../../../../servicehandlers/OutlinesAPIService";
 import UsersAPIService from "../../../../servicehandlers/UsersAPIService";
 import OrderNumbersAPIService from "../../../../servicehandlers/OrderNumbersAPIService";
 import RanksAPIService from "../../../../servicehandlers/RanksAPIService";
 import ProgramsAPIService from "../../../../servicehandlers/ProgramsAPIService";
 import TestingTypesAPIService from "../../../../servicehandlers/TestingTypesAPIService";
 import RankTypesAPIService from "../../../../servicehandlers/RankTypesAPIService";

 const eventsAPIService = new EventsAPIService();
 const outlineAPIService = new OutlineAPIService();
 const orderNumbersAPIService = new OrderNumbersAPIService();
 const ranksAPIService = new RanksAPIService();
 const usersAPIService = new UsersAPIService();
 const programAPIService = new ProgramsAPIService()
 const testingTypesAPIService = new TestingTypesAPIService()
 const rankTypesAPIService = new RankTypesAPIService()
 import Outline from "../Outline/Outline";
 import MasterPrintDialog from "../MasterPrintDialog.vue";

 export default {
   name: "EventManager",
   components: {
     [Dialog.name]: Dialog,
     [Select.name]: Select,
     [Option.name]: Option,
     [Input.name]: Input,
     [Button.name]: Button,
     [Checkbox.name]: Checkbox,
     [Table.name]: Table,
     [TableColumn.name]: TableColumn,
     [InputNumber.name]: InputNumber,
     [DatePicker.name]: DatePicker,
     [Tabs.name]: Tabs,
     [TabPane.name]: TabPane,
     Outline,
     MasterPrintDialog,
   },
   data() {
     return {
       event: {},
       event_id: null,
       drag: false,
       success_txt: null,
       error_txt: null,
       spinning: false,
       selectAll: false,
       fromId: null,
       toId: null,
       selected_user_outline: null,
       selected_user: null,
       selected_order_id: null,
       outlines: [],
       selected_outlines: [],
       possible_user_outlines: [],
       order_numbers: [],
       filteredOrders: [],
       code: null,
       users: [],
       filteredUsers: [],
       files: {},
       event_name: null,
       outlineSelected: [],
       events: [],
       allGroupsData: [],
       groupsTables: [
         {
           id: 1,
           name: 'Group 1',
           data: [],
         },
         {
           id: 2,
           name: 'Group 2',
           data: [],
         },
         {
           id: 3,
           name: 'Group 3',
           data: [],
         },
         {
           id: 4,
           name: 'Group 4',
           data: [],
         },
         {
           id: 5,
           name: 'Group 5',
           data: [],
         },
       ],
       selected_groups: [],
       testing_types: [],
       selected_tab: null,
       search: '',

       modals: {
         error: false,
         success: false,
         person: false,
         print: false,
         groups: false,
         types: false,
         register: false,
       },

       // outline items
       userClass: null,
       user: null,
       userRank: null,

       // all dropdown items right here
       dropdown_ranks: [],
       rank_types: [],
       dropdown_modifiers: [],
       board_techniques: [],
       board_technique_select_options: [],

       // storage objects to hold values in prior to calculating scores
       support_option_objects: {},
       rank_requirements: {},
       technique_objects: {},
       direction_objects: {},
       modifier_objects: {},
       board_size_objects: {},
       board_objects: {},
       penalties_objects: {},
       scores_objects: {},

       categories: [
         {
           id: 'breaking_techniques',
           name: 'Breaking(Green Tape)',
           items: [],
         },
         {
           id: 'poomsae',
           name: 'Poomsae(Black Tape)',
           items: [],
         },
         {
           id: 'kicks',
           name: 'Kicks(Blue Tape)',
           items: [],
         },
         {
           id: 'self_defense',
           name: 'Self Defence(Brown Tape)',
           items: [],
         },
         {
           id: 'kick_combos',
           name: 'Kick Combos(Red Tape)',
           items: [],
         },
         {
           id: 'techniques',
           name: 'Techniques',
           items: [],
         },
         {
           id: 'terminology',
           name: 'Yellow Tape(ADD PDF FOR MEDIA)',
           items: [],
         },
         {
           id: 'documentation',
           name: 'White (ADD PDF FOR MEDIA)',
           items: [],
         },
       ],
       theme: null,
       programs: [],
     }
   },
   async created() {
     this.event_id = this.$route.query.event_id;
     this.getAllData()
   },
   computed: {
     isChecked(){
       return () => {
         return this.selectAll
       }
     }
   },
   methods: {
     async getAllData(){
       this.spinning = true;
       await this.getEvent();
       await this.getTestingTypes()
       await this.getRankTypes()
       await this.getRanks();
       await this.getPrograms()
       await this.getGroupsForEvent()
       await this.getEventOutlines();
       await this.getUsers();
       this.spinning = false;
     },
     onEnd() {
       // go through all the order numbers, align with the indexes
       const promises = [];
       for (let i = 0; i < this.order_numbers.length; i += 1) {
         const id = this.order_numbers[i].id;
         this.order_numbers[i].order = i + 1;
         promises.push(orderNumbersAPIService.updateOrderNumber(id, {order: i + 1}, this.$router));
       }
       return Promise.all(promises)
       // now start updating both the old index and the new to the new values
       .then(() => {
       })
       .catch((error) => {
         Swal.fire({
           title: 'Error',
           text: 'Error updating order! ' + error,
           timer: 1000,
           confirmButtonClass: 'btn btn-danger btn-fill',
           showConfirmButton: false
         });
       });
     },
     deleteCode(id, name, code) {
       this.spinning = true;
       if (!id) {
         this.error_txt = 'No user was selected';
         this.openModal('error');
         this.spinning = false;
         return;
       }
       if (!name) {
         this.error_txt = 'No name was selected';
         this.openModal('error');
         this.spinning = false;
         return;
       }
       if (!code) {
         this.error_txt = 'No code was selected';
         this.openModal('error');
         this.spinning = false;
         return;
       }

       Swal.fire({
         title: 'Are you sure?',
         text: name + ' ' + 'will be deleted for code ' + code,
         type: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Delete',
         cancelButtonText: 'Cancel',
         confirmButtonClass: 'btn btn-success btn-fill',
         cancelButtonClass: 'btn btn-danger btn-fill',
         buttonsStyling: false
       }).then(function (dismiss) {
         if (dismiss.isDismissed) {
           // this.spinning = false;
           Swal.fire({
             title: 'Cancelled',
             text: 'Cancelled',
             timer: 1000,
             confirmButtonClass: 'btn btn-success btn-fill',
             buttonsStyling: false
           });
           return false;
         } else {
           // do the deletions
           return orderNumbersAPIService.deleteOrderNumber(id, null);
         }
       })
       .then(async (trueorfalse) => {
         await this.getEventOutlines();
         if (trueorfalse) {
           Swal.fire({
             title: 'Success',
             text: 'Deletion Successful',
             timer: 1000,
             confirmButtonClass: 'btn btn-success btn-fill',
             buttonsStyling: false
           });
           this.updateOrder()
         }
         this.spinning = false;
       })
       .catch((error) => {
         Swal.fire({
           title: 'Error',
           text: 'Error deleting code! ' + error,
           timer: 1000,
           confirmButtonClass: 'btn btn-danger btn-fill',
           showConfirmButton: true
         });
         this.spinning = false;
       });
     },
     createOrder(name) {
       this.spinning = true;
       // save the code into the DB for the user
       if (!this.selected_user) {
         this.error_txt = 'No user was selected';
         this.openModal('error');
         this.spinning = false;
         return;
       }
       if (!this.code) {
         this.error_txt = 'No code was entered';
         this.openModal('error');
         this.spinning = false;
         return;
       }
       const order_number = {
         code: this.code,
         event_id: this.event_id,
         user_id: this.selected_user,
       };
       if (this.selected_user_outline) {
         order_number.outline_id = this.selected_user_outline;
       }

       return orderNumbersAPIService.addOrderNumber(order_number, this.$router)
       .then(async () => {
         this.selected_user_outline = null;
         this.code = null;
         this.possible_user_outlines = [];
         this.selected_user = null;
         await this.getEventOutlines();
         this.closeModal(name);
         this.spinning = false;
         this.updateOrder()
       })
       .catch((error) => {
         this.error_txt = 'Error creating order for user: ' + error;
         this.openModal('error');
         this.spinning = false;
       });
     },
     getOutlinesForUser() {
       this.possible_user_outlines = [];
       if (!this.selected_user) {
         this.error_txt = 'No user was selected';
         this.openModal('error');
         return;
       }
       this.spinning = true;
       return outlineAPIService.getOutlinesSimple(this.selected_user, this.$router)
       .then((userOutlines) => {
         this.possible_user_outlines = userOutlines
         this.spinning = false;
       })
       .catch((error) => {
         this.spinning = false;
         this.error_txt = 'Error getting users: ' + error;
         this.openModal('error');
       });
     },
     preRegister(userId, orderId){
       this.selected_user = userId
       this.selected_order_id = orderId
       this.getOutlinesForUser()
       this.openModal('register')
     },
     savePerson(){
       const order_number = {
         outline_id: this.selected_user_outline,
       };

       return orderNumbersAPIService.updateOrderNumber(this.selected_order_id, order_number, this.$router)
         .then(async () => {
           this.selected_user_outline = null;
           this.possible_user_outlines = [];
           this.selected_user = null;
           await this.getEventOutlines();
           this.closeModal('register');
           this.spinning = false;
           this.success_txt = 'Successfully updated.'
           this.openModal('success')
         })
         .catch((error) => {
           this.error_txt = 'Error updating order for user: ' + error;
           this.openModal('error');
           this.spinning = false;
         });
     },
     addPerson() {
       this.openModal('person');
     },
     async getUsers() {
       this.users = [];
       return usersAPIService.getUserList(this.$router)
       .then((allUsers) => {
         for (const user of allUsers) {
           this.users.push({
             text: user.first_name + ' ' + user.last_name,
             value: user.id,
           });
         }
       })
       .catch((error) => {
         this.error_txt = 'Error getting users: ' + error;
         this.openModal('error');
       });
     },
     lockOutline(outline_id) {
       this.spinning = true;
       const outline = {
         locked: true,
       };
       return outlineAPIService.updateSingleOutline(outline_id, outline, this.$router)
       .then(() => {
         this.spinning = false;
         this.success_txt = 'Lock Successful';
         this.openModal('success');
         this.getEventOutlines();
       })
       .catch((error) => {
         this.spinning = false;
         this.error_txt = 'Lock failed: ' + error;
         this.openModal('error');
       });
     },
     setUserClass(weight, user) {
       let userClass = 6;
       if (!user.weight) {
         user.weight = 99999;
       }
       if (!weight) {
         weight = parseFloat(user.weight);
       }
       if (weight >= 35 && weight <= 49.9) {
         userClass = 1;
       } else if (weight >= 50 && weight <= 70.5) {
         userClass = 2;
       } else if (weight >= 70.6 && weight <= 96.6) {
         userClass = 3;
       } else if (weight >= 96.7 && weight <= 127.5) {
         userClass = 4;
       } else if (weight >= 127.6 && weight <= 164.9) {
         userClass = 5;
       } else if (weight >= 165) {
         userClass = 6;
       } else {
         userClass = 6;
       }
       return userClass;
     },
     updateDBOrder(order){
       return orderNumbersAPIService.updateOrderNumber(order.id, order, this.$router)
         .then(() => {
           this.calculateGroups()
         })
         .catch((e) => {
           this.error_txt = 'Error updating order number: ' + e
           this.openModal('error')
         })
     },

     getPrograms(){
       return programAPIService.getPrograms(this.$router)
         .then((response) => {
           this.programs = response
         }).catch((error) => {
           this.error_txt = 'Error getting programs: ' + error;
           this.openModal('error');
         });
     },
     unlockOutline(outline_id) {
       const outline = {
         locked: false,
       };
       return outlineAPIService.updateSingleOutline(outline_id, outline, this.$router)
       .then(() => {
         this.spinning = false;
         this.success_txt = 'Unlock Successful';
         this.openModal('success');
         this.getEventOutlines();
       })
       .catch((error) => {
         this.spinning = false;
         this.error_txt = 'Unlock failed: ' + error;
         this.openModal('error');
       });
     },
     async getEvent() {
       return eventsAPIService.getEvent(this.event_id, this.$router)
       .then((event) => {
         this.event = event;
       })
       .catch((error) => {
         this.error_txt = "Error getting event: " + error.message;
         this.openModal('error');
       });
     },
     async getGroupsForEvent() {
       return eventsAPIService.getGroupsForEvent(this.event_id, this.$router)
       .then((response) => {
         this.allGroupsData = response
         for (let order of this.order_numbers) {
           for (let groupData of this.allGroupsData){
             if (groupData.order_id === order.id){
               order.group = groupData
             }
           }
         }
       })
       .catch((error) => {
         this.error_txt = "Error getting groups for event: " + error.message;
         this.openModal('error');
       });
     },
     saveGroups(){
       return eventsAPIService.saveGroupsForEvent(this.allGroupsData, this.event_id, this.$router)
         .then(() => {
           this.success_txt = 'Successfully saved Groups for event'
           this.openModal('success')
           this.closeModal('groups')
           this.getGroupsForEvent()
         })
         .catch((e) => {
           this.error_txt = 'Error while saving groups for event'
           this.openModal('error')
         })
     },

     getTestingTypes(){
       return testingTypesAPIService.getTestingTypes(this.$router)
         .then((response) => {
           this.testing_types = response
         })
         .catch((e) => {
           this.error_txt = 'Error getting testing types' + e
           this.openModal('error')
         })
     },
     addTestingType(){
       let temp = {
         name: '',
       }
       return testingTypesAPIService.addTestingType(temp, this.$router)
         .then(() => {
           this.getTestingTypes()
         })
         .catch((e) => {
           this.error_txt = "Error adding Testing Type: " + e
           this.openModal('error')
         })
     },
     updateTestingType(type){
       return testingTypesAPIService.updateTestingType(type.id, type, this.$router)
         .then(() => {
           this.getTestingTypes()
         })
         .catch((e) => {
           this.error_txt = "Error while updating testing type: " + e
           this.openModal('error')
         })
     },
     assignTestingType(){
       let f1ID
       let f2ID
       let basicID
       let interID
       let advaID
       let prelimID
       for (let type of this.testing_types){
         switch (type.name){
           case 'F1':
             f1ID = type.id
             break
           case 'F2':
             f2ID = type.id
             break
           case 'Basic':
             basicID = type.id
             break
           case 'Intermediate':
             interID = type.id
             break
           case 'Advanced':
             advaID = type.id
             break
           case 'Prelim':
             prelimID = type.id
             break
         }
       }

       let rankBasicID
       let rankInterID
       let rankAdvaID
       let rankBlackID
       for (let type of this.rank_types){
         switch (type.name){
           case 'Beginning':
             rankBasicID = type.id
             break
           case 'Intermediate':
             rankInterID = type.id
             break
           case 'Advanced':
             rankAdvaID = type.id
             break
           case 'Black Belt':
             rankBlackID = type.id
             break
         }
       }

       for (let order of this.order_numbers){
         if (!order.testing_type_id){
           if (order.rank){
             if (order.program.name === 'Foundations 1') order.testing_type_id = f1ID
             else if (order.program.name === 'Foundations 2') order.testing_type_id = f2ID
             else if (order.rank.rank_type_id === rankBasicID) order.testing_type_id = basicID
             else if (order.rank.rank_type_id === rankInterID) order.testing_type_id = interID
             else if (order.rank.rank_type_id === rankAdvaID) order.testing_type_id = advaID
             else if (order.rank.rank_type_id === rankBlackID) order.testing_type_id = prelimID

             this.updateDBOrder(order)

           }
         }
       }
     },

     clickSelect() {
       this.selectAll = !this.selectAll
       if (this.selectAll === false) {
         this.selected_outlines = [];
       } else {
         for (const order_number of this.order_numbers) {
           if (order_number) {
             this.selected_outlines.push(order_number.id);
           }
         }
       }
     },
     async getEventOutlines() {
       try {
         this.order_numbers = await orderNumbersAPIService.getAllOrdersByEvent(this.event_id, this.$router);
         for (let order of this.order_numbers) {

           let nextRank
           let userRank
           let nextRankBool = false
           for (let rank of this.dropdown_ranks) {
             if (nextRankBool) {
               nextRank = rank
               break
             }
             if (rank.id === order.user.rank_id) {
               userRank = rank
               if (rank !== this.dropdown_ranks[this.dropdown_ranks.length - 1]) {
                 nextRankBool = true
               } else {
                 nextRank = userRank
               }
             }
           }
           let userProgram
           if (userRank){
             for (let program of this.programs){
               if (program.id === userRank.program_id){
                 userProgram = program
               }
             }
           }
           order.rank = userRank
           order.nextRank = nextRank
           order.program = userProgram
           for (let groupData of this.allGroupsData){
             if (groupData.order_id === order.id){
               order.group = groupData
             }
           }
           if (!order.rank){
             this.error_txt = order.user.first_name + ' ' + order.user.last_name + " doesn't have a valid rank."
             this.openModal('error')
           }
         }
         if (this.event.event_type_id === 2 || this.event.event_type_id === 3){
           this.assignTestingType()
           let originalLength = this.allGroupsData.length
           for (let type of this.testing_types){
             this.selected_tab = type.name
             this.calculateGroups()
           }
           if (originalLength !== this.allGroupsData.length){
             this.saveGroups()
           }
           this.selected_tab = this.testing_types[0].name
           this.calculateGroups()
         }

         this.filteredOrders = this.order_numbers;
         this.updateOrder();
       } catch (error) {
         this.error_txt = "Error getting event outlines: " + error.message;
         this.openModal('error');
       }
       this.spinning = false
     },
     openModal(name) {
       this.modals[name] = true
     },
     closeModal(name) {
       this.modals[name] = false
     },
     // get all the ranks used in dropdowns
     getRanks() {
       return ranksAPIService.getRanks(this.$router)
       .then((ranks) => {
         this.dropdown_ranks = ranks;
         for (const rank of this.dropdown_ranks) {
           rank.text = rank.name;
           rank.value = rank.id;
         }
       })
       .catch((error) => {
         this.error_txt = 'Error getting ranks for dropdown: ' + error;
         this.openModal('error');
       });
     },
     getRankTypes(){
       return rankTypesAPIService.getRankTypes(this.$router)
         .then((response) => {
           this.rank_types = response
         })
         .catch((e) => {
           this.error_txt = "Error getting rank types: " + e
           this.openModal('error')
         })
     },

     moveUp(index) {
       if (index > 0) {
         const temp = this.order_numbers[index];
         this.order_numbers.splice(index, 1);
         this.order_numbers.splice(index - 1, 0, temp);
         this.updateOrder(temp)
       }
       this.onEnd();
     },
     moveDown(index) {
       if (index < this.order_numbers.length - 1) {
         const temp = this.order_numbers[index];
         this.order_numbers.splice(index, 1);
         this.order_numbers.splice(index + 1, 0, temp);
         this.updateOrder(temp)
       }
       this.onEnd();
     },
     updateOrder(){
       for (let i = 0; i < this.order_numbers.length; i++){
         this.order_numbers[i].order = i+1
       }
       this.filteredOrders =this.order_numbers
     },
     handleSortChange({ column, prop, order}) {
       if (order === "ascending") {
         this.filteredOrders.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
       } else if (order === "descending") {
         this.filteredOrders.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
       }
     },
     filterOrders(){
       try {
         this.filteredOrders = this.order_numbers.filter(
           (data) =>
             !this.search ||
             (data.user.first_name.toLowerCase() + data.user.last_name.toLowerCase()).includes(this.search.toLowerCase())
         )
       } catch (e) {
         this.error_txt = 'One or more names missing'
         this.openModal('error')
       }
     },
     checkSelected(id){
       if (this.selected_outlines.includes(id)) return true;
       return false
     },
     selectID(id){
       if (this.selected_outlines.includes((id))) {
         let index = this.selected_outlines.indexOf(id)
         this.selected_outlines.splice(index, 1)
       } else {
         this.selected_outlines.push(id)
       }
     },
     updateOutline(outline){
       return outlineAPIService.updateSingleOutline(outline.id, outline, this.$router)
         .catch((e) => {
           this.error_txt = 'Error updating outline: ' + e
           this.openModal('error')
         })
     },
     async saveUser(order){
       if (order.outline){
         order.outline.weight = order.user.weight
         await this.updateOutline(order.outline)
       }
       this.spinning = true
       return usersAPIService.updateUser(order.user_id, order.user, this.$router)
         .then(async () => {
           //this.success_txt = 'Successfully updated user'
           //this.openModal('success')
           this.getEventOutlines()
           this.spinning = false
         })
         .catch((error) => {
           this.error_txt = 'Error creating order for user: ' + error;
           this.openModal('error');
           this.spinning = false;
         });

     },
     handleTabClick(tab) {
       this.selected_tab = tab.label
       this.calculateGroups()
     },
     calculateGroups(){
       let selectedType
       this.groupsTables = [
         {
           id: 1,
           name: 'Group 1',
           data: [],
         },
         {
           id: 2,
           name: 'Group 2',
           data: [],
         },
         {
           id: 3,
           name: 'Group 3',
           data: [],
         },
         {
           id: 4,
           name: 'Group 4',
           data: [],
         },
         {
           id: 5,
           name: 'Group 5',
           data: [],
         },
       ]
       for (let type of this.testing_types){
         if (type.name === this.selected_tab){
           selectedType = type
           break
         }
       }
       let unassignedOrders = []
       for (let order of this.order_numbers){
         if (order.testing_type_id === selectedType.id){
           let temp = {
             order_id: order.id,
             name: order.user.first_name + ' ' + order.user.last_name,
             rank: order.rank.name,
           }
           if (order.group){
             this.groupsTables[order.group.group_id - 1].data.push(temp)
           } else {
             unassignedOrders.push(temp)
           }
         }
       }
       if (unassignedOrders.length > 0){
         for (let temp of unassignedOrders){
           let lowestGroups = []
           let lowestLength
           for (let group of this.groupsTables){
             if (lowestGroups.length === 0){
               lowestGroups.push(group.id)
               lowestLength = group.data.length
               continue
             }
             if (group.data.length === lowestLength){
               lowestGroups.push(group.id)
             } else if (group.data.length < lowestLength){
               lowestGroups = [group.id]
             }
           }
           let randIndex = Math.floor(Math.random() * lowestGroups.length);

           let groupIndex = lowestGroups[randIndex] - 1

           this.groupsTables[groupIndex].data.push(temp)

           let newData = {
             order_id: temp.order_id,
             group_id: groupIndex + 1,
           }
           this.allGroupsData.push(newData)
         }
       }
     },
     moveLeft(data, group_id){
       let index = this.groupsTables[group_id - 1].data.indexOf(data)
       this.groupsTables[group_id - 1].data.splice(index, 1)
       let newIndex = group_id - 2
       if (newIndex < 0) newIndex = 4
       this.groupsTables[newIndex].data.push(data)
       this.updateGroups(data.order_id, newIndex + 1)
     },
     moveRight(data, group_id){
       let index = this.groupsTables[group_id - 1].data.indexOf(data)
       this.groupsTables[group_id - 1].data.splice(index, 1)
       let newIndex = group_id % 5
       this.groupsTables[newIndex].data.push(data)
       this.updateGroups(data.order_id, newIndex + 1)
     },
     updateGroups(order_id, newGroup){
       for (let order of this.allGroupsData){
         if (order.order_id === order_id){
           order.group_id = newGroup
         }
       }
     },
     promoteUser(id, rankID){
       this.spinning = true
       return usersAPIService.changeUserRankTo(id, rankID, this.$router)
         .then(() => {
           this.getEventOutlines()
         })
         .catch((e) => {
           this.error_txt = 'Error promoting user'
           this.openModal('error')
         })
     },
     usersNotIn(){
       let temp = []
       for (let user of this.users){
         let found = false
         for (let order of this.order_numbers){
           if (user.value === order.user_id){
             found = true
             break
           }
         }
         if (!found){
           temp.push(user)
         }
       }
       return temp
     },
   },
 }
 </script>

 <style scoped>
 .met{
   border: solid green 1px;
   border-radius: 3px;
   background-color: #abff8f;
   color: black;
   padding: 5px;
 }

 .unmet{
   border: solid red 1px;
   border-radius: 3px;
   background-color: #ff8f8f;
   color: black;
   padding: 5px;
 }

 .col-20{
   -ms-flex:0 0 20%;
   flex:0 0 20%;
   max-width: 20%;
 }
 </style>
