<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Kicks Table</h4>
                <p class="card-category">Create or Edit Kicks</p>
                <p class="card-category">
                  <el-button type="primary" @click="openModal('create')" icon="">Add New Kicks</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterKicks"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterKicks" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredKicks" style="width: 100%" height="65vh" @sort-change="handleSortChange">
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" sortable="custom" label="Name" :min-width="600" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-8">
                    <el-input placeholder="Name" v-model="scope.row.name" @change="updateKick(scope.row)"></el-input>
                  </div>
                  <div class="col-sm-4">
                    <el-input placeholder="Abbreviation" v-model="scope.row.abbreviation" @change="updateKick(scope.row)"></el-input>
                  </div>
                  <div class="col-sm-8">
                    <el-input placeholder="Translation" v-model="scope.row.korean_name" @change="updateKick(scope.row)"></el-input>
                  </div>
                  <div class="col-sm-4">
                    <el-input placeholder="Notes" v-model="scope.row.notes" @change="updateKick(scope.row)"></el-input>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <el-input placeholder="Description" v-model="scope.row.description" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }" @change="updateKick(scope.row)"></el-input>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="PDF" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.pdf">
              <el-button type="primary" @click="downloadPDF(scope.row.pdf, 'Kicks', scope.row.id)" icon="">{{ scope.row.pdf }}</el-button>
              <el-button type="danger" @click="removeFile(scope.row.id)" icon="el-icon-delete"></el-button>
            </div>
            <div v-else>
              <el-button type="primary" @click="preUpload('pdf', scope.row.id)" icon="">Upload PDF</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="URL" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input placeholder="Media URL" v-model="scope.row.media" @change="updateKick(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Selects" :min-width="150" align="center">
          <template v-slot="scope">
            <el-select filterable v-model="scope.row.kick_family_id" placeholder="Family" @change="updateKick(scope.row)">
              <el-option v-for="kick_family in kick_families" v-bind:key="kick_family.id"
                         :value="kick_family.id" :label="kick_family.name">
              </el-option>
            </el-select>
            <el-select filterable v-model="scope.row.kicking_foot_position_id" placeholder="Position" @change="updateKick(scope.row)">
              <el-option v-for="kick_pos in kick_positions" v-bind:key="kick_pos.id"
                         :value="kick_pos.id" :label="kick_pos.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Breaking/Jumping" :min-width="300" align="center">
          <template v-slot="scope">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <el-checkbox v-model="scope.row.is_breaking" @change="updateKick(scope.row)">B</el-checkbox>
              </div>
              <div class="col-sm-6">
                <el-checkbox v-model="scope.row.is_jumping" @change="updateKick(scope.row)">J</el-checkbox>
              </div>
              <div class="col-sm-12" v-if="scope.row.is_breaking">
                <div class="row">
                  <div class="col-sm-4">
                    <el-input type="number" min=0 v-model="scope.row.a_minus" @change="updateKick(scope.row)"></el-input>
                  </div>
                  <div class="col-sm-4">
                    <el-input type="number" min=0 v-model="scope.row.base_point_value" @change="updateKick(scope.row)"></el-input>
                  </div>
                  <div class="col-sm-4">
                    <el-input type="number" min=0 v-model="scope.row.board_multiplier" @change="updateKick(scope.row)"></el-input>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" v-else>
                <div class="row">
                  <div class="col-sm-4">
                    <el-input type="number" min=0 v-model="scope.row.a_minus" disabled></el-input>
                  </div>
                  <div class="col-sm-4">
                    <el-input type="number" min=0 v-model="scope.row.base_point_value" disabled></el-input>
                  </div>
                  <div class="col-sm-4">
                    <el-input type="number" min=0 v-model="scope.row.board_multiplier" disabled></el-input>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template v-slot="scope">
            <el-button type="danger" @click="deleteKick(scope.row.id)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Upload PDF"
      :visible.sync="modals.pdf">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <input type="file" ref="pdfInput" @change="uploadPDF"/>
            <el-button type="primary" @click="uploadFile" icon="">Upload</el-button>
            <p v-if="message">{{ message }}</p>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('pdf')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Create New Kick"
      :visible.sync="modals.create">
      <div>
        <div class="row align-items-center">
          <div class="col-sm-4">
            <label>Name:</label>
            <el-input placeholder="Name" v-model="name"></el-input>
          </div>
          <div class="col-sm-4">
            <label>Abbreviation:</label>
            <el-input placeholder="Abbreviation" v-model="abbreviation"></el-input>
          </div>
          <div class="col-sm-4">
            <label>Translation:</label>
            <el-input placeholder="Translation" v-model="korean_name"></el-input>
          </div>
          <div class="col-sm-6">
            <label>Notes:</label>
            <el-input placeholder="Notes" v-model="notes"></el-input>
          </div>
          <div class="col-sm-6">
            <label>Description:</label>
            <el-input placeholder="Description" v-model="description" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }"></el-input>
          </div>
          <div class="col-sm-4">
            <label>URL:</label>
            <el-input placeholder="Media URL" v-model="media"></el-input>
          </div>
          <div class="col-sm-4">
            <label>Family:</label>
            <el-select filterable v-model="kick_family_id" placeholder="Family">
              <el-option v-for="kick_family in kick_families" v-bind:key="kick_family.id"
                         :value="kick_family.id" :label="kick_family.name">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4">
            <label>Position:</label>
            <el-select filterable v-model="kicking_foot_position_id" placeholder="Position">
              <el-option v-for="kick_pos in kick_positions" v-bind:key="kick_pos.id"
                         :value="kick_pos.id" :label="kick_pos.name">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12 pt-2">
            <div class="row align-items-center">
              <div class="col-sm-12 text-center">
                <el-checkbox v-model="is_breaking"  size="large" border>Breaking</el-checkbox>
              </div>
              <div class="col-sm-12" v-if="is_breaking">
                <div class="row">
                  <div class="col-sm-4">
                    <label>A Minus:</label>
                    <el-input type="number" min=0 v-model="a_minus" size="small"></el-input>
                  </div>
                  <div class="col-sm-4">
                    <label>Base Point Value:</label>
                    <el-input type="number" min=0 v-model="base_point_value" size="small"></el-input>
                  </div>
                  <div class="col-sm-4">
                    <label>Board Multiplier:</label>
                    <el-input type="number" min=0 v-model="board_multiplier" size="small"></el-input>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" v-else>
                <div class="row">
                  <div class="col-sm-4">
                    <label>A Minus:</label>
                    <el-input type="number" min=0 v-model="a_minus" size="small" disabled></el-input>
                  </div>
                  <div class="col-sm-4">
                    <label>Base Point Value:</label>
                    <el-input type="number" min=0 v-model="base_point_value" size="small" disabled></el-input>
                  </div>
                  <div class="col-sm-4">
                    <label>Board Multiplier:</label>
                    <el-input type="number" min=0 v-model="board_multiplier" size="small" disabled></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div slot="footer" class="dialog-footer text-center">
          <el-button type="success" @click="createKick('create')" icon="">OK</el-button>
          <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Button, Select, Checkbox, Input, Option, Table, TableColumn } from 'element-ui';
import KicksAPIService from "src/servicehandlers/KicksAPIService";
import BreakingTechniquesAPIService from "src/servicehandlers/BreakingTechniquesAPIService";
import { FileAPIService } from 'src/servicehandlers/FileHandler';

const kicksAPIService = new KicksAPIService();
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
const fileAPI = new FileAPIService();

export default {
  name: "Kicks",
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    [Input.name]: Input,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      a_minus: null,
      base_point_value: null,
      board_multiplier: null,
      spinning: false,
      korean_name: null,
      abbreviation: null,
      notes: null,
      is_breaking: false,
      tested: false,
      selected_kick_id: null,
      pdfFile: null,
      message: '',
      filename: null,
      name: null,
      description: null,
      pdf: null,
      media: null,
      last_order: null,
      kicking_foot_position_id: null,
      kick_family_id: null,
      kicks: [],
      filteredKicks: [],
      kick_families: [],
      kick_positions: [],
      error_txt: null,
      success_txt: null,
      modals: {
        error: false,
        success: false,
        create: false,
        pdf: false,
      },
      search: '',
    }
  },
  mounted() {
    this.getKicks();
    this.getKickFamilies();
    this.getKickFootPostions();
  },
  methods: {
    getKickFootPostions() {
      return kicksAPIService.getKickFootPositions(this.$router)
      .then((response) => {
        this.kick_positions = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Getting kick positions failed! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getKickFamilies() {
      return kicksAPIService.getKickFamilies(this.$router)
      .then((response) => {
        this.kick_families = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Getting kick families failed! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.filename;
        this.spinning = false;
        link.click();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Kicks PDF download failed! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    preUpload(name, id) {
      this.selected_kick_id = id;
      this.openModal(name);
    },
    removeFile(id) {
      return kicksAPIService.updateKick(id, { pdf: null }, this.$router)
      .then(() => {
        this.getKicks();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'PDF clearing failure: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.pdfInput.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.pdfInput.files) {
        formData.append('pdfInput', fle);
        formData.append('type', 'kicks');
        formData.append('id', this.selected_kick_id);
      }
      return fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        const self = this;
        this.success_txt = 'PDF upload success';
        this.spinning = false;
        this.pdfFile = null;
        this.closeModal('pdf');
        this.openModal('success');
        this.getKicks();
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadPDF(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const fileType = /pdf/;
        if (!fileType.test(files[0].type)) {
          this.message = "Please select a valid PDF file.";
          return;
        }
        this.pdfFile = files[0];
        this.message = '';
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.kicks.length; i += 1) {
        const id = this.kicks[i].id;
        this.kicks[i].order = i + 1;
        promises.push(kicksAPIService.updateKick(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        this.getKicks();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async updateKick(kick) {
      let breaking_technique = null;
      if (kick.is_breaking) {
        try {
          const newKick = {
            name: kick.name,
            description: kick.description,
            media: kick.media,
            pdf: kick.pdf,
            notes: kick.notes,
            abbreviation: kick.abbreviation,
            korean_name: kick.korean_name,
            is_breaking: kick.is_breaking,
            a_minus: kick.a_minus,
            base_point_value: kick.base_point_value,
            board_multiplier: kick.board_multiplier,
            single: true,
          };
          if (!kick.sync_to_breaking_techniques) {
            breaking_technique = await breakingTechniquesAPIService.addBreakingTechnique(newKick, this.$router);
            let updated_sync_technique = await kicksAPIService.updateKick(kick.id, {sync_to_breaking_techniques: breaking_technique.id}, this.$router);
          } else {
            breaking_technique = await breakingTechniquesAPIService.updateBreakingTechnique(kick.sync_to_breaking_techniques, newKick, this.$router);
          }
        } catch (e) {
          const self = this;
          this.error_txt = 'Error updating Kick: ' + e;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        }
      }

      const parms = {
        name: kick.name,
        description: kick.description,
        media: kick.media,
        pdf: kick.pdf,
        notes: kick.notes,
        abbreviation: kick.abbreviation,
        korean_name: kick.korean_name,
        is_breaking: kick.is_breaking,
        tested: kick.tested,
        kick_family_id: kick.kick_family_id,
        kicking_foot_position_id: kick.kicking_foot_position_id,
        a_minus: kick.a_minus,
        base_point_value: kick.base_point_value,
        board_multiplier: kick.board_multiplier,
      };
      if (kick.is_breaking) {
        parms.sync_to_breaking_techniques = breaking_technique.id;
      }
      return kicksAPIService.updateKick(kick.id, parms, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Kick update successful';
        /*this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs*/
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Kick update failure: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    deleteKick(id) {
      return kicksAPIService.deleteKick(id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Delete Kick successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getKicks();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error deleting Kicks: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getKicks() {
      return kicksAPIService.getKick(this.$router)
      .then((response) => {
        for (const kick of response) {
          this.last_order = kick.order;
          this.updateOrder(kick)
        }
        this.kicks = response;
        this.filteredKicks = this.kicks
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Kicks: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async createKick() {
      this.closeModal('create');
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      let breaking_technique = null;
      if (this.is_breaking) {
        try {
          const newKick = {
            name: this.name,
            description: this.description,
            media: this.media,
            pdf: this.pdf,
            notes: this.notes,
            abbreviation: this.abbreviation,
            korean_name: this.korean_name,
            is_breaking: true,
            a_minus: this.a_minus,
            base_point_value: this.base_point_value,
            board_multiplier: this.board_multiplier,
            single: true,
          };
          breaking_technique = await breakingTechniquesAPIService.addBreakingTechnique(newKick, this.$router);
        } catch (e) {
          const self = this;
          this.error_txt = 'Error creating Kick: ' + e;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        }
      }

      const kick = {
        order,
        name: this.name,
        description: this.description,
        media: this.media,
        notes: this.notes,
        abbreviation: this.abbreviation,
        korean_name: this.korean_name,
        is_breaking: this.is_breaking,
        tested: this.tested,
        kick_family_id: this.kick_family_id,
        kicking_foot_position_id: this.kicking_foot_position_id,
      };
      if (this.is_breaking) {
        kick.sync_to_breaking_techniques = breaking_technique.id
      }
      return kicksAPIService.addKick(kick, this.$router)
      .then(async (response) => {
        this.kicks.push(response);
        this.name = null;
        this.description = null;
        this.media = null;
        this.is_breaking = false;
        this.abbreviation = null;
        this.korean_name = null;
        this.notes = null;
        // create a new kick in the breaking table if it is a breaking technique
        const self = this;
        this.success_txt = 'Add Kicks successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error Creating Kicks: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.kicks[index];
        this.kicks.splice(index, 1);
        this.kicks.splice(index - 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    moveDown(index) {
      if (index < this.kicks.length - 1) {
        const temp = this.kicks[index];
        this.kicks.splice(index, 1);
        this.kicks.splice(index + 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    updateOrder(item){
      for (let i = 0; i < this.kicks.length; i++){
        this.kicks[i].order = i+1
      }
      this.updateKick(item)
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredKicks.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredKicks.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterKicks(){
      try {
        this.filteredKicks = this.kicks.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
  }
}
</script>

<style scoped>
el-input,el-select{
  width: 100%;
}
</style>
