<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card v-if="!resetClicked">
                <div slot="header">
                  <h3 class="card-title text-center">Forgot Password</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="text"
                               :error="failed ? 'The field is required': null"
                               :hasSuccess="passed"
                               label="Username"
                               name="email"
                               v-model="email">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">Reset Password</button>
                </div>
              </card>
              <card v-else>
                <div class="row">
                  <div>
                    Your password has been reset.  You should be getting an email in the next hour to login to bst.
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </validationobserver>
      </div>
    </div>
    <el-dialog
      center
      title="error"
      :visible.sync="modals.error">
      <span><h5>error occurred</h5></span>
      <div class="row">
        <div class="col-md-12"></div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <label>{{ error_text}}</label>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">error</button>&nbsp;
</span>
    </el-dialog>
  </auth-layout>
</template>
<script>
import { FadeRenderTransition } from 'src/components'
import { extend } from "vee-validate";
import {
  Dialog,
} from 'element-ui';
import { required, email, min } from "vee-validate/dist/rules";
import UsersAPIService from "../../../servicehandlers/UsersAPIService";
import AuthLayout from './AuthLayout.vue';
const usersAPIService = new UsersAPIService();

extend("email", email);
extend("required", required);
extend("min", min);

  export default {
    components: {
      AuthLayout,
      FadeRenderTransition,
      [Dialog.name]: Dialog,
    },
    data() {
      return {
        email: "",
        password: "",
        resetClicked: false,
        subscribe: true,
        error_text: '',
        modals: {
          error: false,
        },
      };
    },
    methods: {
      openModal(name) {
        this.modals[name] = true;
      },
      closeModal(name) {
        this.modals[name] = false;
      },
      submit() {
        const parms = {
          username: this.email,
        };
        return usersAPIService.resetPassword(parms, this.$router)
        .then(() => {
          this.resetClicked = true;
        })
        .catch((error) => {
          this.error_text = 'Error while trying to reset password: ' + error;
          this.openModal('error');
        });
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open');
      },
      closeMenu () {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      }
    },
    beforeDestroy () {
      this.closeMenu();
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
