import CommonAPIService from './CommonHandler';

export class ProgramsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getPrograms(router) {
    const url = '/api/programs';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addProgram(program, router) {
    const url = '/api/programs';
    return this.CommonAPIService.postCall(url, program, router);
  }

  updateProgram(program_id, program, router) {
    const url = `/api/programs/${program_id}`;
    return this.CommonAPIService.putCall(url, program, router);
  }

  deleteProgram(program_id, router) {
    const url = `/api/programs/${program_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  getProgramsByRank(rank_id, router) {
    const url = `/api/programs/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  updateProgramsToRank(program_ids, rank_id, router) {
    const url = `/api/programs/${program_ids}/${rank_id}/torank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  linkProgramToRank(program_id, rank_id, router) {
    const url = `/api/programs/${program_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteProgramtoRank(program_id, rank_id, router) {
    const url = `/api/programs/${program_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default ProgramsAPIService;
