import CommonAPIService from './CommonHandler';

export class BreakingBreakingTechniquesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getBoardBreakingTechniques(router) {
    const url = '/api/breaking_techniques/boardonly';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getBreakingTechniqueWithDirections(technique_id, router) {
    const url = `/api/breaking_techniques/${technique_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getBreakingTechniques(router) {
    const url = '/api/breaking_techniques';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getAllBreakingTechniqueDirections(router) {
    const url = '/api/breaking_techniques/alldirections';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addBreakingTechnique(technique, router) {
    const url = '/api/breaking_techniques';
    return this.CommonAPIService.postCall(url, technique, router);
  }

  updateBreakingTechnique(techniqueId, technique, router) {
    const url = `/api/breaking_techniques/${techniqueId}`;
    return this.CommonAPIService.putCall(url, technique, router);
  }
  updateBreakingTechniqueToRank(data, rank_id, router) {
    const url = `/api/breaking_techniques/${rank_id}/torank`;
    return this.CommonAPIService.putCall(url, data, router);
  }

  getBreakingTechniqueTypes(router) {
    const url = `/api/breaking_techniques/types`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getBreakingTechniqueByRankAndBreakingTechniqueID(rank_id, technique_type_id, router) {
    const url = `/api/breaking_techniques/${rank_id}/${technique_type_id}/ranktechnique`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteBreakingTechniqueByRankAndBreakingTechniqueID(rank_id, technique_type_id, router) {
    const url = `/api/breaking_techniques/${rank_id}/${technique_type_id}/ranktechnique`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  addBreakingTechniqueByRankAndBreakingTechniqueID(technique_id, rank_id, router) {
    const url = `/api/breaking_techniques/${technique_id}/${rank_id}/ranktechnique`;
    return this.CommonAPIService.postCall(url, '', router);
  }

  getBreakingTechniquesByRankID(rank_id, router) {
    const url = `/api/breaking_techniques/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getPoomsaeBreakingTechniques(router) {
    const url = `/api/breaking_techniques/poomsae`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  deleteBreakingTechnique(techniqueId, router) {
    const url = `/api/breaking_techniques/${techniqueId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deleteBreakingTechniqueToOutline(techniqueId, router) {
    const url = `/api/breaking_techniques/${techniqueId}/outline`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  addDirectionsToBreakingTechnique(breaking_technique_id, direction_ids, router) {
    const url = `/api/breaking_techniques/${breaking_technique_id}/directions`;
    return this.CommonAPIService.postCall(url, direction_ids, router);
  }
  addBlankRow(outlineId, order, router) {
    const url = `/api/breaking_techniques/${outlineId}/${order}/blank`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  linkBreakingTechniqueToRank(technique_id, rank_id, router) {
    const url = `/api/breaking_techniques/${technique_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteBreakingTechniqueToRank(technique_id, rank_id, router) {
    const url = `/api/breaking_techniques/${technique_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  updateTestedOnBreakingTechique(technique_id, rank_id, tested, router) {
    const url = `/api/breaking_techniques/${technique_id}/${rank_id}/${tested}/tested`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default BreakingBreakingTechniquesAPIService;
