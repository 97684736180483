<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-8">
                <h4 class="card-title">Ranks</h4>
                <p class="card-category">
                  Create or Edit Ranks
                </p>
                <p class="card-category">
                  <el-button type="primary" @click="openModal('create')" icon="">Add New Rank</el-button>
                  <el-button type="primary" @click="openModal('levels')" icon="">Edit Rank Levels</el-button>
                </p>
              </div>
              <div class="col-sm-4 align-content-end">
                <master-print-dialog :type="'rank-curriculum'" :given-data="ranks" :print-name="'Ranks'"></master-print-dialog>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterRanks"></el-input>
                <el-switch style="height: 50px" v-model="hideSubRanks" active-text="Hide Sub Ranks" inactive-text="Show Sub Ranks" @change="filterRanks"></el-switch>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterRanks" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredRanks" style="width: 100%" height="65vh" @sort-change="handleSortChange" :row-class-name="tableRowClassName">>
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="200" align="center">
          <template v-slot="scope">
            <div class="row" :class="tableRowClassName(scope.row.is_sub_rank)">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-8" v-if="hideSubRanks || search.length > 0">
                <div class="row">
                  <div class="col-sm-6">
                    <el-tooltip effect="dark" content="Show Sub Ranks and Remove Search to Reorder Ranks">
                      <el-button disabled type="text" icon="el-icon-arrow-up"/>
                    </el-tooltip>
                  </div>
                  <div class="col-sm-6">
                    <el-tooltip effect="dark" content="Show Sub Ranks and Remove Search to Reorder Ranks">
                      <el-button disabled type="text" icon="el-icon-arrow-down" />
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="col-sm-8" v-else>
                  <div class="row">
                    <div class="col-sm-6">
                        <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
                    </div>
                    <div class="col-sm-6">
                        <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
                    </div>
                  </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" sortable="custom" label="Name" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input v-model="scope.row.name" placeholder="Name" @change="updateRank(scope.row)"></el-input>
            <div v-if="checkForMasterRank(scope.row.id)">
              <label>Is sub rank</label>
              <el-checkbox style="margin: 0 10px" v-model="scope.row.is_sub_rank"></el-checkbox>
              <label  style="margin: 0" v-if="scope.row.is_sub_rank">of</label>
            </div>
            <el-select filterable v-if="scope.row.is_sub_rank" v-model="scope.row.head_rank_id" placeholder="Master Rank" style="width: 100%"
                       @change="assignSubRank(scope.row)">
              <el-option v-for="item in subRankList(scope.row.id)"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Program" :min-width="150" align="center">
          <template v-slot="scope">
            <el-select filterable v-model="scope.row.program_id" placeholder="Select Program" style="width: 100%" @change="updateRank(scope.row)">
              <el-option v-for="item in programs"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Rank Level" :min-width="150" align="center">
          <template v-slot="scope">
            <el-select filterable v-model="scope.row.rank_type_id" placeholder="Select Rank Level" style="width: 100%" @change="updateRank(scope.row)">
              <el-option v-for="item in rank_types"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Breaking Requirements" :min-width="300" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-6">
                <label>Number of Breaks:</label>
                <el-input v-model="scope.row.number_of_breaks" min="0" type="number" @change="updateRank(scope.row)"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Breaking Point Minimum:</label>
                <el-input v-model="scope.row.breaking_point_minimum" min="0" type="number" @change="updateRank(scope.row)"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Required Speed Breaks:</label>
                <el-input v-model="scope.row.required_speed_breaks" min="0" type="number" @change="updateRank(scope.row)"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Required Power Breaks:</label>
                <el-input v-model="scope.row.required_power_breaks" min="0" type="number" @change="updateRank(scope.row)"></el-input>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Break Options" :min-width="150" align="center">
          <template v-slot="scope">
            <el-switch style="height: 50px" v-model="scope.row.freestyle_breaks" active-text="Freestyle" inactive-text="Assigned" @change="toggleBreaksAssign(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template v-slot="scope">
            <el-button v-if="checkForMasterRank(scope.row.id)" type="danger" @click="deleteRank(scope.row.id)" icon="el-icon-delete"></el-button>
            <el-tooltip v-else effect="dark" content="Rank has sub ranks">
              <el-button type="danger" @click="deleteRank(scope.row.id)" icon="el-icon-delete" disabled></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Edit Rank Levels"
      :visible.sync="modals.levels">
      <div class="text-center">
        <el-table stripe :data="rank_types" style="width: 100%">
          <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
            <template v-slot="scope">
              <div class="row">
                <div class="col-sm-4">
                  {{ scope.row.order }}
                </div>
                <div class="col-sm-4">
                  <el-button type="text" icon="el-icon-arrow-up" @click="moveTypeUp(scope.$index)" />
                </div>
                <div class="col-sm-4">
                  <el-button type="text" icon="el-icon-arrow-down" @click="moveTypeDown(scope.$index)" />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Name" :min-width="150" align="center">
            <template v-slot="scope">
              <el-input v-model="scope.row.name" placeholder="Name" @change="updateRankTypes(scope.row.id)"></el-input>
            </template>
          </el-table-column>
          <el-table-column :min-width="150" align="center">
            <template v-slot="scope">
              <el-button type="danger" icon="el-icon-delete" @click="deleteRankType(scope.row.id)"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addRankType" icon="">Add</el-button>
          <el-button type="danger" @click="closeModal('levels')" icon="">Close</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Create New Rank"
      :visible.sync="modals.create">
      <div class="text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <label>Name:</label>
                <el-input v-model="new_rank.name" placeholder="Name"></el-input>
              </div>
              <div class="col-sm-12">
                <label>Program:</label>
                <el-select filterable v-model="new_rank.program_id" placeholder="Select Program" style="width: 100%">
                  <el-option v-for="item in programs"
                             :key="item.id" :value="item.id" :label="item.name">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <label>Rank Level:</label>
                <el-select filterable v-model="new_rank.rank_type_id" placeholder="Select Rank Level" style="width: 100%">
                  <el-option v-for="item in rank_types"
                             :key="item.id" :value="item.id" :label="item.name">
                  </el-option>
                </el-select>
              </div>
              <div class="col-sm-12 text-center align-items-center">
                <el-switch style="height: 50px" v-model="new_rank.freestyle_breaks" active-text="Freestyle" inactive-text="Assigned"></el-switch>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <label>Number of Breaks:</label>
                <el-input v-model="new_rank.number_of_breaks" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Break Point Min:</label>
                <el-input v-model="new_rank.breaking_point_minimum" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Speed Breaks:</label>
                <el-input v-model="new_rank.required_speed_breaks" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Power Breaks:</label>
                <el-input v-model="new_rank.required_power_breaks" min="0" type="number"></el-input>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div slot="footer" class="dialog-footer text-center">
          <el-button type="success" @click="createRank('create')" icon="">Create</el-button>
          <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Change User Ranks"
      :visible.sync="modals.users">
      <div class="text-center" v-if="users.length > 0">
        These users are assigned the rank of {{delete_rank.name}}
        <el-table stripe :data="users" style="width: 100%">
          <el-table-column label="Name" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.first_name}} {{scope.row.last_name}}
            </template>
          </el-table-column>
          <el-table-column label="Action" :min-width="150" align="center">
            <template v-slot="scope">
              <el-button v-if="last_rank" type="danger" @click="demoteUser(scope.row.id)" icon="">Demote to {{last_rank.name}}</el-button>
              <el-button v-if="next_rank" type="primary" @click="promoteUser(scope.row.id)" icon="">Promote to {{next_rank.name}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('users')" icon="">Cancel</el-button>
        </span>
      </div>
      <div class="text-center" v-else>
        <el-button v-if="delete_rank" type="danger" @click="deleteRank(delete_rank.id)" icon="">Delete {{delete_rank.name}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Select, Option, Input, Button, Checkbox, Table, TableColumn, Switch, Tooltip } from 'element-ui';
import ProgramsAPIService from "src/servicehandlers/ProgramsAPIService";
import RanksAPIService from "src/servicehandlers/RanksAPIService";
import RankTypesAPIService from "../../../../servicehandlers/RankTypesAPIService";
import UserAPIService from "../../../../servicehandlers/UsersAPIService";
import MasterPrintDialog from "../MasterPrintDialog.vue";

const programsAPIService = new ProgramsAPIService();
const ranksAPIService = new RanksAPIService();
const rankTypesAPIService = new RankTypesAPIService();
const userAPIService = new UserAPIService()

export default {
  name: "RanksAdmin",
  components: {
    MasterPrintDialog,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      spinning: false,
      is_rank_selected: false,
      selected_rank_id: null,
      selected_rank: null,
      selected_ranks: [],
      programs: [],
      new_rank: {},
      ranks: [],
      filteredRanks: [],
      rank_types: [],
      reorder_ranks: false,
      error_txt: null,
      program_name: null,
      success_txt: null,
      last_order: null,
      modals: {
        error: false,
        success: false,
        person: false,
        create: false,
        levels: false,
        users: false,
      },
      search: '',
      users: [],
      last_rank: null,
      delete_rank: null,
      next_rank: null,
      hideSubRanks: false,
    }
  },
  mounted() {
    this.getRanks();
    this.getRankTypes();
    this.getPrograms();
  },
  methods: {
    toggleSelected() {
      this.is_rank_selected = true;
      this.selected_ranks = []
      for (const rank of this.ranks) {
        if (this.selected_rank_id && this.selected_rank_id === rank.id) {
          this.selected_rank = rank;
          this.selected_ranks.push(rank)
          return;
        }
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.ranks.length; i += 1) {
        const id = this.ranks[i].id;
        this.ranks[i].order = i + 1;
        promises.push(ranksAPIService.updateRank(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        // const self = this;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Error updating rank! ' + error,
          timer: 1000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          showConfirmButton: false
        });
      });
    },
    getPrograms() {
      return programsAPIService.getPrograms(this.$router)
      .then((programs) => {
        for (const program of programs) {
          program.value = program.id;
          program.text = program.name;
        }
        this.programs = programs;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Error getting programs! ' + error,
          timer: 1000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          showConfirmButton: false
        });
      });
    },
    getRanks() {
      return ranksAPIService.getRanks(this.$router)
      .then((response) => {
        this.ranks = response;
        for (const rank of this.ranks) {
          rank.text = rank.name;
          rank.value = rank.id;
          rank.order = parseFloat(rank.order)
          this.last_order = rank.order;
          rank.freestyle_breaks = !rank.assigned_breaks
        }
        this.filteredRanks = this.ranks
        this.selected_ranks = this.filteredRanks
      })
      .catch((error) => {
        this.error_txt = 'Error getting Ranks: ' + error;
        this.openModal('error');
      });
    },
    getRankTypes(){
      return rankTypesAPIService.getRankTypes(this.$router)
        .then((response) => {
          this.rank_types = response;
          this.updateTypeOrder()
        })
        .catch((error) => {
          this.error_txt = 'Error getting Rank Types: ' + error;
          this.openModal('error');
        });
    },
    getUsers(id){
      this.users = []
      return userAPIService.getUserList(this.$router)
        .then((response) => {
          for (let user of response){
            if (user.rank_id === id){
              this.users.push(user)
            }
          }
        })
    },
    updateRank(rank) {
      return ranksAPIService.updateRank(rank.id, rank, this.$router)
      .then(() => {
        const self = this;
        /*this.success_txt = 'Rank update successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs*/
      })
      .catch((error) => {
        this.error_txt = 'Error updating Rank: ' + error;
        this.openModal('error');
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      if (name === 'create') {
        this.new_rank = {};
      }
      this.modals[name] = false;
    },
    async deleteRank(id) {
      await this.getUsers(id)
      if (this.users.length > 0){
        for (let i = 0; i < this.ranks.length; i++){
          if (this.ranks[i].id === id){
            if (i >= 1){
              this.last_rank = this.ranks[i - 1]
            } else {
              this.last_rank = null
            }
            this.delete_rank = this.ranks[i]
            if (i <= this.ranks.length - 1){
              this.next_rank = this.ranks[i + 1]
            } else {
              this.next_rank = null
            }
            break
          }
        }
        this.openModal('users')
      } else {
        return ranksAPIService.deleteRank(id, this.$router)
        .then(() => {
          this.getRanks();
          this.updateOrder()
          this.selected_rank = {};
          this.is_rank_selected = false;
          const self = this;
          this.success_txt = 'Delete Rank successful';
          this.openModal('success');
          setTimeout(function () {
            self.modals['success'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        })
        .catch((error) => {
          this.error_txt = 'Error deleting rank: ' + error;
          this.openModal('error');
        });
      }
    },
    createRank(modalName) {
      if (!this.new_rank.name) {
        const self = this;
        this.error_txt = 'Missing rank name';
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        return;
      }
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      if (!this.new_rank.freestyle_breaks) {
        this.new_rank.freestyle_breaks = false;
      }
      if (!this.new_rank.assigned_breaks) {
        this.new_rank.assigned_breaks = false;
      }
      if (!this.new_rank.show_poomsae) {
        this.new_rank.show_poomsae = false;
      }
      this.new_rank.order = order;
      this.new_rank.freestyle_breaks = !this.new_rank.assigned_breaks
      return ranksAPIService.addRank(this.new_rank, this.$router)
      .then(() => {
        this.selected_rank = {};
        this.is_rank_selected = false;
        this.new_rank = {};
        this.getRanks();
        this.closeModal(modalName);
        const self = this;
        this.success_txt = 'Add Rank successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error rank program: ' + error;
        this.openModal('error');
      });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.ranks[index];
        if (temp.is_sub_rank){
          let subRanks = this.ranks.filter(data => data.is_sub_rank && (data.head_rank_id === temp.head_rank_id))
          let rankIndex = subRanks.indexOf(temp)
          if (rankIndex > 0){
            this.ranks.splice(index, 1);
            this.ranks.splice(index - 1, 0, temp);
          }
        } else {
          let found = false
          for (let rank of this.ranks){
            if (rank.head_rank_id === temp.id){
              found = true
              break
            }
          }
          if (found){
            let subCount = this.ranks.filter(data => data.is_sub_rank && (data.head_rank_id === temp.id)).length
            let prev = this.ranks[index - 1]
            this.ranks.splice(index - 1, 1)
            this.ranks.splice(index + subCount, 0, prev)
          } else {
            let prev = this.ranks[index - 1]
            if (prev.is_sub_rank){
              let headRank = this.ranks.filter(data => data.id === prev.head_rank_id)[0]
              let newIndex = this.ranks.indexOf(headRank)
              this.ranks.splice(index, 1)
              this.ranks.splice(newIndex, 0, temp)
            } else {
              this.ranks.splice(index, 1);
              this.ranks.splice(index - 1, 0, temp);
            }
          }
        }
        this.updateOrder()
      }
    },
    moveDown(index) {
      if (index < this.ranks.length - 1) {
        const temp = this.ranks[index];
        if (temp.is_sub_rank){
          let subRanks = this.ranks.filter(data => data.is_sub_rank && (data.head_rank_id === temp.head_rank_id))
          let rankIndex = subRanks.indexOf(temp)
          if (rankIndex < subRanks.length - 1){
            this.ranks.splice(index, 1);
            this.ranks.splice(index + 1, 0, temp);
          }
        } else {
          let found = false
          for (let rank of this.ranks){
            if (rank.head_rank_id === temp.id){
              found = true
              break
            }
          }
          if (found){
            let subCount = this.ranks.filter(data => data.is_sub_rank && (data.head_rank_id === temp.id)).length
            let next = this.ranks[index + subCount + 1]
            this.ranks.splice(index + subCount + 1, 1)
            this.ranks.splice(index - 1, 0, next)
          } else {
            let next = this.ranks[index + 1]
            let found = false
            for (let rank of this.ranks){
              if (rank.head_rank_id === next.id){
                found = true
                break
              }
            }
            if (found){
              let subRanks = this.ranks.filter(data => data.is_sub_rank && (data.head_rank_id === next.id))
              let newIndex =  this.ranks.indexOf(subRanks[subRanks.length - 1])
              this.ranks.splice(index, 1)
              this.ranks.splice(newIndex, 0, temp)
            } else {
              this.ranks.splice(index, 1);
              this.ranks.splice(index + 1, 0, temp);
            }
          }
        }
        this.updateOrder()
      }
    },
    updateOrder(){
      let count = 0
      let subCount = 1
      for (let i = 0; i < this.ranks.length; i++){
        let rank = this.ranks[i]
        if (rank.is_sub_rank){
          rank.order = count + (subCount * .1)
          subCount++
        } else {
          count++
          subCount = 1
          rank.order = count
        }
        this.updateRank(this.ranks[i])
      }
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredRanks.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredRanks.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterRanks(){
      try {
        this.filteredRanks = this.ranks.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
        if (this.hideSubRanks){
          this.filteredRanks = this.filteredRanks.filter(data => !data.is_sub_rank)
        }
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
    toggleBreaksAssign(rank){
      rank.assigned_breaks = !rank.freestyle_breaks
      this.updateRank(rank)
    },
    updateRankTypes(id){
      this.spinning = true
      let temp
      for (let type of this.rank_types){
        if (type.id === id){
          temp = type
          break
        }
      }
      return rankTypesAPIService.updateRankType(id, temp, this.$router)
        .then(() => {
          this.spinning = false
          //this.success_txt = "Successfully updated rank type"
          //this.openModal('success')
        })
        .catch((e) => {
          this.spinning = false
          this.error_txt = "Error updating rank type" + e
          this.openModal('error')
        })
    },
    deleteRankType(id){
      this.spinning = true
      return rankTypesAPIService.deleteRankType(id, this.$router)
        .then(() => {
          this.spinning = false
          //this.success_txt = "Successfully delete rank type"
          //this.openModal('success')
          this.getRankTypes()
        })
        .catch((e) => {
          this.spinning = false
          this.error_txt = 'Error delete rank type' + e
          this.openModal('error')
        })
    },
    addRankType(){
      let temp = {
        name: ''
      }
      return rankTypesAPIService.addRankType(temp, this.$router)
        .then(() => {
          //this.success_txt = 'Successfully add rank type'
          //this.openModal('success')
          this.getRankTypes()
        })
        .catch((e) => {
          this.error_txt = 'Error adding rank type' + e
          this.openModal('error')
        })
    },
    moveTypeUp(index) {
      if (index > 0) {
        const temp = this.rank_types[index];
        this.rank_types.splice(index, 1);
        this.rank_types.splice(index - 1, 0, temp);
        this.updateTypeOrder()
      }
    },
    moveTypeDown(index) {
      if (index < this.rank_types.length - 1) {
        const temp = this.rank_types[index];
        this.rank_types.splice(index, 1);
        this.rank_types.splice(index + 1, 0, temp)
        this.updateTypeOrder()
      }
    },
    updateTypeOrder(){
      for (let i = 0; i < this.rank_types.length; i++){
        this.rank_types[i].order = i+1
        this.updateRankTypes(this.rank_types[i].id)
      }
    },
    promoteUser(id){
      if (this.next_rank){
        return userAPIService.changeUserRankTo(id, this.next_rank.id, this.$router)
          .then(() => {
            this.getUsers(this.delete_rank.id)
          })
          .catch((e) => {
            this.error_txt = 'Error promoting user'
            this.openModal('error')
          })
      }
    },
    demoteUser(id){
      if (this.last_rank){
        return userAPIService.changeUserRankTo(id, this.last_rank.id, this.$router)
          .then(() => {
            this.getUsers(this.delete_rank.id)
          })
          .catch((e) => {
            this.error_txt = 'Error promoting user'
            this.openModal('error')
          })
      }
    },
    subRankList(){
      let temp = this.ranks
      temp = temp.filter((data) => !data.is_sub_rank)
      return temp
    },
    checkForMasterRank(id){
      for (let rank of this.ranks){
        if (rank.head_rank_id === id && rank.is_sub_rank){
          return false
        }
      }
      return true
    },
    assignSubRank(rank){
      let masterRank
      let found = false
      let newOrder
      for (let rnk of this.ranks){
        if (found){
          if (rnk.is_sub_rank && rnk.head_rank_id === masterRank.id && rnk.id !== rank.id){
            newOrder = parseFloat(rnk.order + .1)
          }
        }
        else if (rnk.id === rank.head_rank_id){
          masterRank = rnk
          found = true
          newOrder = parseFloat(masterRank.order + .1)
        }
      }
      if (newOrder >= (masterRank.order + 1)){
        rank.head_rank_id = null
        this.error_txt = "Too many sub ranks assigned to " + masterRank.name
        this.openModal('error')
        return
      }
      rank.order = newOrder.toFixed(1)
      this.ranks.sort((a, b) => a.order - b.order);
      this.updateOrder()
      this.updateRank(rank)
    },
    tableRowClassName(value) {
      if (value) {
        return 'subRank';
      }
      return '';
    },
  }
}
</script>

<style>
.subRank{
  margin-left: 50px;
}
</style>
