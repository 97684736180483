import CommonAPIService from './CommonHandler';

export class SupportOptionsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getSupportOptions(router) {
    const url = '/api/support_options';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addSupportOption(support_option, router) {
    const url = '/api/support_options';
    return this.CommonAPIService.postCall(url, support_option, router);
  }

  updateSupportOption(support_optionId, support_option, router) {
    const url = `/api/support_options/${support_optionId}`;
    return this.CommonAPIService.putCall(url, support_option, router);
  }

  deleteSupportOption(support_optionId, router) {
    const url = `/api/support_options/${support_optionId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default SupportOptionsAPIService;
