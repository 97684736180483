<template>
    <span>Successfully Added Kick/Motion</span>
</template>
<script>
  export default {
    name: 'notification-addedKickMotion'
  }
</script>
<style>
</style>
