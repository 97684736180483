import CommonAPIService from './CommonHandler';

export class JudgeadminAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getCurrentOutline(router) {
    const url = `/api/judgeadmin`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addJudgeAdmin(judgeadmin, router) {
    const url = '/api/judgeadmin';
    return this.CommonAPIService.postCall(url, outline, router);
  }

  updateJudgeAdmin(judge_admin_id, parms, router) {
    const url = `/api/judgeadmin/${judge_admin_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  deleteJudgeAdmin(judgeadmin_id, router) {
    const url = `/api/judgeadmin/${judgeadmin_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default JudgeadminAPIService;
