/*!

 =========================================================
 * Vue Light Bootstrap Dashboard - v1.4.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import VueHtmlToPaper from 'vue-html-to-paper';
import LightBootstrap from './light-bootstrap-main';
import 'vue-search-select/dist/VueSearchSelect.css';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// Plugins
import App from './App.vue';

const options = {
  name: '_blank',
  size: 'landscape',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    '/static/css/bootstrap.min.css',
    '/static/css/VueSearchSelect.css',
    '/static/css/nucleo_icons.css',
    '/static/css/dialog.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: 'PrintPreview', // override the window title
}

// router setup
import { store } from './store';
import { router } from './routes/routes';
// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(BootstrapVue);
Vue.use(VueHtmlToPaper, options);

// configure router
// const router = new VueRouter({
//   routes, // short for routes: routes
//   linkActiveClass: 'active'
// })

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
})
