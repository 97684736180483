import CommonAPIService from './CommonHandler';

export class EventsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getEvents(router) {
    const url = '/api/events';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getEvent(event_id, router) {
    const url = `/api/events/${event_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addEvent(event, router) {
    const url = '/api/events';
    return this.CommonAPIService.postCall(url, event, router);
  }

  updateEvent(eventId, event, router) {
    const url = `/api/events/${eventId}`;
    return this.CommonAPIService.putCall(url, event, router);
  }

  deleteEvent(eventId, router) {
    const url = `/api/events/${eventId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  getEventsByIDWithOutlines(eventId, router) {
    const url = `/api/events/${eventId}/eventoutline`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getGroupsForEvent(event_id, router) {
    const url = `/api/events/${event_id}/groups`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  saveGroupsForEvent(groups, event_id, router){
    const url = `/api/events/${event_id}/groups`;
    return this.CommonAPIService.putCall(url, groups, router);
  }

  getEventsForUser(user_id, router) {
    const url = `/api/events/${user_id}/user`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default EventsAPIService;
