import CommonAPIService from './CommonHandler';

export class MotionsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the motions
  getMotion(router) {
    const url = '/api/motions';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getMotionByRank(rank_id, router) {
    const url = '/api/motions/' + rank_id + '/rank';
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveMotionToTechniques(techniques, router) {
    const url = `/api/motions/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getMotionByID(motion_id, router) {
    const url = `/api/motions/${motion_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveMotionToRank(ranks, router) {
    const url = '/api/motions/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addMotion(motions, router) {
    const url = '/api/motions';
    return this.CommonAPIService.postCall(url, motions, router);
  }

  updateMotion(motion_id, parms, router) {
    const url = `/api/motions/${motion_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  updateMotionsToRank(motion_ids, rank_id, router) {
    const url = `/api/motions/${motion_ids}/${rank_id}/torank`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkMotionToRank(motion_id, rank_id, router) {
    const url = `/api/motions/${rank_id}/${motion_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteMotionToRank(motion_id, rank_id, router) {
    const url = `/api/motions/${motion_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deleteMotion(motion_id, router) {
    const url = `/api/motions/${motion_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default MotionsAPIService;
