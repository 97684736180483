import CommonAPIService from './CommonHandler';

export class DirectionsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getAllDirections(router) {
    const url = '/api/directions/all';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getDirections(router) {
    const url = '/api/directions';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getDirection(direction_id, router) {
    const url = `/api/directions/${direction_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getDirectionsByTechniqueId(technique_id, router) {
    const url = `/api/directions/${technique_id}/technique`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addDirection(direction, router) {
    const url = '/api/directions';
    return this.CommonAPIService.postCall(url, direction, router);
  }

  updateDirection(directionId, direction, router) {
    const url = `/api/directions/${directionId}`;
    return this.CommonAPIService.putCall(url, direction, router);
  }

  deleteDirection(directionId, router) {
    const url = `/api/directions/${directionId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default DirectionsAPIService;
