<template>
  <div>
    <!-- Start if spinning Card -->
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center"><b-spinner variant="primary" label="Spinning"></b-spinner></div>
      </div>
    </card>
    <!-- End if spinning Card -->

    <!-- Start else div-->
    <div v-else>

      <!-- Start header Card -->
      <card>
        <div class="row align-items-center">
          <div class="col-sm-3">
            <el-select v-model="dropdown_outline" placeholder="Select Outline to View" @input="getUserOutline">
              <el-option v-for="outline in my_outlines"
                         :key="outline.id" :value="outline.id" :label="outline.description">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-3">
            <label>Outline: {{ outline_description }}</label>
          </div>
          <div class="col-sm-3">
            <el-select v-model="dropdown_judge" placeholder="Select Judge View" @input="getUserOutline">
              <el-option v-for="judge in judges"
                         :key="judge.value" :value="judge.value" :label="judge.text">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-3">
            <label>Judge #{{selected_judge}}: {{ judge_name }}</label>
          </div>
        </div>
      </card>
      <!-- End header Card -->

      <!-- Start Scoring Card -->
      <card>
        <div class="row pb-4">
          <div class="col-sm-12 text-center">
            <span style="font-weight: bold; font-size: 3em;">Presentation Scoring</span>
          </div>
        </div>

        <!-- Start organization Score -->
        <div class="row">
          <div class="col-sm-12 text-center pb-4">
            <span style="font-weight: bold; font-size: 2em;">{{ organization.title }}</span>
            <div v-html="organization.text" class="col-sm-12 text-center pt-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6 text-center">
            <div class="row">
              <label class="col-sm-4">{{ organization.first_text }}</label>
              <label class="col-sm-4">{{ organization.second_text }}</label>
              <label class="col-sm-4">{{ organization.third_text }}</label>
            </div>
            <div class="row pb-5">
              <div class="col-sm-12">
                <div v-for="org in organization.items"
                     :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                     v-bind:key="org.id">
                  {{ org.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End organization Score -->

        <!-- Start modifiers Score -->
        <div class="row">
          <div class="col-sm-12 text-center pb-4">
            <span style="font-weight: bold; font-size: 2em;">{{ modifiers.title }}</span>
            <div v-html="modifiers.text" class="col-sm-12 text-center pt-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6 text-center">
            <div class="row">
              <label class="col-sm-4">{{ modifiers.first_text }}</label>
              <label class="col-sm-4">{{ modifiers.second_text }}</label>
              <label class="col-sm-4">{{ modifiers.third_text }}</label>
            </div>
            <div class="row pb-5">
              <div class="col-sm-12">
                <div v-for="org in modifiers.items"
                     :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                     v-bind:key="org.id">
                  {{ org.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End modifiers Score -->

        <!-- Start heightOfTechniques Score -->
        <div class="row">
          <div class="col-sm-12 text-center pb-4">
            <span style="font-weight: bold; font-size: 2em;">{{ heightOfTechniques.title }}</span>
            <div v-html="heightOfTechniques.text" class="col-sm-12 text-center pt-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6 text-center">
            <div class="row">
              <label class="col-sm-4">{{ heightOfTechniques.first_text }}</label>
              <label class="col-sm-4">{{ heightOfTechniques.second_text }}</label>
              <label class="col-sm-4">{{ heightOfTechniques.third_text }}</label>
            </div>
            <div class="row pb-5">
              <div class="col-sm-12">
                <div v-for="org in heightOfTechniques.items"
                     :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                     v-bind:key="org.id">
                  {{ org.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End heightOfTechniques Score -->

        <!-- Start transitionsAndFlow Score -->
        <div class="row">
          <div class="col-sm-12 text-center pb-4">
            <span style="font-weight: bold; font-size: 2em;">{{ transitionsAndFlow.title }}</span>
            <div v-html="transitionsAndFlow.text" class="col-sm-12 text-center pt-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6 text-center">
            <div class="row">
              <label class="col-sm-4">{{ transitionsAndFlow.first_text }}</label>
              <label class="col-sm-4">{{ transitionsAndFlow.second_text }}</label>
              <label class="col-sm-4">{{ transitionsAndFlow.third_text }}</label>
            </div>
            <div class="row pb-5">
              <div class="col-sm-12">
                <div v-for="org in transitionsAndFlow.items"
                     :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                     v-bind:key="org.id">
                  {{ org.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End transitionsAndFlow Score -->

        <!-- Start epicness Score -->
        <div class="row">
          <div class="col-sm-12 text-center pb-4">
            <span style="font-weight: bold; font-size: 2em;">{{ epicness.title }}</span>
            <div v-html="epicness.text" class="col-sm-12 text-center pt-3"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6 text-center">
            <div class="row">
              <label class="col-sm-4">{{ epicness.first_text }}</label>
              <label class="col-sm-4">{{ epicness.second_text }}</label>
              <label class="col-sm-4">{{ epicness.third_text }}</label>
            </div>
            <div class="row pb-5">
              <div class="col-sm-12">
                <div v-for="org in epicness.items"
                     :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                     v-bind:key="org.id">
                  {{ org.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End epicness Score -->

      </card>
      <!-- End Scoring Card -->

      <!-- Start Score and Submit Card -->
      <card>
        <div class="row">
          <div class="col-sm-12 text-center">
            <span style="font-weight: bold; font-size: 2em">Total Score: {{total_score.toFixed(2)}}</span>
          </div>
        </div>
      </card>
      <!-- End Score and Submit Card -->

      <!--
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center"><b-spinner variant="primary" label="Spinning"></b-spinner></div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4">
          <model-select class="select-primary" :options="my_outlines"
                        v-model="dropdown_outline"
                        @input="getUserOutline"
                        placeholder="Select Outline to View">
          </model-select>
        </div>
        <div class="col-sm-4">
        </div>
        <div class="col-sm-4">
          <model-select class="select-primary" :options="judges"
                        v-model="dropdown_judge"
                        @input="getUserOutline"
                        placeholder="Select Judge View">
          </model-select>
          <label>Judge #:&nbsp;</label><label>{{ selected_judge }}</label>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4">
          <label>Judge: {{ judge_name }}</label>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <label>Outline: {{ outline_description }}</label>
        </div>
      </div>
    </card>
    <card>
      <div class="row pb-5">
        <div class="col-sm-12 text-center">
          <span style="font-weight: bold; font-size: 4em;">Presentation Scoring</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center"><span
          style="font-weight: bold; font-size: 2em;">{{ organization.title }}</span></div>
      </div>
      <div class="row">
        <div v-html="organization.text" class="col-sm-6 text-center"></div>
        <div class="col-sm-6 text-center">
          <div class="row">
            <div class="col-sm-4">{{ organization.first_text }}</div>
            <div class="col-sm-4">{{ organization.second_text }}</div>
            <div class="col-sm-4">{{ organization.third_text }}</div>
          </div>
          <div class="row pb-2">
            <div class="col-sm-12">
              <div @click="changeColor(org.id, organization)" v-for="org in organization.items"
                   :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                   v-bind:key="org.id">
                {{ org.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center"><span
          style="font-weight: bold; font-size: 2em;">{{ modifiers.title }}</span></div>
      </div>
      <div class="row">
        <div v-html="modifiers.text" class="col-sm-6 text-center"></div>
        <div class="col-sm-6 text-center">
          <div class="row">
            <div class="col-sm-4">{{ modifiers.first_text }}</div>
            <div class="col-sm-4">{{ modifiers.second_text }}</div>
            <div class="col-sm-4">{{ modifiers.third_text }}</div>
          </div>
          <div class="row pb-2">
            <div class="col-sm-12">
              <div @click="changeColor(org.id, modifiers)" v-for="org in modifiers.items"
                   :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                   v-bind:key="org.id">
                {{ org.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center"><span
          style="font-weight: bold; font-size: 2em;">{{ heightOfTechniques.title }}</span></div>
      </div>
      <div class="row">
        <div v-html="heightOfTechniques.text" class="col-sm-6 text-center"></div>
        <div class="col-sm-6 text-center">
          <div class="row">
            <div class="col-sm-4">{{ heightOfTechniques.first_text }}</div>
            <div class="col-sm-4">{{ heightOfTechniques.second_text }}</div>
            <div class="col-sm-4">{{ heightOfTechniques.third_text }}</div>
          </div>
          <div class="row pb-2">
            <div class="col-sm-12">
              <div @click="changeColor(org.id, heightOfTechniques)" v-for="org in heightOfTechniques.items"
                   :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                   v-bind:key="org.id">
                {{ org.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center"><span
          style="font-weight: bold; font-size: 2em;">{{ transitionsAndFlow.title }}</span></div>
      </div>
      <div class="row">
        <div v-html="transitionsAndFlow.text" class="col-sm-6 text-center"></div>
        <div class="col-sm-6 text-center">
          <div class="row">
            <div class="col-sm-4">{{ transitionsAndFlow.first_text }}</div>
            <div class="col-sm-4">{{ transitionsAndFlow.second_text }}</div>
            <div class="col-sm-4">{{ transitionsAndFlow.third_text }}</div>
          </div>
          <div class="row pb-2">
            <div class="col-sm-12">
              <div @click="changeColor(org.id, transitionsAndFlow)" v-for="org in transitionsAndFlow.items"
                   :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                   v-bind:key="org.id">
                {{ org.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center"><span
          style="font-weight: bold; font-size: 2em;">{{ epicness.title }}</span></div>
      </div>
      <div class="row">
        <div v-html="epicness.text" class="col-sm-6 text-center"></div>
        <div class="col-sm-6 text-center">
          <div class="row">
            <div class="col-sm-4">{{ epicness.first_text }}</div>
            <div class="col-sm-4">{{ epicness.second_text }}</div>
            <div class="col-sm-4">{{ epicness.third_text }}</div>
          </div>
          <div class="row pb-2">
            <div class="col-sm-12">
              <div @click="changeColor(org.id, epicness)" v-for="org in epicness.items"
                   :class="{ static: org.static, box: org.box, box_checked: org.box_checked }"
                   v-bind:key="org.id">
                {{ org.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12 text-center">
          <span style="font-weight: bold; font-size: 4em">Total Score: {{total_score.toFixed(2)}}</span>
        </div>
      </div>
    </card>
    -->
      <card v-if="submitted">
        <div class="row">
          <div class="col-sm-12 text-center">
            {{ submit_results }}
          </div>
        </div>
      </card>
      <el-dialog
        center
        title="Error"
        :visible.sync="modals.error">
        <div class="text-center">
          <span>Error: {{ error_txt }}</span>
          <br>
          <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
          </span>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Dialog, Select, Option, Button } from 'element-ui';
import UsersAPIService from "../../../../servicehandlers/UsersAPIService";
const userAPIService = new UsersAPIService();
import OutlinesAPIService from "../../../../servicehandlers/OutlinesAPIService";
const outlinesAPIService = new OutlinesAPIService();
export default {
  name: "JudgeStudentView",
  components: {
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
  },
  data() {
    return {
      selected_judge: null,
      judges_to_select: [],
      spinning: false,
      submitted: false,
      current_outline: null,
      submit_results: '',
      error_txt: null,
      judge_name: null,
      isActive: false,
      judge_number: null,
      dropdown_judge: null,
      dropdown_outline: null,
      judges: [{text: 'J1', value: 1},{text: 'J2', value: 2},{text: 'J3', value: 3},
        {text: 'J4', value: 4},{text: 'J5', value: 5}],
      judgeNum: [{j1: false, j2: false, j3: false, j4: false, j5: false}],
      outline_description: '',
      outlines: [],
      my_outlines: [],
      outline: [],
      judge: [],
      org_score: 0,
      modifier_score: 0,
      height_score: 0,
      transition_score: 0,
      epic_score: 0,
      total_score: 0,
      modals: {
        error: false,
      },
      organization: {
        title: 'Organization & Layout',
        text: 'How interesting/original was their layout? Could you see their breaks? How efficient was their set up?',
        first_text: 'Dynamic & Efficient',
        second_text: 'Dynamic or Efficient',
        third_text: 'Neither',
        items: [
          { id: 1, static: true, 'box': true, 'box_checked': false, value: 0.8, selected: false },
          { id: 2, static: true, 'box': true, 'box_checked': false, value: 0.7, selected: false },
          { id: 3, static: true, 'box': true, 'box_checked': false, value: 0.6, selected: false },
          { id: 4, static: true, 'box': true, 'box_checked': false, value: 0.5, selected: false },
          { id: 5, static: true, 'box': true, 'box_checked': false, value: 0.4, selected: false },
          { id: 6, static: true, 'box': true, 'box_checked': false, value: 0.3, selected: false },
          { id: 7, static: true, 'box': true, 'box_checked': false, value: 0.2, selected: false },
          { id: 8, static: true, 'box': true, 'box_checked': false, value: 0.1, selected: false }]
      },
      modifiers: {
        title: 'Modifiers',
        text: 'Modifiers added value & were impressive. (Blindfolds, Speed Breaks, Simultaneous, Successive, Power Breaks)',
        first_text: 'Effective & Impressive',
        second_text: 'Effective or Impressive',
        third_text: 'Neither',
        items: [
          { id: 1, static: true, 'box': true, 'box_checked': false, value: 0.8, selected: false },
          { id: 2, static: true, 'box': true, 'box_checked': false, value: 0.7, selected: false },
          { id: 3, static: true, 'box': true, 'box_checked': false, value: 0.6, selected: false },
          { id: 4, static: true, 'box': true, 'box_checked': false, value: 0.5, selected: false },
          { id: 5, static: true, 'box': true, 'box_checked': false, value: 0.4, selected: false },
          { id: 6, static: true, 'box': true, 'box_checked': false, value: 0.3, selected: false },
          { id: 7, static: true, 'box': true, 'box_checked': false, value: 0.2, selected: false },
          { id: 8, static: true, 'box': true, 'box_checked': false, value: 0.1, selected: false }]
      },
      heightOfTechniques: {
        title: 'Height of Techniques',
        text: 'Performed techniques at expected height or higher.',
        first_text: 'Above',
        second_text: 'As Expected',
        third_text: 'Below',
        items: [
          { id: 1, static: true, 'box': true, 'box_checked': false, value: 0.8, selected: false },
          { id: 2, static: true, 'box': true, 'box_checked': false, value: 0.7, selected: false },
          { id: 3, static: true, 'box': true, 'box_checked': false, value: 0.6, selected: false },
          { id: 4, static: true, 'box': true, 'box_checked': false, value: 0.5, selected: false },
          { id: 5, static: true, 'box': true, 'box_checked': false, value: 0.4, selected: false },
          { id: 6, static: true, 'box': true, 'box_checked': false, value: 0.3, selected: false },
          { id: 7, static: true, 'box': true, 'box_checked': false, value: 0.2, selected: false },
          { id: 8, static: true, 'box': true, 'box_checked': false, value: 0.1, selected: false }]
      },
      transitionsAndFlow: {
        title: 'Transitions & Flow',
        text: 'Transitions were planned & well executed. Routine flowed well w/ no wasted motions. Minimal adjustments during.',
        first_text: 'Transitions & Flow',
        second_text: 'Transitions or Flow',
        third_text: 'Neither',
        items: [
          { id: 1, static: true, 'box': true, 'box_checked': false, value: 0.8, selected: false },
          { id: 2, static: true, 'box': true, 'box_checked': false, value: 0.7, selected: false },
          { id: 3, static: true, 'box': true, 'box_checked': false, value: 0.6, selected: false },
          { id: 4, static: true, 'box': true, 'box_checked': false, value: 0.5, selected: false },
          { id: 5, static: true, 'box': true, 'box_checked': false, value: 0.4, selected: false },
          { id: 6, static: true, 'box': true, 'box_checked': false, value: 0.3, selected: false },
          { id: 7, static: true, 'box': true, 'box_checked': false, value: 0.2, selected: false },
          { id: 8, static: true, 'box': true, 'box_checked': false, value: 0.1, selected: false }]
      },
      epicness: {
        title: 'Epic-ness',
        text: 'Performed unique, interesting, or fun breaks with energy and confidence. It looked Epic!',
        first_text: 'Technical & Challenging',
        second_text: 'Technical or Challenging',
        third_text: 'Neither',
        items: [
          { id: 1, static: true, 'box': true, 'box_checked': false, value: 0.8, selected: false },
          { id: 2, static: true, 'box': true, 'box_checked': false, value: 0.7, selected: false },
          { id: 3, static: true, 'box': true, 'box_checked': false, value: 0.6, selected: false },
          { id: 4, static: true, 'box': true, 'box_checked': false, value: 0.5, selected: false },
          { id: 5, static: true, 'box': true, 'box_checked': false, value: 0.4, selected: false },
          { id: 6, static: true, 'box': true, 'box_checked': false, value: 0.3, selected: false },
          { id: 7, static: true, 'box': true, 'box_checked': false, value: 0.2, selected: false },
          { id: 8, static: true, 'box': true, 'box_checked': false, value: 0.1, selected: false }]
      }
    };
  },
  mounted() {
    this.getCurrentUserAndOutline();
    this.calculateScore();
    this.getUserOutlines();
  },
  methods: {
    getUserOutline() {
      this.clearSelections();
      this.submitted = false;
      this.submit_results = '';
      this.spinning = true;
      const userId = this.$store.getters.userId;
      return userAPIService.getUserById(userId, this.$router)
      .then((user) => {
        this.judge = user;
        this.judge_name = user.first_name + ' ' + user.last_name;
        this.spinning = false;
        // now get all the users and line it up
        if (user.current_outline) {
          return outlinesAPIService.getOutlineByID(this.dropdown_outline, this.$router);
        }
      })
      .then((outline) => {
        this.outline = outline;
        this.judges_to_select = [];
        this.outline_description = outline.description;
        this.spinning = false;

        // figure out which judge this user is, it could be multiple judges on the outline for the same user
        if (this.dropdown_judge === 1) {
          this.selected_judge = 'j1';
          this.judge_number = 1;
          if (outline.org_score1 > 0) {
            this.findAndChangeColor(outline.org_score1, this.organization);
          }
          if (outline.modifier_score1 > 0) {
            this.findAndChangeColor(outline.modifier_score1, this.modifiers);
          }
          if (outline.height_score1 > 0) {
            this.findAndChangeColor(outline.height_score1, this.heightOfTechniques);
          }
          if (outline.transition_score1 > 0) {
            this.findAndChangeColor(outline.transition_score1, this.transitionsAndFlow);
          }
          if (outline.epic_score1 > 0) {
            this.findAndChangeColor(outline.epic_score1, this.epicness);
          }
        }
        if (this.dropdown_judge === 2) {
          this.selected_judge = 'j2';
          this.judge_number = 2;
          if (outline.org_score2 > 0) {
            this.findAndChangeColor(outline.org_score2, this.organization);
          }
          if (outline.modifier_score2 > 0) {
            this.findAndChangeColor(outline.modifier_score2, this.modifiers);
          }
          if (outline.height_score2 > 0) {
            this.findAndChangeColor(outline.height_score2, this.heightOfTechniques);
          }
          if (outline.transition_score2 > 0) {
            this.findAndChangeColor(outline.transition_score2, this.transitionsAndFlow);
          }
          if (outline.epic_score2 > 0) {
            this.findAndChangeColor(outline.epic_score2, this.epicness);
          }
        }
        if (this.dropdown_judge === 3) {
          this.selected_judge = 'j3';
          this.judge_number = 3;
          if (outline.org_score3 > 0) {
            this.findAndChangeColor(outline.org_score3, this.organization);
          }
          if (outline.modifier_score3 > 0) {
            this.findAndChangeColor(outline.modifier_score3, this.modifiers);
          }
          if (outline.height_score3 > 0) {
            this.findAndChangeColor(outline.height_score3, this.heightOfTechniques);
          }
          if (outline.transition_score3 > 0) {
            this.findAndChangeColor(outline.transition_score3, this.transitionsAndFlow);
          }
          if (outline.epic_score3 > 0) {
            this.findAndChangeColor(outline.epic_score3, this.epicness);
          }
        }
        if (this.dropdown_judge === 4) {
          this.selected_judge = 'j4';
          this.judge_number = 4;
          if (outline.org_score4 > 0) {
            this.findAndChangeColor(outline.org_score4, this.organization);
          }
          if (outline.modifier_score4 > 0) {
            this.findAndChangeColor(outline.modifier_score4, this.modifiers);
          }
          if (outline.height_score4 > 0) {
            this.findAndChangeColor(outline.height_score4, this.heightOfTechniques);
          }
          if (outline.transition_score4 > 0) {
            this.findAndChangeColor(outline.transition_score4, this.transitionsAndFlow);
          }
          if (outline.epic_score4 > 0) {
            this.findAndChangeColor(outline.epic_score4, this.epicness);
          }
        }
        if (this.dropdown_judge === 5) {
          this.selected_judge = 'j5';
          this.judge_number = 5;
          if (outline.org_score5 > 0) {
            this.findAndChangeColor(outline.org_score5, this.organization);
          }
          if (outline.modifier_score5 > 0) {
            this.findAndChangeColor(outline.modifier_score5, this.modifiers);
          }
          if (outline.height_score5 > 0) {
            this.findAndChangeColor(outline.height_score5, this.heightOfTechniques);
          }
          if (outline.transition_score5 > 0) {
            this.findAndChangeColor(outline.transition_score5, this.transitionsAndFlow);
          }
          if (outline.epic_score5 > 0) {
            this.findAndChangeColor(outline.epic_score5, this.epicness);
          }
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting user and outline: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getUserOutlines() {
      this.spinning = true;
      const userId = this.$store.getters.userId;
      return outlinesAPIService.getOutlineByUser(userId, this.$router)
      .then((outlines) => {
        this.my_outlines = outlines;
        for (const outline of this.my_outlines) {
          outline.text = outline.description;
          outline.value = outline.id;
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting user and outline: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },

    submitScore() {
      // submit the score to the DB as is
      this.spinning = true;
      const userId = this.$store.getters.userId;
      return outlinesAPIService.submitScore(this.current_outline, userId, this.judge_number,
        this.org_score, this.modifier_score, this.height_score, this.transition_score,
        this.epic_score, this.total_score, this.$router)
      .then((updated) => {
        this.spinning = false;
        this.submit_results = 'Score Update Successful!';
        this.submitted = true;
      })
      .catch((error) => {
        this.error_txt = 'Error getting user and outline: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },

    calculateScore() {
      this.total_score = 0;
      for (const org of this.organization.items) {
        if (org.selected === true) {
          this.total_score += org.value;
          this.org_score = org.value;
        }
      }
      for (const mod of this.modifiers.items) {
        if (mod.selected === true) {
          this.modifier_score = mod.value;
          this.total_score += mod.value;
        }
      }
      for (const height of this.heightOfTechniques.items) {
        if (height.selected === true) {
          this.height_score = height.value;
          this.total_score += height.value;
        }
      }
      for (const trans of this.transitionsAndFlow.items) {
        if (trans.selected === true) {
          this.transition_score = trans.value;
          this.total_score += trans.value;
        }
      }
      for (const epic of this.epicness.items) {
        if (epic.selected === true) {
          this.epic_score = epic.value;
          this.total_score += epic.value;
        }
      }
    },

    // find the value and sets it
    findAndChangeColor(value, objItem) {
      objItem.items = [
        { id: 1, static: true, 'box': true, 'box_checked': false, value: 0.8, selected: false },
        { id: 2, static: true, 'box': true, 'box_checked': false, value: 0.7, selected: false },
        { id: 3, static: true, 'box': true, 'box_checked': false, value: 0.6, selected: false },
        { id: 4, static: true, 'box': true, 'box_checked': false, value: 0.5, selected: false },
        { id: 5, static: true, 'box': true, 'box_checked': false, value: 0.4, selected: false },
        { id: 6, static: true, 'box': true, 'box_checked': false, value: 0.3, selected: false },
        { id: 7, static: true, 'box': true, 'box_checked': false, value: 0.2, selected: false },
        { id: 8, static: true, 'box': true, 'box_checked': false, value: 0.1, selected: false },
      ];
      for (const item of objItem.items) {
        if (item.value === value) {
          item.box = false;
          item.box_checked = true;
          item.selected = true;
          this.calculateScore();
          break;
        }
      }
    },

    clearSelections() {
      for (const org of this.organization.items) {
        org.box = true;
        org.box_checked = false;
        org.selected = false;
      }
      for (const mod of this.modifiers.items) {
        mod.box = true;
        mod.box_checked = false;
        mod.selected = false;
      }
      for (const height of this.heightOfTechniques.items) {
        height.box = true;
        height.box_checked = false;
        height.selected = false;
      }
      for (const trans of this.transitionsAndFlow.items) {
        trans.box = true;
        trans.box_checked = false;
        trans.selected = false;
      }
      for (const epic of this.epicness.items) {
        epic.box = true;
        epic.box_checked = false;
        epic.selected = false;
      }
    },

    changeColor(item, objItem) {
      objItem.items = [
        { id: 1, static: true, 'box': true, 'box_checked': false, value: 0.8, selected: false },
        { id: 2, static: true, 'box': true, 'box_checked': false, value: 0.7, selected: false },
        { id: 3, static: true, 'box': true, 'box_checked': false, value: 0.6, selected: false },
        { id: 4, static: true, 'box': true, 'box_checked': false, value: 0.5, selected: false },
        { id: 5, static: true, 'box': true, 'box_checked': false, value: 0.4, selected: false },
        { id: 6, static: true, 'box': true, 'box_checked': false, value: 0.3, selected: false },
        { id: 7, static: true, 'box': true, 'box_checked': false, value: 0.2, selected: false },
        { id: 8, static: true, 'box': true, 'box_checked': false, value: 0.1, selected: false },
      ];
      for (let i = 0; i < objItem.items.length; i += 1) {
        if (i === (item - 1)) {
          objItem.items[item - 1].box = false;
          objItem.items[item - 1].box_checked = true;
          objItem.items[item - 1].selected = true;
          this.calculateScore();
          break;
        }
      }
    },

    getCurrentUserAndOutline() {
      this.submitted = false;
      this.submit_results = '';
      this.spinning = true;
      const userId = this.$store.getters.userId;
      return userAPIService.getUserById(userId, this.$router)
      .then((user) => {
        this.judge = user;
        this.judge_name = user.first_name + ' ' + user.last_name;
        this.current_outline = user.current_outline;
        this.spinning = false;
        // now get all the users and line it up
        if (user.current_outline) {
          return outlinesAPIService.getOutlineByID(this.current_outline, this.$router);
        }
      })
      .then((outline) => {
        this.outline = outline;
        this.judges_to_select = [];
        this.outline_description = outline.description;
        this.spinning = false;

        // figure out which judge this user is, it could be multiple judges on the outline for the same user
        if (this.outline.j1_user === userId) {
          this.selected_judge = 'j1';
          this.judge_number = 1;
          if (outline.org_score1 > 0) {
            this.findAndChangeColor(outline.org_score1, this.organization);
          }
          if (outline.modifier_score1 > 0) {
            this.findAndChangeColor(outline.modifier_score1, this.modifiers);
          }
          if (outline.height_score1 > 0) {
            this.findAndChangeColor(outline.height_score1, this.heightOfTechniques);
          }
          if (outline.transition_score1 > 0) {
            this.findAndChangeColor(outline.transition_score1, this.transitionsAndFlow);
          }
          if (outline.epic_score1 > 0) {
            this.findAndChangeColor(outline.epic_score1, this.epicness);
          }
        }
        if (this.outline.j2_user === userId) {
          this.selected_judge = 'j2';
          this.judge_number = 2;
          if (outline.org_score2 > 0) {
            this.findAndChangeColor(outline.org_score2, this.organization);
          }
          if (outline.modifier_score2 > 0) {
            this.findAndChangeColor(outline.modifier_score2, this.modifiers);
          }
          if (outline.height_score2 > 0) {
            this.findAndChangeColor(outline.height_score2, this.heightOfTechniques);
          }
          if (outline.transition_score2 > 0) {
            this.findAndChangeColor(outline.transition_score2, this.transitionsAndFlow);
          }
          if (outline.epic_score2 > 0) {
            this.findAndChangeColor(outline.epic_score2, this.epicness);
          }
        }
        if (this.outline.j3_user === userId) {
          this.selected_judge = 'j3';
          this.judge_number = 3;
          if (outline.org_score3 > 0) {
            this.findAndChangeColor(outline.org_score3, this.organization);
          }
          if (outline.modifier_score3 > 0) {
            this.findAndChangeColor(outline.modifier_score3, this.modifiers);
          }
          if (outline.height_score3 > 0) {
            this.findAndChangeColor(outline.height_score3, this.heightOfTechniques);
          }
          if (outline.transition_score3 > 0) {
            this.findAndChangeColor(outline.transition_score3, this.transitionsAndFlow);
          }
          if (outline.epic_score3 > 0) {
            this.findAndChangeColor(outline.epic_score3, this.epicness);
          }
        }
        if (this.outline.j4_user === userId) {
          this.selected_judge = 'j4';
          this.judge_number = 4;
          if (outline.org_score4 > 0) {
            this.findAndChangeColor(outline.org_score4, this.organization);
          }
          if (outline.modifier_score4 > 0) {
            this.findAndChangeColor(outline.modifier_score4, this.modifiers);
          }
          if (outline.height_score4 > 0) {
            this.findAndChangeColor(outline.height_score4, this.heightOfTechniques);
          }
          if (outline.transition_score4 > 0) {
            this.findAndChangeColor(outline.transition_score4, this.transitionsAndFlow);
          }
          if (outline.epic_score4 > 0) {
            this.findAndChangeColor(outline.epic_score4, this.epicness);
          }
        }
        if (this.outline.j5_user === userId) {
          this.selected_judge = 'j5';
          this.judge_number = 5;
          if (outline.org_score5 > 0) {
            this.findAndChangeColor(outline.org_score5, this.organization);
          }
          if (outline.modifier_score5 > 0) {
            this.findAndChangeColor(outline.modifier_score5, this.modifiers);
          }
          if (outline.height_score5 > 0) {
            this.findAndChangeColor(outline.height_score5, this.heightOfTechniques);
          }
          if (outline.transition_score5 > 0) {
            this.findAndChangeColor(outline.transition_score5, this.transitionsAndFlow);
          }
          if (outline.epic_score5 > 0) {
            this.findAndChangeColor(outline.epic_score5, this.epicness);
          }
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting user and outline: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>
.static {
  font-weight: bold;
  font-size: 1em;
  width: 12.5%;
  height: 50px;
  border: 1px solid black;
  border-radius: 6px;
  background: #dadad7;
  display: inline-grid;
  align-items: center;
}

.box {
  background: #dadad7;
}

.box_checked {
  background: #3399ff;
}
</style>
