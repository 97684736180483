import CommonAPIService from './CommonHandler';

export class OrderNumbersAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getOrderNumbers(router) {
    const url = '/api/order_numbers';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getOrderNumbersByUserID(user_id, router) {
    const url = `/api/order_numbers/${user_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addOrderNumber(order_number, router) {
    const url = '/api/order_numbers';
    return this.CommonAPIService.postCall(url, order_number, router);
  }

  updateOrderNumber(codeId, parms, router) {
    const url = `/api/order_numbers/${codeId}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  deleteOrderNumber(order_num_id, router) {
    const url = `/api/order_numbers/${order_num_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  getUnusedOrderForEvent(eventId, userId, router) {
    const url = `/api/order_numbers/${eventId}/${userId}/unused`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getAllOrdersByEvent(eventId, router) {
    const url = `/api/order_numbers/${eventId}/outlines`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  // returns a 1 if already registered, 0 if not registered
  isRegistered(eventId, userId, router) {
    const url = `/api/order_numbers/${eventId}/${userId}/registered`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default OrderNumbersAPIService;
