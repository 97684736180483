import CommonAPIService from './CommonHandler';

export class ModifiersAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getModifiers(router) {
    const url = '/api/modifiers';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addEvent(modifier, router) {
    const url = '/api/modifiers';
    return this.CommonAPIService.postCall(url, modifier, router);
  }

  updateEvent(modifierId, modifier, router) {
    const url = `/api/modifiers/${modifierId}`;
    return this.CommonAPIService.putCall(url, modifier, router);
  }

  deleteEvent(modifierId, router) {
    const url = `/api/modifiers/${modifierId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default ModifiersAPIService;
