<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Self Defense</h4>
                <p class="card-category">Create or Edit Self Defense Techniques</p>
                <p class="card-category">
                  <el-button type="primary" @click="openModal('create')" icon="">Add New Self Defense Technique</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterSelfDef"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterSelfDef" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filtered_self_defence" style="width: 100%" height="65vh" @sort-change="handleSortChange">
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" sortable="custom" label="Name" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input placeholder="Name" v-model="scope.row.name" @change="updateSelfDefenseTechniques(scope.row.id)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Description" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input placeholder="Description" v-model="scope.row.description" @change="updateSelfDefenseTechniques(scope.row.id)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Notes" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input placeholder="Notes" v-model="scope.row.notes" @change="updateSelfDefenseTechniques(scope.row.id)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="PDF" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.pdf">
              <el-button type="primary" @click="downloadPDF(scope.row.pdf, 'self defense techniques', scope.row.id)" icon="">{{ scope.row.pdf }}</el-button>
              <el-button type="danger" @click="removeFile(scope.row.id)" icon="el-icon-delete"></el-button>
            </div>
            <div v-else>
              <el-button type="primary" @click="preUpload('pdf', scope.row.id)" icon="">Upload PDF</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="URL" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input placeholder="Media URL" v-model="scope.row.media" @change="updateSelfDefenseTechniques(scope.row.id)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Actions" :min-width="200" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-6">
                <el-checkbox v-model="scope.row.tested" size="large" border @change="updateSelfDefenseTechniques(scope.row.id)">Tested</el-checkbox>
              </div>
              <div class="col-sm-6">
                <el-button type="danger" @click="deleteSelfDefenseTechniquesToRank(scope.row.id)" icon="el-icon-delete"></el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Upload PDF"
      :visible.sync="modals.pdf">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <input type="file" ref="pdfInput" @change="uploadPDF"/>
            <el-button type="primary" @click="uploadFile" icon="">Upload</el-button>
            <p v-if="message">{{ message }}</p>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('pdf')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Create New Self Defence"
      :visible.sync="modals.create">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-4">
            <label>Name:</label>
            <el-input placeholder="Name" v-model="name"></el-input>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-12">
                <label>Description:</label>
                <el-input placeholder="Description" v-model="description"></el-input>
              </div>
              <div class="col-sm-12">
                <label>Notes:</label>
                <el-input placeholder="Notes" v-model="notes"></el-input>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-12">
                <label>URL:</label>
                <el-input placeholder="Media URL" v-model="media"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <br>
            <el-checkbox v-model="tested" size="large" border>Tested</el-checkbox>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <br>
          <el-button type="success" @click="createSelfDefenseTechniques('create')" icon="">OK</el-button>
          <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Checkbox, Select, Input, Button, Table, TableColumn } from 'element-ui';
import SelfDefenseTechniquesAPIService from "src/servicehandlers/SelfDefenseTechniquesAPIService";
import { FileAPIService } from 'src/servicehandlers/FileHandler';

const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService();
const fileAPI = new FileAPIService();

export default {
  name: "SelfDefenseTechniques",
  components: {
    [Dialog.name]: Dialog,
    [Checkbox.name]: Checkbox,
    [Input.name]: Input,
    [Select.name]: Select,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      spinning: false,
      selected_self_defense_techniques_id: null,
      pdfFile: null,
      message: '',
      filename: null,
      name: null,
      description: null,
      self_defense_techniques: [],
      filtered_self_defence: [],
      pdf: null,
      media: null,
      tested: false,
      notes: null,
      last_order: null,
      error_txt: null,
      success_txt: null,
      modals: {
        error: false,
        success: false,
        create: false,
        pdf: false,
      },
      search: '',
    }
  },
  mounted() {
    this.getSelfDefenseTechniques();
  },
  methods: {
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.filename;
          this.spinning = false;
          link.click();
        })
        .catch((error) => {
          const self = this;
          this.error_txt = 'Self Defense PDF download failed! ' + error;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['success'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        })
    },
    preUpload(name, id) {
      this.selected_self_defense_techniques_id = id;
      this.openModal(name);
    },
    removeFile(id) {
      const parms = {
        pdf: null,
      };
      return selfDefenseTechniquesAPIService.updateSelfDefenseTechniques(id, parms, this.$router)
        .then(() => {
          this.getSelfDefenseTechniques();
        })
        .catch((error) => {
          const self = this;
          this.error_txt = 'PDF clearing failure: ' + error;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        });
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.pdfInput.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.pdfInput.files) {
        formData.append('pdfInput', fle);
        formData.append('type', 'self defense techniques');
        formData.append('id', this.selected_self_defense_techniques_id);
      }
      return fileAPI.uploadFile(formData, this.$router)
        .then((response) => {
          const self = this;
          this.success_txt = 'PDF upload success';
          this.spinning = false;
          this.pdfFile = null;
          this.closeModal('pdf');
          this.openModal('success');
          this.getSelfDefenseTechniques();
          setTimeout(function () {
            self.modals['success'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        })
        .catch((error) => {
          const self = this;
          this.error_txt = 'File upload error: ' + error;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        });
    },
    uploadPDF(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const fileType = /pdf/;
        if (!fileType.test(files[0].type)) {
          this.message = "Please select a valid PDF file.";
          return;
        }
        this.pdfFile = files[0];
        this.message = '';
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.self_defense_techniques.length; i += 1) {
        const id = this.self_defense_techniques[i].id;
        this.self_defense_techniques[i].order = i + 1;
        promises.push(selfDefenseTechniquesAPIService.updateSelfDefenseTechniques(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
        // now start updating both the old index and the new to the new values
        .then(() => {
          this.getSelfDefenseTechniques();
        })
        .catch((error) => {
          const self = this;
          this.error_txt = 'File upload error: ' + error;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        });
    },
    updateSelfDefenseTechniques(id) {
      for (const self_defense_techniques of this.self_defense_techniques) {
        if (id === self_defense_techniques.id) {
          const parms = {
            name: self_defense_techniques.name,
            description: self_defense_techniques.description,
            media: self_defense_techniques.media,
            tested: self_defense_techniques.tested,
            notes: self_defense_techniques.notes,
          };
          return selfDefenseTechniquesAPIService.updateSelfDefenseTechniques(id, parms, this.$router)
            .then(() => {
              const self = this;
              this.success_txt = 'Self Defense Technique update successful';
              /*this.openModal('success');
              setTimeout(function () {
                self.modals['success'] = false; // Use your variable name
              }, 1500); // Hide after 5 secs*/
            })
            .catch((error) => {
              const self = this;
              this.error_txt = 'Self Defense Technique update failure: ' + error;
              this.openModal('error');
              this.spinning = false;
              setTimeout(function () {
                self.modals['error'] = false; // Use your variable name
              }, 1500); // Hide after 5 secs
            });
        }
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    deleteSelfDefenseTechniquesToRank(id) {
      return selfDefenseTechniquesAPIService.deleteSelfDefenseTechnique(id, this.$router)
        .then(() => {
          const self = this;
          this.success_txt = 'Delete Self Defense Technique Successful';
          this.openModal('success');
          this.getSelfDefenseTechniques();
          setTimeout(function () {
            self.modals['success'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
          //this.getSelfDefenseTechniques();
        })
        .catch((error) => {
          const self = this;
          this.error_txt = 'Error Deleting Self Defense Technique: ' + error;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        });
    },
    getSelfDefenseTechniques() {
      return selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
        .then((response) => {
          this.self_defense_techniques = [];
          for (const self_defense_techniques of response) {
            this.last_order = self_defense_techniques.order;
          }
          this.self_defense_techniques = response;
          this.filtered_self_defence = this.self_defense_techniques
        })
        .catch((error) => {
          const self = this;
          this.error_txt = 'Error Getting Self Defense Technique: ' + error;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        });
    },
    createSelfDefenseTechniques() {
      this.closeModal('create');
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      const technique_items = {
        order,
        name: this.name,
        description: this.description,
        media: this.media,
        tested: this.tested,
        notes: this.notes,
      };
      return selfDefenseTechniquesAPIService.addSelfDefenseTechniques(technique_items, this.$router)
        .then((response) => {
          this.self_defense_techniques.push(response);
          this.name = null;
          this.description = null;
          this.media = null;
          this.tested = false;
          this.notes = null;
          const self = this;
          this.getSelfDefenseTechniques();
          this.success_txt = 'Add Self Defense Technique Successful';
          this.openModal('success');
          setTimeout(function () {
            self.modals['success'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        })
        .catch((error) => {
          const self = this;
          this.error_txt = 'Error Creating Self Defense Technique: ' + error;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.self_defense_techniques[index];
        this.self_defense_techniques.splice(index, 1);
        this.self_defense_techniques.splice(index - 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    moveDown(index) {
      if (index < this.self_defense_techniques.length - 1) {
        const temp = this.self_defense_techniques[index];
        this.self_defense_techniques.splice(index, 1);
        this.self_defense_techniques.splice(index + 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    updateOrder(item){
      for (let i = 0; i < this.self_defense_techniques.length; i++){
        this.self_defense_techniques[i].order = i+1
      }
      this.updateSelfDefenseTechniques(item.id)
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filtered_self_defence.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filtered_self_defence.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterSelfDef(){
      try {
        this.filtered_self_defence = this.self_defense_techniques.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
  }
}
</script>

<style scoped>
el-input,el-select{
  width: 100%;
}
</style>
