import CommonAPIService from './CommonHandler';

export class RankTypesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getRankTypes(router) {
    const url = '/api/rank_types';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getRankType(rank_type_id, router) {
    const url = `/api/rank_types/${rank_type_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addRankType(rank_type, router) {
    const url = '/api/rank_types';
    return this.CommonAPIService.postCall(url, rank_type, router);
  }

  updateRankType(rank_typeId, rank_type, router) {
    const url = `/api/rank_types/${rank_typeId}`;
    return this.CommonAPIService.putCall(url, rank_type, router);
  }

  deleteRankType(rank_typeId, router) {
    const url = `/api/rank_types/${rank_typeId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  getRankTypesByIDWithOutlines(rank_typeId, router) {
    const url = `/api/rank_types/${rank_typeId}/rank_typeoutline`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getRankTypesForUser(user_id, router) {
    const url = `/api/rank_types/${user_id}/user`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default RankTypesAPIService;
