import CommonAPIService from './CommonHandler';

export class EventTypesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getEventTypes(router) {
    const url = '/api/event_types';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getEventType(event_type_id, router) {
    const url = `/api/event_types/${event_type_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addEventType(event_type, router) {
    const url = '/api/event_types';
    return this.CommonAPIService.postCall(url, event_type, router);
  }

  updateEventType(event_typeId, event_type, router) {
    const url = `/api/event_types/${event_typeId}`;
    return this.CommonAPIService.putCall(url, event_type, router);
  }

  deleteEventType(event_typeId, router) {
    const url = `/api/event_types/${event_typeId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  getEventTypesByIDWithOutlines(event_typeId, router) {
    const url = `/api/event_types/${event_typeId}/event_typeoutline`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getEventTypesForUser(user_id, router) {
    const url = `/api/event_types/${user_id}/user`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default EventTypesAPIService;
