<template>
<div>
  <card>
    <div slot="header">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12">
              <h4 class="card-title">Outlines Table</h4>
              <p class="card-category">
                Create or Edit Outlines
              </p>
              <p class="card-category">
                <el-button type="primary" @click="createNewOutline('outline')" icon="">Create New Outline</el-button>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row align-items-center">
            <div class="col-sm-8">
              <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterOutlines"></el-input>
            </div>
            <div class="col-sm-4" style="padding-left: 5px;">
              <el-button type="primary" @click="filterOutlines" icon="">Search</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-table stripe :data="filteredOutlineData" style="width: 100%" height="65vh" @sort-change="handleSortChange">
      <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
        <template v-slot="scope">
          <div class="row">
            <div class="col-sm-4">
              {{ scope.row.order }}
            </div>
            <div class="col-sm-4">
              <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
            </div>
            <div class="col-sm-4">
              <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Description" sortable="custom" label="Description" :min-width="300" align="center">
        <template v-slot="scope">
          <el-input @change="updateOutlineDescription(scope.row.id, scope.row.description)" v-model="scope.row.description"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Edit" align="center">
        <template v-slot="scope">
          <outline :outline_id="scope.row.id"></outline>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template v-slot="scope">
          <el-dialog
            center
            :before-close="handleClose"
            title="Confirm Delete?"
            :visible.sync="modals.delete">
            <div class="text-center">
              <span>Are you sure you wish to delete the outline?</span>
              <br>
              <span slot="footer" class="dialog-footer">
                <el-button type="success" @click="deleteOutline(scope.row.id, 'delete')" icon="">Confirm</el-button>
                <el-button type="danger" @click="closeModal('delete')" icon="">Cancel</el-button>
              </span>
            </div>
          </el-dialog>
          <el-button type="danger" @click="openModal('delete')" icon="el-icon-delete"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </card>
  <el-dialog
    center
    title="Create New Outline"
    :visible.sync="modals.outline">
    <div class="row text-center">
      <div class="col-md-4"></div>
      <div class="col-md-4 text-center">
        <label>Outline Description</label>
        <el-input placeholder="Description" v-model="outline_description"></el-input>
      </div>
      <div class="col-md-4"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="row">
        <div class="col-md-4">
          <el-button type="success" @click="saveOutline('outline')" icon="">Save</el-button>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-2">
          <el-button type="danger" @click="closeModal('outline')" icon="">Cancel</el-button>
        </div>
      </div>
</span>
  </el-dialog>
</div>
</template>

<script>
import {
  Dialog, Table, TableColumn, Button, Input
} from 'element-ui';
import Outline from "src/pages/Dashboard/Pages/Outline/Outline";

import moment from 'moment';
// import Swal from "sweetalert2";
import OutlinesAPIService from "src/servicehandlers/OutlinesAPIService";
// import { Checkbox, Table as LTable } from "../../../../components";
const outlineAPIService = new OutlinesAPIService();
export default {
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Input.name]: Input,
    Outline,
  },
  name: "OutlineManagement",

  data() {
    return {
      outlineColumns: ['Order', 'Description', 'Edit', 'Delete'],
      outlineData: [],
      filteredOutlineData: [],
      outline_description: null,
      outline_name: null,
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false,
        delete: false,
        outline: false,
        okmodal: false,
        error: false,
      },
      search: '',
    };
  },
  methods: {
    getOutlines() {
      return outlineAPIService.getOutlines(this.$router)
      .then((response) => {
        this.filteredOutlineData = []
        this.outlineData = response;
        for (let outline of this.outlineData) {
          this.updateOrder(outline)
          if (outline.is_default){
            this.filteredOutlineData.push(outline)
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting outlines: ' + error;
        this.openModal('error');
      });
    },
    createNewOutline(name) {
      this.modals[name] = true
    },
    saveOutline(name) {
      this.modals[name] = false;
      this.modals['outline'] = false;
      const newoutline = {
        user_id: 0,
        description: this.outline_description,
        time: null,
        is_default: true,
      };
      outlineAPIService.addOutline(newoutline, this.$router)
      .then(() => {
        this.getOutlines();
        this.outline_name = null;
        this.outline_description = null;
      })
      .catch((error) => {
        this.error_txt = 'Error saving outline: ' + error;
        this.openModal('error');
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    deleteOutline(outlineId, name) {
      this.modals[name] = false;
      outlineAPIService.deleteOutline(outlineId)
      .then(() => {
        this.getOutlines();
      })
      .catch((error) => {
        this.error_txt = 'Error deleting outline: ' + error;
        this.openModal('error');
      })
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?')
        done()
      } catch (e) {}
    },
    updateOutlineDescription(id, description) {
      const parms = {
        description,
      };
      return outlineAPIService.updateSingleOutline(id, parms, this.$router)
      .then(() => {
        // this.outlineData.sort((a, b) => (a.order > b.order) ? 1 : -1);
      })
      .catch((error) => {
        this.error_txt = 'Error during outline save: ' + error;
        this.openModal('error');
      });
    },
    reorderMainOutline(outline_id, order) {
      const parms = {
        order
      };
      return outlineAPIService.updateSingleOutline(outline_id, parms, this.$router)
      .then(() => {
        this.outlineData.sort((a, b) => (a.order > b.order) ? 1 : -1);
      })
      .catch((error) => {
        this.error_txt = 'Error during outline reorder: ' + error;
        this.openModal('error');
      });
    },
    filterOutlines(){
      try {
        this.filteredOutlineData = this.outlineData.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredOutlineData.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredOutlineData.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.outlineData[index];
        this.outlineData.splice(index, 1);
        this.outlineData.splice(index - 1, 0, temp);
        this.updateOrder(temp)
      }
    },
    moveDown(index) {
      if (index < this.outlineData.length - 1) {
        const temp = this.outlineData[index];
        this.outlineData.splice(index, 1);
        this.outlineData.splice(index + 1, 0, temp);
        this.updateOrder(temp)
      }
    },
    updateOrder(item){
      for (let i = 0; i < this.outlineData.length; i++){
        this.outlineData[i].order = i+1
        this.reorderMainOutline(this.outlineData[i].id, this.outlineData[i].order)
      }
    },
  },
  editOutline(name, outline_id) {
    this.getMountedItems(outline_id);
    this.modals[name] = true
  },
  mounted() {
    this.getOutlines();
  },
  beforeDestroy() {
    this.closeMenu()
  }
}
</script>

<style scoped>

</style>
